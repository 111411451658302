import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './Config.module.scss'
import General from './General'
import Contact from './Contact'
import ContactLink from './ContactLink'
import LocationType from './LocationType'
import ReportType from './ReportType'
import Billing from './Billing'
import HomeScreen from './HomeScreen'

function Config({ config, updateConfig, tab, routerPush }) {
  useEffect(() => {
    if (!tab) {
      routerPush({ query: { tab: 'general' } })
    }
  }, [tab])

  function renderContent() {
    switch (tab ?? 'general') {
    case 'general':
      return <General config={config} updateConfig={updateConfig} />
    case 'home_screen':
      return <HomeScreen config={config} updateConfig={updateConfig} />
    case 'contacts':
      return <Contact />
    case 'contact_links':
      return <ContactLink />
    case 'location_types':
      return <LocationType />
    case 'report_types':
      return <ReportType />
    case 'billing':
      return <Billing />
    }
    return <p>Not found.</p>
  }

  return (
    <div className={styles.root}>
      <div className={styles.navigationHeader}>
        <div className={styles.navigationHeaderItem}>
          Configuration
        </div>
      </div>
      <div className={styles.navigation}>
        <div
          onClick={() => routerPush({ query: { tab: 'general' } })}
          className={clsx(styles.navigationLink, { [styles.active]: tab === 'general' })}
        >
          Général
        </div>
        <div
          onClick={() => routerPush({ query: { tab: 'home_screen' } })}
          className={clsx(styles.navigationLink, { [styles.active]: tab === 'home_screen' })}
        >
          Écran de pré-accueil
        </div>
        <div
          onClick={() => routerPush({ query: { tab: 'billing' } })}
          className={clsx(styles.navigationLink, { [styles.active]: tab === 'billing' })}
        >
          Facturation
        </div>
        <div
          onClick={() => routerPush({ query: { tab: 'contacts' } })}
          className={clsx(styles.navigationLink, { [styles.active]: tab === 'contacts' })}
        >
          Contacts
        </div>
        <div
          onClick={() => routerPush({ query: { tab: 'contact_links' } })}
          className={clsx(styles.navigationLink, { [styles.active]: tab === 'contact_links' })}
        >
          Liens
        </div>
        <div
          onClick={() => routerPush({ query: { tab: 'location_types' } })}
          className={clsx(styles.navigationLink, { [styles.active]: tab === 'location_types' })}
        >
          Types de localisations
        </div>
        <div
          onClick={() => routerPush({ query: { tab: 'report_types' } })}
          className={clsx(styles.navigationLink, { [styles.active]: tab === 'report_types' })}
        >
          Types de signalements
        </div>
      </div>

      <div className={clsx(styles.container, 'container')}>
        {renderContent()}
      </div>
    </div>
  )
}

Config.propTypes = {
  tab: PropTypes.string,
  config: PropTypes.object,
  updateConfig: PropTypes.func,
  routerPush: PropTypes.func.isRequired,
}

export default Config
