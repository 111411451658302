<template>
  <div class="create-company">
    <div class="container">
      <div class="header">
        <div class="header-title">
          Nouveau client
        </div>
      </div>
      <form @submit="onSubmit">
        <Error :error="error"></Error>

        <div class="step">
          <div class="step-title">Informations sur l'entreprise</div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Nom de l'entreprise
            <span class="required">*</span>
          </div>
          <input v-model="name" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Adresse de l'entreprise
          </div>
          <input v-model="address" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Logo de l'entreprise
          </div>
          <PictureUrlInput v-model="logo" />
        </div>

        <div class="step">
          <div class="step-title">Configuration</div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Fonctionnalités
            <span class="required">*</span>
          </div>
          <div class="treeselect-wrapper">
            <TreeSelect v-model="features" :multiple="true" :options="options"
                        style="margin-bottom: 32px"/>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Page d'accueil utilisateurs
            <span class="required">*</span>
          </div>
          <div class="treeselect-wrapper">
            <TreeSelect v-model="home" :options="homes"
                        style="margin-bottom: 32px"/>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Prefixe UL
          </div>
          <input v-model="ulPrefix" class="form-field-input" type="text">
        </div>

        <div class="step">
          <div class="step-title">Compte</div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Nom
            <span class="required">*</span>
          </div>
          <input v-model="firstName" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Prénom
            <span class="required">*</span>
          </div>
          <input v-model="lastName" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Email
            <span class="required">*</span>
          </div>
          <input v-model="email" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Mot de passe
            <span class="required">*</span>
          </div>
          <input v-model="password" class="form-field-input" type="password">
        </div>
        <div class="form-submission">
          <div class="btn default" @click="$router.go(-1)">
            Annuler
          </div>
          <button type="submit" class="btn primary">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TreeSelect from '@riophae/vue-treeselect'
import * as companyService from '@/services/companyService'
import featureList from '@/utils/featureList'
import ErrorComponent from '@/components/Error'
import PictureUrlInput from '@/components/PictureUrlInput'

export default {
  name: 'CreateCompany',
  data: () => ({
    error: undefined,
    loading: false,
    name: '',
    address: '',
    logo: '',
    ulPrefix: '',
    features: [],
    home: 'home',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    homes: [
      { label: 'Accueil', id: 'home' },
      { label: 'Tribunes', id: 'user-home' },
      { label: 'Signalements', id: 'user-signalements-list' },
      { label: 'Concertations / Satisfactions', id: 'user-votes-list' },
      { label: 'Travaux', id: 'user-travaux-list' },
      { label: 'Interventions', id: 'user-interventions-list' },
      { label: 'Contacts', id: 'user-contacts' },
    ],
  }),
  components: {
    PictureUrlInput,
    Error: ErrorComponent,
    TreeSelect,
  },
  computed: {
    options() {
      return featureList.map(v => ({
        id: v.value,
        label: v.name,
      }))
    },
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      this.loading = true
      this.error = undefined
      try {
        if (
          !this.name || !this.features?.length || !this.home || !this.firstName ||
          !this.lastName || !this.email || !this.password
        ) {
          throw new Error('Champs requis manquant.')
        }
        await companyService.adminCreate({
          name: this.name,
          address: this.address,
          logo: this.logo,
          ulPrefix: this.ulPrefix,
          features: this.features,
          home: this.home,
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
        })
        this.$router.push({ name: 'superadmin-company-list' })
      } catch (error) {
        this.error = error.isAxiosError && error.response?.status === 400
          ? new Error(error.response?.data?.message || 'Erreur inconnue')
          : error
      } finally {
        this.loading = false
      }
    },
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/styles/variables';

.create-company {
  margin-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 16px;

  .header-title {
    font-weight: 600;
    font-size: 24px;
  }
}

.step {
  .step-title {
    font-size: 18px;
    font-weight: 500;
    margin: 16px 0 8px;
  }
}

.form-field-input {
  border: 2px solid $color-grey;
  padding: 6px 12px;
  border-radius: 4px;
  box-shadow: none;
  max-width: 400px;

  &:focus {
    outline: none;
    border-color: $color-blue;
  }
}

.error,
.form-submission,
.treeselect-wrapper {
  max-width: 400px;
}
</style>
