<template>
  <div>
    <RenderReact
      :component="ConcertationAnswer"
      :id="$route.params.id"
      :routerBack="$router.back.bind($router)"
    />
  </div>
</template>

<script>
import ConcertationAnswer from '@/views/user/ConcertationAnswer.jsx'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'ConcertationAnswer',
  components: {
    RenderReact,
  },
  data: () => ({ ConcertationAnswer }),
}
</script>
