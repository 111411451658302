<template>
  <div class="list-votes">
    <template v-if="votes && votes.length > 0">
      <div class="card" v-for="vote in votes" :key="vote.id">
        <div class="card-header">
          <div class="card-type-icon">
            <VoteIcon></VoteIcon>
          </div>
          <div class="card-icon">
            <component v-if="vote.pictogram" v-bind:is="findIcon(vote.pictogram).image"></component>
          </div>
          <div class="card-right" v-if="reamingTime(vote.availableAt || vote.available_at)">
            <div class="card-duration">
              <i class="ion-ios-hourglass"></i>
              {{ reamingTime(vote.availableAt) }}
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-title">
            {{vote.name}}
          </div>
          <div class="card-duration">
            <CalendarIcon></CalendarIcon>
            Du {{(vote.startAt) | moment("DD/MM/YYYY")}} au {{(vote.endAt) | moment("DD/MM/YYYY")}}
          </div>
          <!-- <div class="card-duration">
            <DurationIcon></DurationIcon>
            {{formatCreation(vote.createdAt)}}
          </div> -->
          <div class="card-duration" v-if="vote.availableAt">
            <CountdownIcon></CountdownIcon>
            Résultat disponible le
            {{ mapAvailableAt(vote.availableAt) }}
          </div>
          <div v-if="vote.description" class="card-description">
            <template v-if="vote.type === 'concertation'">
              <RenderReact
                :component="TextEditorRenderer"
                :children="vote.description"
                className="user-card-description"
              />
            </template>
            <template v-else>
              {{vote.description}}
            </template>
          </div>
          <img v-if="vote.file" class="card-image" :src="vote.file">
        </div>
        <template v-if="vote.type === 'concertation'">
          <div class="card-footer">
            <div v-if="vote.hasAnswered" class="card-button read">
              <i class="ion-ios-checkmark-circle"></i>
              Vous avez répondu à la consultation
            </div>
            <template v-else>
              <div class="card-button" @click="$router.push({
                name: 'user-concertation-answer', params: { id: vote.id },
              })">
                Répondre à la consultation
              </div>
            </template>
          </div>
        </template>
        <template v-else-if="vote.type === 'satisfaction'">
          <div class="card-footer" style="padding: 0 0 16px; border-top: none">
            <div class="card-advice" v-if="config.concertationExample">
              {{config.concertationExample}}
            </div>
            <div class="card-option card-option-green" @click="voteFor(vote, 'TOWARD')"
              :class="{ 'card-option-disabled': isCardOptionDisabled(vote, 'TOWARD') }">
              <div class="card-option-icon">
                <SatisfactionIcon></SatisfactionIcon>
              </div>
              <div class="card-option-text">
                Satisfait(e)
              </div>
            </div>
            <div class="card-option card-option-red" @click="voteFor(vote, 'AGAINST')"
              :class="{ 'card-option-disabled': isCardOptionDisabled(vote, 'AGAINST') }">
              <div class="card-option-icon">
                <SatisfactionIcon style="transform: rotate(180deg)"></SatisfactionIcon>
              </div>
              <div class="card-option-text">
                Pas satisfait(e)
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <div v-else class="nothing-to-show">
      <VoteIcon></VoteIcon>
      Rien à signaler
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import RenderReact from '@/components/RenderReact'
import moment from 'moment'
import { Capacitor } from '@capacitor/core'
import { Dialog } from '@capacitor/dialog'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { mapActions, mapState } from 'vuex'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import SatisfactionIcon from '@/assets/picto/satisfaction.svg'
import DurationIcon from '@/assets/picto/historique.svg'
import CountdownIcon from '@/assets/picto/sablier.svg'
import CalendarIcon from '@/assets/picto/agenda.svg'
import VoteIcon from '@/assets/picto/voter.svg'
import icons from '@/utils/icons'

export default {
  name: 'ListVotes',
  components: {
    RenderReact,
    SatisfactionIcon,
    CalendarIcon,
    DurationIcon,
    CountdownIcon,
    VoteIcon,
  },
  data: () => ({
    TextEditorRenderer,
    hammer: undefined,
  }),
  computed: {
    ...mapState('concertation', ['concertations']),
    ...mapState('satisfaction', ['satisfactions']),
    ...mapState('user', ['config']),
    votes() {
      return [
        ...this.concertations?.map(v => ({
          type: 'concertation',
          ...v,
        })) ?? [],
        ...this.satisfactions?.map(v => ({
          type: 'satisfaction',
          ...v,
        })) ?? [],
      ].sort(
        ({ startAt: sa, createdAt: ca }, { startAt: sb, createdAt: cb }) =>
          new Date(sb || cb).getTime() - new Date(sa || ca).getTime()
      )
    },
  },
  methods: {
    ...mapActions('concertation', {
      fetchConcertations: 'fetchConcertations',
      voteConcertation: 'vote',
    }),
    ...mapActions('satisfaction', {
      fetchSatisfactions: 'fetchSatisfactions',
      voteSatisfaction: 'vote',
    }),
    findIcon(name) {
      return icons.find(({ value }) => value === name) ?? {}
    },
    mapAvailableAt(availableAt) {
      const available = moment(availableAt)
      return available.format('DD/MM/YYYY')
    },
    formatCreation(createdAt) {
      return moment(createdAt)
        .from(moment())
        .replace(/^il/, 'Il')
    },
    reamingTime(availableAt) {
      const available = moment(availableAt)
      if (available.toDate().getTime() <= Date.now()) {
        return null
      }
      return moment().to(available)
        .replace(/dans/, 'Il reste')
        .replace(/^il/, 'Il')
    },
    isCardOptionDisabled(card, vote) {
      if (card.type === 'concertation') {
        return card.hasVoted && card.userVote?.id !== vote.id
      }
      return card.hasVoted && card.type === 'satisfaction' && card.userVote !== vote
    },
    classFromIndex(index) {
      return ['green', 'red', 'blue', 'yellow'][index]
    },
    async voteFor(item, vote) {
      if (item.hasVoted) return
      try {
        if (item.type === 'satisfaction') {
          await this.voteSatisfaction({
            id: item.id,
            type: vote,
          })
        }
        if (item.type === 'concertation') {
          await this.voteConcertation({
            id: item.id,
            vote: vote.id,
          })
        }
      } catch (error) {
        await Dialog.alert({
          title: 'Erreur',
          message: error.message,
        })
      }
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      FirebaseAnalytics.setScreenName({
        screenName: 'list-votes',
        nameOverride: 'ListVotesScreen',
      }).catch(console.error)
    }
    this.hammer = new Hammer(document.body)
    this.hammer.on('swipe', (event) => {
      if (event?.direction === 4) {
        this.$router.push({ name: 'user-signalements-list' })
      } else if (event?.direction === 2) {
        this.$router.push({ name: 'user-travaux-list' })
      }
    })
    try {
      await Promise.all([
        this.fetchConcertations(),
        this.fetchSatisfactions(),
      ])
    } catch (error) {
      await Dialog.alert({
        title: 'Erreur',
        message: error.message,
      })
    }
  },
  async beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/card";

.card {
  @include user-card($color-concertation);
}

.nothing-to-show {
  @include nothing-to-show($color-concertation);
}
</style>
