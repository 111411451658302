<template>
  <div class="create-user">
    <div class="header">
      <UtilisateurIcon></UtilisateurIcon>
      Créer un utilisateur
    </div>
    <form class="form" @submit="submit">
      <Error :error="error"></Error>
      <div class="form-field">
        <div class="form-field-label">
          Type d'utilisateur
          <span class="required">*</span>
        </div>
        <div class="select">
          <select v-model="role">
            <option value="" selected>Sélectionner</option>
            <option value="owner">Owner</option>
            <option value="superowner">Superowner</option>
          </select>
        </div>
      </div>
      <div class="form-field" v-if="role === 'owner'">
        <div class="form-field-label">
          Sélectionner l'utilisateur parent
          <span class="required">*</span>
        </div>
        <div class="select">
          <select v-model="superownerId">
            <option value="" selected>Sélectionner</option>
            <option v-for="so in superowners" :key="so._id" :value="so._id">
              {{so.firstName}} {{so.lastName}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field-label">
          Nom
          <span class="required">*</span>
        </div>
        <input v-model="firstName" class="form-field-input" type="text">
      </div>
      <div class="form-field">
        <div class="form-field-label">
          Prénom
          <span class="required">*</span>
        </div>
        <input v-model="lastName" class="form-field-input" type="text">
      </div>
      <div class="form-field">
        <div class="form-field-label">
          Email
          <span class="required">*</span>
        </div>
        <input v-model="email" class="form-field-input" type="text">
      </div>
      <div class="form-field">
        <div class="form-field-label">
          Mot de passe
          <span class="required">*</span>
        </div>
        <input v-model="password" class="form-field-input" type="password">
      </div>
      <div class="form-field">
        <div class="form-field-label">
          Role
          <span class="required">*</span>
        </div>
        <input v-model="office" class="form-field-input" type="text">
      </div>
      <div class="form-submission">
        <div class="btn default" @click="$router.go(-1)">
          Annuler
        </div>
        <button type="submit" class="btn primary">
          Valider
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ErrorComponent from '@/components/Error'
import UtilisateurIcon from '@/assets/picto/person.svg'

export default {
  name: 'AdminCreateUser',
  components: {
    UtilisateurIcon,
    Error: ErrorComponent,
  },
  data: () => ({
    error: undefined,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    superownerId: '',
    office: '',
  }),
  computed: {
    ...mapState('superowner', ['superowners']),
  },
  methods: {
    ...mapActions('superowner', ['fetchSuperOwners']),
    ...mapActions('admin', ['createUser']),
    async submit(event) {
      event.preventDefault()
      try {
        if (!this.firstName || !this.lastName || !this.email || !this.password || !this.role || !this.office ||
          (!this.superownerId && this.role === 'owner')) {
          throw new Error('Champs requis manquant.')
        }
        await this.createUser({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          role: this.role,
          office: this.office,
          superownerId: this.superownerId,
        })
        this.$router.push({ name: 'permissions-superowner' })
      } catch (error) {
        if (error.isAxiosError && error.response && error.response.data && error.response.data.status === 400) {
          this.error = new Error(error.response.data.message || 'Erreur inconnu.')
        } else {
          this.error = error
        }
      }
    },
  },
  async mounted() {
    try {
      await this.fetchSuperOwners()
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.form {
  margin: 0 auto 32px;
  max-width: 720px;
  padding: 0 16px;
}

.select {
  z-index: 3000;
  display: inline;
  border: 1px solid #000;
  padding: 4px;
  border-radius: 24px;

  select {
    border: none;
    box-shadow: none;
    background: transparent;
    color: #000;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
