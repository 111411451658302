import {tribuneService} from '@/services'

const initialState = {
  tribunes: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setTribunes(state, tribunes) {
      state.tribunes = tribunes
    },
  },
  actions: {
    async fetchTribunes({ commit }) {
      const data = await tribuneService.list()
      data.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime())
      commit('setTribunes', data)
      return data
    },
    async markAsRead({ dispatch }, { id }) {
      await tribuneService.readed({ id })
      return dispatch('fetchTribunes')
    },
  },
}
