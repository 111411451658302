export default [
  { name: 'Accueil', value: 'home' },
  { name: 'Signalement', value: 'signalement' },
  { name: 'Concertation', value: 'concertation' },
  { name: 'Tribune', value: 'tribune' },
  { name: 'Travaux', value: 'travaux' },
  { name: 'Intervention', value: 'intervention' },
  { name: 'Contact', value: 'contact' },
  { name: 'Video', value: 'video' },
  { name: 'Télécharger les statistiques', value: 'download_stats' },
  { name: 'Signalement locataire', value: 'signalement_locataire' },
  { name: 'Signalement locataire V2', value: 'signalement_locataire_v2' },
  { name: 'Concertation complet', value: 'concertation-full' },
]
