<template>
  <div class="login">
    <div class="login-banner" @click="forgetPassword">
      Pour récupérer votre code : cliquez ici
    </div>
    <img src="../assets/images/logo.png" alt="Neybs" title="Neybs" />
    <form class="form" @submit="submit">
      <div class="title">Connexion</div>
      <Error :error="error"></Error>
      <div class="form-group">
        <label>Email</label>
        <input v-model="username" type="email" placeholder="Email" />
      </div>
      <div class="form-group">
        <label>Mot de passe</label>
        <input v-model="password" type="password" placeholder="Mot de passe" />
      </div>
      <div class="form-actions">
        <button type="submit">Valider</button>
      </div>
      <div class="first-login">
        Pas d'identifiant ?
        <router-link :to="{ name: 'first-login' }">
          Première connexion
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ErrorComponent from "@/components/Error";

export default {
  name: "Login",
  data: () => ({
    username: "",
    password: "",
    error: undefined,
  }),
  components: {
    Error: ErrorComponent,
  },
  methods: {
    ...mapActions("user", ["login"]),
    forgetPassword() {
      window.open(
        "https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAASzTtntUODU2QlhFRzZSSVJIWUJBUkpWTjdLVDZZNS4u",
        "_blank"
      );
    },
    async submit(event) {
      event.preventDefault();
      try {
        await this.login({ username: this.username, password: this.password });
        this.$router.push({ name: "home" });
      } catch (error) {
        if (
          error.isAxiosError &&
          error.response &&
          error.response.data &&
          error.response.data.status === 401
        ) {
          this.error = new Error("Email ou mot de passe incorrect.");
        } else {
          this.error = error;
        }
      }
    },
  },
  mounted() {
    document.body.style.overflow = "auto";
    document.body.style.touchAction = "auto";
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.login-banner {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background: $color-blue-light;
  color: #fff;
  padding: 16px;
  font-size: 16px;
}

.login {
  width: 100%;
  max-width: 400px;
  margin: 64px auto 32px;

  img {
    display: block;
    width: 96px;
    margin: 0 auto 32px;
  }

  form {
    padding: 16px 32px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);

    .title {
      text-transform: uppercase;
      color: $color-blue;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 16px;

      label {
        min-width: 100px;
        max-width: 100px;
        margin-right: 16px;
        display: flex;
        color: #4b5563;
        font-weight: 400;
      }

      input {
        border: 2px solid #d4d4d4;
        box-shadow: none;
        border-radius: 4px;
        width: 100%;
        padding: 6px 12px;
      }
    }

    button {
      display: block;
      background: #d4d4d4;
      border: none;
      padding: 8px 12px;
      border-radius: 4px;
      font-weight: 500;
      width: 100%;

      &:hover {
        background: $color-blue-light;
      }
    }
  }
}

.form-actions {
  button {
    margin-left: auto;
    color: #fff;
    background: $color-blue !important;
  }
}

.first-login {
  border-top: 1px solid $color-grey;
  padding-top: 16px;
  padding-bottom: 8px;
  margin-top: 16px;
  text-align: center;
  color: #4b5563;

  a {
    color: $color-blue;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .form-actions {
    justify-content: space-between;

    a {
      display: block !important;
    }
  }

  .login {
    form {
      border-radius: 0;
    }
  }
}
</style>

<style>
.login-bg {
  background: url(../assets/images/background.png) no-repeat center fixed;
  -webkit-background-size: cover;
  background-size: cover;
}
</style>
