import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/tutorials.graphql'
import GET_QUERY from '@/queries/tutorial.graphql'
import CREATE_MUTATION from '@/queries/createTutorial.graphql'
import UPDATE_MUTATION from '@/queries/updateTutorial.graphql'
import DELETE_MUTATION from '@/queries/deleteTutorial.graphql'

export async function list() {
  const res = await client.query({
    query: LIST_QUERY,
  })
  return res.data.tutorials
}

export async function get({id}) {
  const res = await client.query({
    query: GET_QUERY,
    variables: {id},
  })
  return res.data.tutorial
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createTutorial
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {input},
  })
  return res.data.updateTutorial
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteTutorial
}
