<template>
  <div class="intervention">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="InterventionForm"
            title="Ajouter une intervention"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InterventionForm from '@/components/InterventionForm'
import {interventionService} from '@/services'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'NewIntervention',
  components: {RenderReact},
  data: () => ({ InterventionForm }),
  methods: {
    async onSubmit(values) {
      await interventionService.create(values)
      this.$router.push({ name: 'list-intervention' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.intervention {
  margin-bottom: 48px;
}
</style>
