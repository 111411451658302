<template>
  <div>
    <RenderReact
      :component="Config"
      :config="config"
      :tab="$route.query.tab"
      :routerPush="$router.push.bind($router)"
      @updateConfig="setConfig"
    />
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import RenderReact from '@/components/RenderReact'
import Config from './index'

export default {
  name: 'Config',
  components: {
    RenderReact,
  },
  computed: mapState('user', ['config']),
  methods: mapMutations('user', ['setConfig']),
  data: () => ({ Config }),
}
</script>
