<template>
  <div class="home">
    <div class="counter">
      <div class="count signalement" @click="$router.push({ name: 'list-reporting' })"
           :class="{ disabled: config && !config.features.includes('signalement') }">
        <ReportingIcon></ReportingIcon>
        <div class="length">{{reports ? onlyActive(reports).length : 0}}</div>
        <div class="title">Signalements</div>
      </div>
      <div class="count concertation" @click="$router.push({ name: 'list-concertation' })"
           :class="{ disabled: config && !config.features.includes('concertation') }">
        <ConcertationIcon></ConcertationIcon>
        <div class="length">{{concertations ? onlyActive(concertations).length : 0}}</div>
        <div class="title">Concertations</div>
      </div>
      <div class="count satisfaction" @click="$router.push({ name: 'list-satisfaction' })"
           :class="{ disabled: config && !config.features.includes('concertation') }">
        <SatisfactionIcon></SatisfactionIcon>
        <div class="length">{{satisfactions ? onlyActive(satisfactions).length : 0}}</div>
        <div class="title">Satisfactions</div>
      </div>
      <div class="count travaux" @click="$router.push({ name: 'list-work' })"
           :class="{ disabled: config && !config.features.includes('travaux') }">
        <WorkIcon></WorkIcon>
        <div class="length">{{works ? onlyActive(works).length : 0}}</div>
        <div class="title">Travaux</div>
      </div>
      <div class="count intervention" @click="$router.push({ name: 'list-intervention' })"
           :class="{ disabled: config && !config.features.includes('intervention') }">
        <InterventionIcon></InterventionIcon>
        <div class="length">{{interventions ? onlyActive(interventions).length : 0}}</div>
        <div class="title">Interventions</div>
      </div>
      <div class="count tribune" @click="$router.push({ name: 'list-tribune' })"
           :class="{ disabled: config && !config.features.includes('tribune') }">
        <TribuneIcon></TribuneIcon>
        <div class="length">{{tribunes ? onlyActive(tribunes).length : 0}}</div>
        <div class="title">Tribunes</div>
      </div>
    </div>
    <div class="container">
      <Error :error="error"></Error>
      <div class="container-title">Les dernières actions en cours</div>
      <div v-for="card in lastActions" :key="card.id || card._id">
        <component v-if="card.type !== 'concertation'" v-bind:is="getCard(card)" v-bind="{ [card.type]: card }" />
        <template v-else>
          <RenderReact
            :component="getCard(card)"
            v-bind="{ [card.type]: card }"
            @routerPush="$router.push"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ErrorComponent from '@/components/Error'
import ReportingIcon from '@/assets/picto/signalement.svg'
import ConcertationIcon from '@/assets/picto/concertation.svg'
import SatisfactionIcon from '@/assets/picto/satisfaction.svg'
import WorkIcon from '@/assets/picto/travaux.svg'
import InterventionIcon from '@/assets/picto/interventions.svg'
import TribuneIcon from '@/assets/picto/tribune.svg'
import ReportingCard from '@/components/ReportingCard'
import ConcertationCard from '@/components/ConcertationCard'
import SatisfactionCard from '@/components/SatisfactionCard'
import WorkCard from '@/components/WorkCard'
import InterventionCard from '@/components/InterventionCard'
import TribuneCard from '@/components/TribuneCard'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'AdminHome',
  data: () => ({
    error: undefined,
    lastActions: [],
  }),
  components: {
    RenderReact,
    Error: ErrorComponent,
    ReportingIcon,
    ConcertationIcon,
    SatisfactionIcon,
    WorkIcon,
    InterventionIcon,
    TribuneIcon,
  },
  computed: {
    ...mapState('reporting', ['reports']),
    ...mapState('concertation', ['concertations']),
    ...mapState('satisfaction', ['satisfactions']),
    ...mapState('work', ['works']),
    ...mapState('intervention', ['interventions']),
    ...mapState('tribune', ['tribunes']),
    ...mapState('user', ['config']),
  },
  methods: {
    ...mapActions('reporting', ['fetchReports']),
    ...mapActions('concertation', ['fetchConcertations']),
    ...mapActions('satisfaction', ['fetchSatisfactions']),
    ...mapActions('work', ['fetchWorks']),
    ...mapActions('intervention', ['fetchInterventions']),
    ...mapActions('tribune', ['fetchTribunes']),
    getCard({ type }) {
      switch (type) {
      case 'report':
        return ReportingCard
      case 'concertation':
        return ConcertationCard
      case 'satisfaction':
        return SatisfactionCard
      case 'work':
        return WorkCard
      case 'intervention':
        return InterventionCard
      case 'tribune':
        return TribuneCard
      }
    },
    onlyActive(data) {
      return data.filter(({ isArchived }) => !isArchived)
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.fetchReports({ status: 'IN_PROGRESS' }),
        this.fetchConcertations(),
        this.fetchSatisfactions(),
        this.fetchWorks(),
        this.fetchInterventions(),
        this.fetchTribunes(),
      ])
      this.lastActions = this.onlyActive([
        ...this.reports.map(report => ({
          type: 'report',
          ...report,
        })),
        ...this.concertations.map(concertation => ({
          type: 'concertation',
          ...concertation,
        })),
        ...this.satisfactions.map(satisfaction => ({
          type: 'satisfaction',
          ...satisfaction,
        })),
        ...this.works.map(work => ({
          type: 'work',
          ...work,
        })),
        ...this.interventions.map(intervention => ({
          type: 'intervention',
          ...intervention,
        })),
        ...this.tribunes.map(tribune => ({
          type: 'tribune',
          ...tribune,
        })),
      ])
        .map(o => ({ ...o, time: new Date(o.createdAt).getTime() }))
        .sort(({ time: a }, { time: b }) => b - a)
        .slice(0, 5)
    } catch (error) {
      console.log(error)
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.home {
  padding-bottom: 48px;
}

.container-title {
  font-weight: 600;
  font-size: 18px;
  margin: 24px 0;
}

.counter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .count {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-white;
    padding: 32px;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
      user-select: none;
      pointer-events: none;
      cursor: default;
    }

    svg {
      width: 54px;
      fill: $color-white;
    }

    .length {
      font-size: 48px;
      font-weight: 500;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
    }

    &.signalement {
      background: $color-reporting;
    }

    &.concertation {
      background: $color-concertation;
    }

    &.satisfaction {
      background: $color-satisfaction;
    }

    &.travaux {
      background: $color-work;
    }

    &.intervention {
      background: $color-intervention;
    }

    &.tribune {
      background: $color-tribune;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .counter {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
