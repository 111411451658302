var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-layout",class:{
    'user-admin':
      _vm.$store.state.user.user && _vm.$store.state.user.user.role !== 'user',
  }},[_c('div',{staticClass:"switch-user",on:{"click":function($event){return _vm.$router.push('/admin/home')}}},[_vm._v(" Toucher pour passer en vue administrateur ")]),(_vm.toggle)?_c('div',{staticClass:"sidebar-overlay",on:{"click":_vm.dismissSidebar}},[_c('div',{staticClass:"sidebar-container"},[_vm._m(0),_c('div',{staticClass:"sidebar-content"},[_c('div',{staticClass:"sidebar-link",on:{"click":_vm.goToDashboard}},[_c('i',{staticClass:"ion-md-home"}),_vm._v(" Tableau de bord ")]),_c('div',{staticClass:"sidebar-link",on:{"click":_vm.goToProfile}},[_c('i',{staticClass:"ion-md-person"}),_vm._v(" Profil ")]),_c('div',{staticClass:"sidebar-link",on:{"click":_vm.logout}},[_c('i',{staticClass:"ion-md-log-out"}),_vm._v(" Déconnexion ")])])])]):_vm._e(),_c('div',{staticClass:"full-header"},[_c('div',{staticClass:"header",class:{ 'header-center-alone': _vm.homeScreenToggled }},[(!_vm.homeScreenToggled)?_c('div',{staticClass:"header-left",on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('i',{staticClass:"ion-ios-menu"})]):_vm._e(),(_vm.config && _vm.config.logo)?_c('div',{staticClass:"header-center"},[_c('img',{attrs:{"src":_vm.config.logo,"alt":"Logo"}})]):_vm._e(),(!_vm.homeScreenToggled)?_c('div',{staticClass:"header-right",class:{ disabled: _vm.config && !_vm.config.features.includes('video') },on:{"click":function($event){return _vm.$router.push({ name: 'user-tutorial' })}}},[_c('i',{staticClass:"ion-ios-film"})]):_vm._e()]),(!_vm.homeScreenToggled)?_c('div',{staticClass:"navigation"},[_c('div',{staticClass:"navigation-link",class:{ disabled: _vm.config && !_vm.config.features.includes('tribune') },on:{"click":function($event){return _vm.$router.push({ name: 'user-home' })}}},[_c('div',{staticClass:"navigation-link-icon"},[(_vm.tribunesToRead > 0)?_c('span',{staticClass:"navigation-link-badge"},[_vm._v(" "+_vm._s(_vm.tribunesToRead)+" ")]):_vm._e(),_c('TribuneIcon')],1)]),_c('div',{staticClass:"navigation-link",class:{
          disabled: _vm.config && !_vm.config.features.includes('signalement'),
        },on:{"click":function($event){return _vm.$router.push({ name: 'user-signalements-list' })}}},[_c('div',{staticClass:"navigation-link-icon"},[(_vm.signalementsToRead > 0)?_c('span',{staticClass:"navigation-link-badge"},[_vm._v(" "+_vm._s(_vm.signalementsToRead)+" ")]):_vm._e(),_c('ReportIcon')],1)]),_c('div',{staticClass:"navigation-link",class:{
          disabled: _vm.config && !_vm.config.features.includes('concertation'),
        },on:{"click":function($event){return _vm.$router.push({ name: 'user-votes-list' })}}},[_c('div',{staticClass:"navigation-link-icon"},[(_vm.votesToRead > 0)?_c('span',{staticClass:"navigation-link-badge"},[_vm._v(" "+_vm._s(_vm.votesToRead)+" ")]):_vm._e(),_c('VoteIcon')],1)]),_c('div',{staticClass:"navigation-link",class:{ disabled: _vm.config && !_vm.config.features.includes('travaux') },on:{"click":function($event){return _vm.$router.push({ name: 'user-travaux-list' })}}},[_c('div',{staticClass:"navigation-link-icon"},[(_vm.travauxToRead > 0)?_c('span',{staticClass:"navigation-link-badge"},[_vm._v(" "+_vm._s(_vm.travauxToRead)+" ")]):_vm._e(),_c('WorkIcon')],1)]),_c('div',{staticClass:"navigation-link",class:{
          disabled: _vm.config && !_vm.config.features.includes('intervention'),
        },on:{"click":function($event){return _vm.$router.push({ name: 'user-interventions-list' })}}},[_c('div',{staticClass:"navigation-link-icon"},[(_vm.interventionsToRead > 0)?_c('span',{staticClass:"navigation-link-badge"},[_vm._v(" "+_vm._s(_vm.interventionsToRead)+" ")]):_vm._e(),_c('InterventionIcon')],1)]),_c('div',{staticClass:"navigation-link",class:{ disabled: _vm.config && !_vm.config.features.includes('contact') },on:{"click":function($event){return _vm.$router.push({ name: 'user-contacts' })}}},[_c('ContactIcon')],1)]):_vm._e()]),(_vm.homeScreenToggled)?_c('div',{class:{
      'home-screen':
        _vm.config.homeScreenButtonMessage || _vm.config.homeScreenMessage,
    }},[_c('RenderReact',{attrs:{"component":_vm.TextEditorRenderer,"children":_vm.config.homeScreenMessage}}),(_vm.config.homeScreenPicture)?_c('img',{staticClass:"home-screen-image",class:{
        'home-screen-image-fullscreen':
          !_vm.config.homeScreenButtonMessage && !_vm.config.homeScreenMessage,
      },attrs:{"src":_vm.config.homeScreenPicture},on:{"click":_vm.onClickImage}}):_vm._e()],1):_c('router-view')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-header"},[_c('img',{attrs:{"src":require("../../assets/images/logo.png")}})])}]

export { render, staticRenderFns }