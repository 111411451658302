import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Upload, message } from 'antd'
import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons'
import { client } from '@/utils/api'
import styles from '@/assets/styles/PictureInput.module.scss'

function beforeUpload(file) {
  const isSupportedType = ['image/jpeg', 'image/png'].includes(file.type)
  if (!isSupportedType) {
    message.error('Type de fichier non supporté')
  }
  return isSupportedType
}

function PictureInput({ value, onChange }) {
  const [loading, setLoading] = useState(false)

  function handleChange(info) {
    if (info.file.status === 'uploading') {
      setLoading(true)
    } else if (info.file.status === 'done') {
      onChange(info.file.response.url)
    }
  }

  async function uploadFileRequest({ file, onError, onProgress, onSuccess }) {
    try {
      const form = new FormData()
      form.append('file', file)
      const res = await client.post('/files/images/upload', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: onProgress,
      })
      onSuccess(res.data)
    } catch (error) {
      onError(error)
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <DownloadOutlined />}
      <div className={styles.uploadButton}>Ajouter une photo</div>
    </div>
  )

  return (
    <>
      <Upload
        listType="picture-card"
        className={styles.root}
        showUploadList={false}
        accept="image/*"
        customRequest={uploadFileRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >

        {value ? <img src={value} alt="avatar" style={{
          width: '100px', height: '100px', backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }} /> : uploadButton}

      </Upload>
      <div className="text-muted" style={{ fontSize: 12 }}>
        Uniquement les images au format JPEG ou PNG sont acceptées
      </div>
    </>

  )
}

PictureInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default PictureInput
