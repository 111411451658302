import React, {useEffect, useState, useMemo} from 'react'
import {Button, Drawer, Modal, notification} from 'antd'
import styles from './Config.module.scss'
import {signalementTitleService} from '@/services'
import ReportTypeForm from '@/views/admin/Config/ReportTypeForm'

function ReportType() {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [reportTypes, setReportTypes] = useState(null)
  const [error, setError] = useState(null)
  const parentReportTypes = useMemo(
    () => reportTypes?.filter(v => !v.parentId) ?? [],
    [reportTypes],
  )
  const formattedReportTypes = useMemo(
    () => reportTypes
      ?.filter(v => !v.parentId)
      ?.map(v => ({
        ...v,
        children: reportTypes.filter(c => c.parentId === v.id),
      })) ?? [],
    [reportTypes],
  )

  useEffect(() => {
    setLoading(true)
    signalementTitleService.list()
      .then((reportTypes) => setReportTypes(reportTypes))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [])

  function createReportType(values) {
    return signalementTitleService.create(values)
      .then(link => {
        setReportTypes([...reportTypes, link])
        setDrawerVisible(false)
      })
      .catch(error => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
  }

  async function deleteReportType(reportType) {
    Modal.confirm({
      title: 'Confirmer',
      icon: null,
      content: 'Voulez-vous vraiment supprimer ce type de signalement ?',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      confirmLoading: true,
      onOk: () =>
        signalementTitleService.destroy({ id: reportType.id })
          .then(() => setReportTypes(reportTypes.filter(v => v.id !== reportType.id)))
          .catch((error) => notification.error({
            message: 'Erreur !',
            description: error.toString(),
          })),
    })
  }

  function renderContent() {
    if (loading) {
      return null
    }

    if (error) {
      return error.message
    }

    return (
      <>
        {formattedReportTypes.map(parent => (
          <div key={parent.id} className={styles.reportTypeParentCard}>
            <div className={styles.reportType}>
              {parent.name}
              <Button type="link" danger onClick={() => deleteReportType(parent)}>
                Supprimer
              </Button>
            </div>
            {parent.children.map(child => (
              <div key={child.id} className={styles.reportTypeChildCard}>
                {child.name}
                <Button type="link" danger onClick={() => deleteReportType(child)}>
                  Supprimer
                </Button>
              </div>
            ))}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <div className={styles.header}>
        <Button type="primary" size="large" onClick={() => setDrawerVisible(true)}>
          Ajouter un type de signalement
        </Button>
      </div>

      {renderContent()}

      <Drawer
        title="Ajouter un type de signalement"
        placement="right"
        width="min(100vw, 600px)"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <ReportTypeForm parentReportTypes={parentReportTypes} onSubmit={createReportType} />
      </Drawer>
    </>
  )
}

export default ReportType
