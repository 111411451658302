<template>
  <div class="user-list-tribunes">
    <template v-if="mappedTribunes.length > 0">
      <div class="card" v-for="tribune in tribunes" :key="tribune.id">
        <div class="card-header">
          <div class="card-type-icon">
            <TribuneIcon></TribuneIcon>
          </div>
          <div class="card-icon">
            <component v-if="tribune.pictogram" v-bind:is="findIcon(tribune.pictogram).image"></component>
          </div>
        </div>
        <div class="card-body">
          <div class="card-title">
            {{tribune.name}}
          </div>
          <div class="card-duration">
            <CalendarIcon></CalendarIcon>
            Le {{tribune.startAt | moment("DD/MM/YYYY")}}
          </div>
          <!-- <div class="card-duration">
            <DurationIcon></DurationIcon>
            {{tribune.createdAt | moment("from", "now")}}
          </div> -->
          <div class="card-description">
            <RenderReact
              :component="TextEditorRenderer"
              :children="tribune.description"
            />
          </div>
          <img v-if="tribune.file" class="card-image" :src="tribune.file">
        </div>
        <div class="card-footer">
          <div v-if="tribune.hasRead" class="card-button read">
            <i class="ion-ios-checkmark-circle"></i>
            Information déjà lue
          </div>
          <ClickLoaderWrapper v-else class="card-button" @click="markAsReadWrapper(tribune)">
<!--            <i class="ion-ios-checkmark-circle-outline"></i>-->
            J'ai lu l'information
          </ClickLoaderWrapper>
        </div>
      </div>
    </template>
    <div v-else class="nothing-to-show">
      <TribuneIcon></TribuneIcon>
      Rien à signaler
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import { Capacitor } from '@capacitor/core'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { mapActions, mapState } from 'vuex'
import { Dialog } from '@capacitor/dialog'
import ClickLoaderWrapper from '@/components/ClickLoaderWrapper'
import DurationIcon from '@/assets/picto/historique.svg'
import CalendarIcon from '@/assets/picto/agenda.svg'
import TribuneIcon from '@/assets/picto/tribune.svg'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import icons from '@/utils/icons'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'UserListTribunes',
  components: {
    RenderReact,
    ClickLoaderWrapper,
    TribuneIcon,
    CalendarIcon,
    DurationIcon,
  },
  data: () => ({
    TextEditorRenderer,
    hammer: undefined,
  }),
  computed: {
    ...mapState('tribune', ['tribunes']),
    mappedTribunes() {
      return this.tribunes
        ?.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime()) ?? []
    },
  },
  methods: {
    ...mapActions('tribune', ['fetchTribunes', 'markAsRead']),
    findIcon(name) {
      return icons.find(({ value }) => value === name) ?? {}
    },
    markAsReadWrapper(tribune, cb) {
      cb(this.markAsRead({ id: tribune.id }))
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      FirebaseAnalytics.setScreenName({
        screenName: 'list-tribunes',
        nameOverride: 'ListTribunesScreen',
      }).catch(console.error)
    }
    this.hammer = new Hammer(document.body)
    this.hammer.on('swipe', (event) => {
      if (event?.direction === 2) {
        this.$router.push({ name: 'user-signalements-list' })
      }
    })
    try {
      await this.fetchTribunes()
    } catch (error) {
      await Dialog.alert({
        title: 'Erreur',
        message: error.message,
      })
    }
  },
  async beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/card";

.card {
  @include user-card($color-tribune);
}

.nothing-to-show {
  @include nothing-to-show($color-tribune);
}
</style>
