import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Select} from 'antd'
import store from '@/store'
import useVueState from '@/hooks/useVueState'

function LocationTypeInput({ value, onChange, disabled }) {
  const localisationTypes = useVueState((s) => s.user.localisationTypes)

  useEffect(() => {
    if (!localisationTypes) {
      store.dispatch('user/fetchLocalisationTypes')
        .catch(console.error)
    }
  }, [localisationTypes])

  return (
    <Select defaultValue={value} onChange={onChange} placeholder="Sélectionner" disabled={disabled}>
      {localisationTypes?.map((type) => (
        <Select.Option key={type.id} value={type.id}>
          {type.name}
        </Select.Option>
      ))}
    </Select>
  )
}

LocationTypeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default LocationTypeInput
