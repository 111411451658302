<template>
  <div class="home">
    <div class="header">
      <AuthIcon></AuthIcon> Roles et autorisations
    </div>
    <div class="container">
      <div class="header-actions">
        <div>
          Utilisateur :
          <div class="select">
            <select v-model="user">
              <option :value="undefined" selected>Sélectionner</option>
              <option v-for="curr in users" :key="curr.id" :value="curr">
                {{curr.firstName}} {{curr.lastName}}
              </option>
            </select>
          </div>
        </div>
        <router-link :to="{ name: 'admin-create-user' }" class="btn primary"
          :class="{ disabled: !config || config.type !== 'admin' }">
          Créer un utilisateur
        </router-link>
      </div>
      <Error :error="error"></Error>
      <div v-if="message" class="success">
        {{message}}
      </div>
      <form v-if="user" @submit="save">
        <div class="form-field">
          <div class="form-field-label">
            Sélectionnez les localisations visibles
          </div>
          <LocalisationInput
            :key="user.id"
            v-model="viewableLocalisations"
            :defaultValue="user.viewableLocalisations"
            unit="homestay"
          />
        </div>
        <template v-if="user.role === 'OWNER'">
          <div class="form-field">
            <div class="form-field-label">
              Permissions
            </div>
            <div style="overflow-x: auto">
              <table width="100%" border="1px" style="margin-bottom: 16px">
                <thead>
                  <tr>
                    <th></th>
                    <th v-for="(name, index) in Object.values(names)" :key="index">
                      {{name}}
                    </th>
                    <th>Toutes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(type, index) in types" :key="index" :class="{ disabledRow: !hasFeature(type) }">
                    <th>{{type | capitalize}}</th>
                    <td v-for="(key, index) in Object.keys(names)" :key="index" style="text-align: center">
                      <input v-if="hasFeature(type)" v-model="user.permissions[type][key]" type="checkbox">
                      <input v-else :value="false" type="checkbox" disabled>
                    </td>
                    <td>
                      <input v-if="hasFeature(type)" :ref="type" :checked="allFeaturesEnabled.includes(type)"
                             type="checkbox" @input="checkAll(type)">
                      <input v-else :value="false" type="checkbox" disabled>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <div class="form-submission">
          <button type="submit" class="btn primary">
            Sauvegarder
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import LocalisationInput from '@/components/LocalisationInput'
import AuthIcon from '@/assets/picto/auth.svg'
import Error from '@/components/Error'
import {userService} from '@/services'

export default {
  name: 'SuperOwnerPermissions',
  components: {
    LocalisationInput,
    Error,
    AuthIcon,
  },
  data: () => ({
    error: undefined,
    users: undefined,
    user: undefined,
    message: '',
    viewableLocalisations: [],
    types: [
      'signalement', 'concertation', 'satisfaction',
      'travaux', 'intervention', 'tribune',
    ],
    names: {
      create: 'Créer',
      read: 'Visualiser',
      update: 'Editer',
      delete: 'Supprimer',
    },
  }),
  watch: {
    user() {
      this.error = undefined
      this.message = ''
      this.viewableLocalisations = undefined
    }
  },
  computed: {
    ...mapState('user', ['config']),
    allFeaturesEnabled() {
      return this.user?.permissions && this.types.filter(type =>
        Object.values(this.user.permissions[type]).every(v => v === true) &&
        this.hasFeature(type)
      )
    },
  },
  methods: {
    hasFeature(type) {
      switch (type) {
      case 'satisfaction':
        return this.config.features?.includes('concertation')
      default:
        return this.config.features?.includes(type)
      }
    },
    checkAll(type) {
      if (this.allFeaturesEnabled.includes(type)) {
        this.user.permissions[type].create = false
        this.user.permissions[type].read = false
        this.user.permissions[type].update = false
        this.user.permissions[type].delete = false
      } else {
        this.user.permissions[type].create = true
        this.user.permissions[type].read = true
        this.user.permissions[type].update = true
        this.user.permissions[type].delete = true
      }
    },
    async save(event) {
      event.preventDefault()
      this.error = undefined
      this.message = ''
      try {
        await userService.updateUser({
          id: this.user.id,
          permissions: this.user.permissions,
          viewableLocalisations: this.viewableLocalisations,
        })
        this.message = 'Permissions modifiées.'
      } catch (error) {
        this.error = error
      }
    },
  },
  async mounted() {
    try {
      this.users = await userService.list({
        only: ['SUPER_OWNER', 'OWNER'],
      })
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.home {
  padding-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

table th, table td {
  padding: 8px;
}

.header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;

  .select {
    z-index: 3000;
    display: inline;
    border: 1px solid #000;
    padding: 4px 4px;
    border-radius: 12px;

    select {
      border: none;
      box-shadow: none;
      background: transparent;
      color: #000;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .btn {
    &,
    div {
      display: flex;
      flex-direction: row;
      align-self: center;
    }

    svg {
      height: 32px;
      margin-right: 8px;
      fill: $color-blue-light;
    }
  }
}

.disabledRow th {
  opacity: .4;
}

.success {
  background: $color-green;
  padding: 16px;
  margin-bottom: 16px;
  color: $color-white;
  border-radius: 4px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }

  .header-actions {
    flex-direction: column;
    align-items: flex-start;

    .btn {
      margin-top: 16px;
      align-self: flex-start;
    }
  }
}
</style>
