<template>
  <div class="edit-company">
    <div class="container">
      <div class="header">
        <div class="header-title">
          Editer un client
        </div>
      </div>
      <Error :error="error"></Error>
      <form v-if="company" @submit="onSubmit">
        <div class="step">
          <div class="step-title">Informations sur l'entreprise</div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Nom de l'entreprise
            <span class="required">*</span>
          </div>
          <input v-model="name" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Adresse de l'entreprise
          </div>
          <input v-model="address" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Logo de l'entreprise
          </div>
          <PictureUrlInput v-model="logo" />
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Afficher le logo de l'entreprise
          </div>
          <input v-model="showLogo" class="form-field-input" type="checkbox">
        </div>

        <div class="step">
          <div class="step-title">Configuration</div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Fonctionnalités
            <span class="required">*</span>
          </div>
          <div class="treeselect-wrapper">
            <TreeSelect v-model="features" :multiple="true" :options="options"
                        style="margin-bottom: 32px"/>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Page d'accueil utilisateurs
            <span class="required">*</span>
          </div>
          <div class="treeselect-wrapper">
            <TreeSelect v-model="home" :options="homes"
                        style="margin-bottom: 32px"/>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Prefixe UL
          </div>
          <input v-model="ulPrefix" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Nombre de logement actif
          </div>
          <input :value="company.activeHomestays" disabled class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Demande de désabonnement ?
          </div>
          <input :value="company.subscription.cancelled" class="form-field-input" type="checkbox" disabled>
        </div>

        <div class="form-field">
          <div class="form-field-label">
            Essai gratuit jusqu'au
          </div>
          <DatePicker
            v-model="freeTrialUntil"
            :lang="lang"
            format="DD-MM-YYYY"
          />
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Desactiver le compte ?
          </div>
          <input v-model="disabled" class="form-field-input" type="checkbox">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Type d'abonnement
            <span class="required">*</span>
          </div>
          <div class="treeselect-wrapper">
            <TreeSelect v-model="plan" :options="plans"
                        style="margin-bottom: 32px"/>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Cacher la documentation ?
          </div>
          <input v-model="hideDocumentation" class="form-field-input" type="checkbox">
        </div>

        <div class="form-submission">
          <div class="btn danger" @click="deleteCompany">
            Supprimer
          </div>
          <div class="btn default" @click="$router.go(-1)">
            Annuler
          </div>
          <button type="submit" class="btn primary">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TreeSelect from '@riophae/vue-treeselect'
import DatePicker from 'vue2-datepicker'
import * as companyService from '@/services/companyService'
import featureList from '@/utils/featureList'
import ErrorComponent from '@/components/Error'
import PictureUrlInput from '@/components/PictureUrlInput'

export default {
  name: 'EditCompany',
  data: () => ({
    error: undefined,
    loading: false,
    company: undefined,
    name: '',
    address: '',
    logo: '',
    showLogo: false,
    ulPrefix: '',
    features: [],
    disabled: false,
    hideDocumentation: false,
    home: 'home',
    plan: '',
    homes: [
      { label: 'Accueil', id: 'home' },
      { label: 'Tribunes', id: 'user-home' },
      { label: 'Signalements', id: 'user-signalements-list' },
      { label: 'Concertations / Satisfactions', id: 'user-votes-list' },
      { label: 'Travaux', id: 'user-travaux-list' },
      { label: 'Interventions', id: 'user-interventions-list' },
      { label: 'Contacts', id: 'user-contacts' },
    ],
    plans: [
      { label: 'Propriétaire', id: 'small' },
      { label: 'Copropriété', id: 'normal' },
      { label: 'Autre', id: '' },
    ],
    freeTrialUntil: null,
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    },
  }),
  components: {
    PictureUrlInput,
    Error: ErrorComponent,
    TreeSelect,
    DatePicker,
  },
  computed: {
    options() {
      return featureList.map(v => ({
        id: v.value,
        label: v.name,
      }))
    },
  },
  methods: {
    async deleteCompany() {
      if (!confirm('Voulez-vous vraiment supprimer le compte et toutes les données ?')) {
        return
      }
      const res = await companyService.adminDelete({ companyId: this.$route.params.id })
      if (!res) {
        alert('Le compte n\'a pas pu etre supprimé')
        return
      }

      this.$router.push({ name: 'superadmin-company-list' })
    },
    async onSubmit(event) {
      event.preventDefault()
      this.loading = true
      this.error = undefined
      try {
        if (!this.name || !this.features?.length || !this.home) {
          throw new Error('Champs requis manquant.')
        }
        await companyService.adminUpdate({
          id: this.$route.params.id,
          name: this.name,
          address: this.address,
          logo: this.logo,
          showLogo: this.showLogo,
          ulPrefix: this.ulPrefix,
          features: this.features,
          home: this.home,
          disabled: this.disabled,
          hideDocumentation: this.hideDocumentation,
          subscription: {
            freeTrialUntil: this.freeTrialUntil,
            plan: this.plan,
          },
        })
        this.$router.push({ name: 'superadmin-company-list' })
      } catch (error) {
        this.error = error.isAxiosError && error.response?.data?.message
          ? new Error(error.response?.data?.message)
          : error
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    try {
      this.company = await companyService.adminGet({ id: this.$route.params.id })
      this.name = this.company.name
      this.address = this.company.address
      this.logo = this.company.logo
      this.showLogo = this.company.showLogo
      this.ulPrefix = this.company.ulPrefix
      this.features = this.company.features
      this.home = this.company.home
      this.disabled = this.company.disabled
      this.hideDocumentation = this.company.hideDocumentation
      this.plan = this.company.subscription.plan
      if (this.company.subscription.freeTrialUntil) {
        this.freeTrialUntil = new Date(this.company.subscription.freeTrialUntil)
      }
    } catch (error) {
      this.error = error.isAxiosError && error.response?.data?.message
        ? new Error(error.response?.data?.message)
        : error
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/styles/variables';

.edit-company {
  margin-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 16px;

  .header-title {
    font-weight: 600;
    font-size: 24px;
  }
}

.step {
  .step-title {
    font-size: 18px;
    font-weight: 500;
    margin: 16px 0 8px;
  }
}

.form-field-input {
  border: 2px solid $color-grey;
  padding: 6px 12px;
  border-radius: 4px;
  box-shadow: none;
  max-width: 400px;

  &:focus {
    outline: none;
    border-color: $color-blue;
  }
}

.error,
.form-submission,
.treeselect-wrapper {
  max-width: 400px;
}
</style>
