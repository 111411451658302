import {signalementService} from '@/services'

const initialState = {
  reports: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setReports(state, reports) {
      state.reports = reports
    }
  },
  actions: {
    async fetchReports({commit}, {filter} = {}) {
      const data = await signalementService.list({filter})
      commit('setReports', data)
      return data
    },
    async markAsRead({dispatch}, {id}) {
      await signalementService.readed({id})
      return dispatch('fetchReports')
    }
  }
}
