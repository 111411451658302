import React from 'react'
import moment from 'moment'

function DateRangeFormatter({ startAt, endAt, noData = 'Date indisponible' }) {
  if (!startAt && !endAt) {
    return noData
  }

  if (startAt && !endAt) {
    return `A partir du ${moment(startAt).format('DD/MM/YYYY')}`
  }

  if (!startAt && endAt) {
    return `Fin le ${moment(endAt).format('DD/MM/YYYY')}`
  }

  return `Du ${moment(startAt).format('DD/MM/YYYY')} au ${moment(endAt).format('DD/MM/YYYY')}`
}

export default DateRangeFormatter
