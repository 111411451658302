<template>
  <div class="profile">
    <Error :error="error"></Error>
    <template v-if="user">
      <form @submit="submitChangePassword">
        <div class="form-field">
          <div class="form-field-label">
            Ancien mot de passe
            <span class="required">*</span>
          </div>
          <input v-model="form.oldPassword" class="form-field-input" type="password">
        </div>

        <div class="form-field">
          <div class="form-field-label">
            Nouveau mot de passe
            <span class="required">*</span>
          </div>
          <input v-model="form.newPassword" class="form-field-input" type="password">
        </div>

        <div class="form-field">
          <div class="form-field-label">
            Confirmer le mot de passe
            <span class="required">*</span>
          </div>
          <input v-model="form.confirmPassword" class="form-field-input" type="password">
        </div>

        <div class="form-submission">
          <button type="submit" class="btn primary">
            Changer le mot de passe
          </button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import {Capacitor} from '@capacitor/core'
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics'
import { mapState, mapActions } from 'vuex'
import ErrorComponent from '@/components/Error'

export default {
  name: 'UserProfile',
  data: () => ({
    error: undefined,
    form: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  }),
  components: { Error: ErrorComponent },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    ...mapActions('user', ['fetchUser', 'changePassword']),
    async submitChangePassword(event) {
      event.preventDefault()
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.error = new Error('Mot de passe différent.')
        return
      }
      try {
        await this.changePassword(this.form)
        this.$router.go(-1)
      } catch (error) {
        if (error.isAxiosError && error.response && error.response.data) {
          this.error = new Error(error.response.data.message)
        } else {
          this.error = error
        }
      }
    }
  },
  async mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      FirebaseAnalytics.setScreenName({
        screenName: 'profile',
        nameOverride: 'ProfileScreen',
      }).catch(console.error)
    }
    try {
      await this.fetchUser()
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.profile {
  padding: 32px 16px;
}
</style>
