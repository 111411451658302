import {useEffect, useState} from 'react'
import store from '@/store'

function useVueState(selector) {
  const [value, setValue] = useState(null)

  useEffect(() => {
    setValue(selector(store.state))
    const unwatch = store.watch(selector, (value) => {
      setValue(value)
    })
    return () => unwatch()
  }, [])

  return value
}

export default useVueState
