import React, {useEffect, useState, useMemo} from 'react'
import {Button, Drawer, Modal, notification, Space, Table} from 'antd'
import styles from './Config.module.scss'
import {contactLinkService} from '@/services'
import LocationFormatter from '@/components/LocationFormatter'
import ContactLinkForm from '@/views/admin/Config/ContactLinkForm'

function ContactLink() {
  const [currentLink, setCurrentLink] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [links, setLinks] = useState(null)
  const [error, setError] = useState(null)
  const columns = useMemo(() => [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: url => <img src={url} alt="Image" height="32px"/>,
    },
    {
      title: 'Titre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Lien',
      dataIndex: 'href',
      key: 'href',
      render: href => <a href={href} target="_blank" rel="noreferrer nofollow">{href}</a>,
    },
    {
      title: 'Localisations',
      dataIndex: 'localisations',
      key: 'localisations',
      render: locations => locations?.length ? <LocationFormatter locations={locations} /> : null,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => {
            setCurrentLink(record)
            setDrawerVisible(true)
          }}>
            Editer
          </Button>
          <Button type="link" danger onClick={() => deleteLink(record)}>
            Supprimer
          </Button>
        </Space>
      ),
    },
  ], [links])

  useEffect(() => {
    setLoading(true)
    contactLinkService.list()
      .then((contacts) => setLinks(contacts))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!drawerVisible) {
      setCurrentLink(null)
    }
  }, [drawerVisible])

  function createOrUpdateLink(values) {
    (currentLink ? updateContact : createContact)(values)
      .then(() => setDrawerVisible(false))
      .catch(error => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
  }

  function createContact(values) {
    return contactLinkService.create(values)
      .then(link => setLinks([...links, link]))
  }

  function updateContact(values) {
    return contactLinkService.update({
      id: currentLink.id,
      ...values,
    }).then(link => setLinks(links.map(v => v.id === link.id ? link : v)))
  }

  async function deleteLink(link) {
    Modal.confirm({
      title: 'Confirmer',
      icon: null,
      content: 'Voulez-vous vraiment supprimer le lien ?',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      confirmLoading: true,
      onOk: () =>
        contactLinkService.destroy({ id: link.id })
          .then(() => setLinks(links.filter(v => v.id !== link.id)))
          .catch((error) => notification.error({
            message: 'Erreur !',
            description: error.toString(),
          })),
    })
  }

  function renderContent() {
    if (loading) {
      return null
    }

    if (error) {
      return error.message
    }

    return <Table dataSource={links} columns={columns} pagination={false} rowKey="id"/>
  }

  return (
    <>
      <div className={styles.header}>
        <Button type="primary" size="large" onClick={() => setDrawerVisible(true)}>
          Ajouter un lien
        </Button>
      </div>

      {renderContent()}

      <Drawer
        title={currentLink ? 'Editer un lien' : 'Ajouter un lien'}
        placement="right"
        width="min(100vw, 600px)"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <ContactLinkForm link={currentLink} onSubmit={createOrUpdateLink} />
      </Drawer>
    </>
  )
}

export default ContactLink
