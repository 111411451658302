<template>
  <div class="status" :class="toClass"></div>
</template>

<script>
export default {
  name: "StatusIcon",
  props: ["status"],
  computed: {
    toClass() {
      switch (this.status) {
        case 0:
        case 6:
        case 8:
          return "waiting";
        case 1:
        case 2:
        case 3:
          return "progress";
        case 4:
        case 5:
        case 7:
          return "closed";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.status {
  margin: 0 auto 16px;
  border-radius: 50%;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;

  &.waiting {
    background: #ffd100;
  }

  &.progress {
    background: #ff8000;
  }

  &.closed {
    background: #1ad566;
  }
}
</style>
