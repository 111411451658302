<template>
  <optgroup v-if="data.children && data.children.length" :label="data.name">
    <SelectOption v-for="curr in data.children" :key="curr.id" :data="curr"></SelectOption>
  </optgroup>
  <option v-else :value="data.id">
    {{data.name}}
  </option>
</template>

<script>
export default {
  name: 'SelectOption',
  props: ['data'],
}
</script>
