<template>
  <div class="reporting">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center" v-if="report">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="ReportForm"
            :report="report"
            title="Ajouter un signalement"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'
import {signalementService} from '@/services'
import RenderReact from '@/components/RenderReact'
import ReportForm from '@/components/ReportForm'

export default {
  name: 'EditReporting',
  components: {
    RenderReact,
  },
  data: () => ({
    report: null,
    ReportForm,
  }),
  methods: {
    async onSubmit(values) {
      const id = this.$route.params.id
      await signalementService.update({
        ...values,
        id,
      })
      this.$router.push({ name: 'view-reporting', params: { id } })
    },
  },
  async mounted() {
    this.report = await signalementService.get({ id: this.$route.params.id })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.reporting {
  margin-bottom: 48px;
}
</style>
