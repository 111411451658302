<template>
  <div class="side-nav-section">
    <div class="side-nav-section-title">
      {{title}}
    </div>
    <div class="side-nav-section-links">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideNavSection',
  props: ['title'],
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.side-nav-section {
  border-bottom: 1px solid rgba(180,188,199,.32);
  padding-bottom: 20px;
}

.side-nav-section-title {
  padding: 25px 20px 15px;
  transition: padding 0s linear 0s;
  text-transform: uppercase;
  color: #677694;
  font-weight: 500;
  font-size: .8rem;
  line-height: .8rem;
  letter-spacing: .06rem;
  cursor: pointer;
}
</style>
