<template>
  <div class="satisfaction">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center" v-if="satisfaction">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="SatisfactionForm"
            :satisfaction="satisfaction"
            title="Modifier une satisfaction"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'
import {satisfactionService} from '@/services'
import SatisfactionForm from '@/components/SatisfactionForm'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'EditSatisfaction',
  data: () => ({
    SatisfactionForm,
    satisfaction: undefined,
  }),
  components: {
    RenderReact,
  },
  methods: {
    async onSubmit(values) {
      const id = this.$route.params.id
      await satisfactionService.update({
        ...values,
        id,
      })
      this.$router.push({ name: 'view-satisfaction', params: { id } })
    },
  },
  async mounted() {
    this.satisfaction = await satisfactionService.get({
      id: this.$route.params.id,
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.satisfaction {
  margin-bottom: 48px;
}

.title {
  font-size: 24px;
  color: $color-satisfaction;
  font-weight: 600;
  margin: 32px 0;

  svg {
    height: 42px;
    margin-right: 12px;
    fill: $color-satisfaction;
  }
}
</style>
