import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import store from '@/store'
import {API_ENDPOINT} from './api'

const httpLink = createHttpLink({
  uri: `${API_ENDPOINT}${API_ENDPOINT.endsWith('/') ? '' : '/'}graphql`,
})

const authLink = setContext(() => {
  const { token } = store.state.user
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const link = authLink.concat(httpLink)

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

export default client
