import { client } from "@/utils/api";

const initialState = {
  appInfo: undefined,
  appVersionChecked: false,
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setAppInfo(state, appInfo) {
      state.appInfo = appInfo;
    },
  },
  actions: {
    async fetchAppInfo({ commit }) {
      const res = await client.get("appInfo");
      commit("setAppInfo", res.data);
      return res.data;
    },
  },
};
