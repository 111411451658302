import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {Button, Checkbox, Form, Input, notification, Typography} from 'antd'
import styles from './Config.module.scss'
import {configService} from '@/services'
import TextEditor from "@/components/TextEditor/TextEditor";
import PictureInput from "@/components/PictureInput";

const {Title} = Typography

function HomeScreen({ config, updateConfig }) {
  const [form] = Form.useForm()
  const initialValues = useMemo(() => config, [config])

  useEffect(() => {
    form.resetFields()
  }, [initialValues])

  function onSubmit(values) {
    return configService.update({
      ...config,
      ...values,
    })
      .then((values) => {
        updateConfig({
          ...config,
          ...values,
        })
        notification.success({
          message: 'Mis à jour !',
          description: 'Configuration mise à jour.'
        })
      })
      .catch(error => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
  }

  return (
    <div className={styles.generalForm}>
      <Title level={4}>Écran de pré-accueil</Title>
      <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
        <Form.Item name="homeScreenEnabled" label="Activer l'écran de pré-accueil" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item
          name="homeScreenMessage"
          label="Message"
        >
          <TextEditor />
        </Form.Item>

        <Form.Item
          name="homeScreenPicture"
          label="Photo"
        >
          <PictureInput />
        </Form.Item>

        <Form.Item
          name="homeScreenButtonMessage"
          label="Texte du bouton"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

HomeScreen.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
}

export default HomeScreen
