<template>
  <div class="picture-input">
    <input ref="input" class="hidden-input" type="file" accept="video/mp4" @change="onChange"/>
    <div class="button" @click="onClick">
      <div v-if="loading" class="loader"></div>
      <CheckIcon v-else-if="video"></CheckIcon>
      <UploadVideoIcon v-else></UploadVideoIcon>
    </div>
    <div class="show-video" v-if="video">
      <a :href="video" target="_blank">
        Cliquer ici pour voir la vidéo
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Capacitor } from '@capacitor/core'
import { Dialog } from '@capacitor/dialog'
import UploadVideoIcon from '@/assets/picto/tutosvideos.svg'
import CheckIcon from '@/assets/picto/valider.svg'

export default {
  name: 'VideoUrlInput',
  props: ['value'],
  components: { UploadVideoIcon, CheckIcon },
  data: () => ({
    video: undefined,
    loading: false,
  }),
  methods: {
    ...mapActions('admin', ['uploadVideo']),
    onChange(event) {
      if (event.target && event.target.files && event.target.files[0]) {
        const [video] = event.target.files
        this.afterGetVideoFile(video)
      }
    },
    async onClick() {
      if (Capacitor.getPlatform() === 'web') {
        this.$refs.input.click()
      } else {
        await Dialog.alert({
          message: 'Fonctionnalité indisponible sur mobile.'
        })
      }
    },
    async afterGetVideoFile(video) {
      this.loading = true
      try {
        const url = await this.uploadVideo({ video })
        this.video = url
        this.$emit('input', url)
      } catch (error) {
        await Dialog.alert({
          title: 'Erreur',
          message: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.video = this.value
    this.$emit('input', this.video)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.picture-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.show-video {
  margin-left: 16px;
}

.hidden-input {
  display: none;
}

.loader {
  opacity: 1;
  margin: 8px;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid $color-blue-dark;
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;

  &,
  ::after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: inline-block;
  border-radius: 12px;
  cursor: pointer;
  padding: 8px;
  background: $color-grey;

  svg, img {
    height: 48px;
    width: 48px;
  }

  &:hover {
    border-color: $color-blue-light;

    svg {
      fill: $color-blue-light;
    }
  }
}
</style>
