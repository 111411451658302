import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/concertations.graphql'
import GET_QUERY from '@/queries/concertation.graphql'
import ANSWERS_QUERY from '@/queries/concertationAnswers.graphql'
import CREATE_MUTATION from '@/queries/createConcertation.graphql'
import UPDATE_MUTATION from '@/queries/updateConcertation.graphql'
import DELETE_MUTATION from '@/queries/deleteConcertation.graphql'
import ANSWER_MUTATION from '@/queries/answerConcertation.graphql'

export async function list({filter, localisations = []} = {}) {
  const res = await client.query({
    query: LIST_QUERY,
    variables: {
      filter,
      localisations,
    },
  })
  return res.data.concertations
}

export async function get({ id }) {
  const res = await client.query({
    query: GET_QUERY,
    variables: { id },
  })
  return res.data.concertation
}

export async function getAnswers({ id, page, search }) {
  const res = await client.query({
    query: ANSWERS_QUERY,
    variables: {
      id,
      page,
      search,
    },
  })
  return res.data.concertationAnswers
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createConcertation
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {input},
  })
  return res.data.updateConcertation
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteConcertation
}

export async function answer(input) {
  const res = await client.mutate({
    mutation: ANSWER_MUTATION,
    variables: {input},
  })
  return res.data.answerConcertation
}
