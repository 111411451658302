<template>
  <div class="list-companies">
    <div class="container">
      <div class="header">
        <div class="header-title">
          Clients
        </div>
        <router-link class="header-new-company" :to="{ name: 'superadmin-company-new' }">
          Nouveau client
        </router-link>
      </div>
      <Error :error="error"></Error>
      <table class="companies-table">
        <thead>
          <tr>
            <th>Entreprise</th>
            <th>Adresse</th>
            <th>Prefixe UL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="company in companies" :key="company.id">
            <td>{{company.name}}</td>
            <td>{{company.address}}</td>
            <td>{{company.ulPrefix}}</td>
            <td>
              <router-link class="link" :to="{ name: 'superadmin-company-edit', params: { id: company.id } }">
                Editer
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Error from '@/components/Error'
import * as companyService from '@/services/companyService'

export default {
  name: 'ListCompanies',
  components: {Error},
  data: () => ({
    error: undefined,
    companies: [],
  }),
  async mounted() {
    try {
      this.companies = await companyService.adminList()
    }  catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.list-companies {
  margin-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 16px;

  .header-title {
    font-weight: 600;
    font-size: 24px;
  }

  .header-new-company {
    background: $color-green;
    border-radius: 4px;
    padding: 8px 12px;
    color: #fff;

    &:hover {
      text-decoration: none;
      background: $color-green-dark;
    }
  }
}

.companies-table {
  width: 100%;

  th {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-transform: uppercase;
    padding: 21px 0;
  }

  td {
    padding: 16px 0;
    font-weight: 500;
    color: #999999;
    font-size: 16px;
  }

  tr {
    border-bottom: 1px solid $color-grey;
  }

  .link {
    font-weight: 400;
  }
}
</style>
