import React, {useMemo, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Button, Checkbox, Form, Input, Select} from 'antd'
import units from '@/utils/locationUnitList'

function LocationTypeForm({ locationType, onSubmit }) {
  const [form] = Form.useForm()
  const initialValues = useMemo(() => locationType, [locationType])

  useEffect(() => {
    form.resetFields()
  }, [form, locationType])

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Requis.' }]}>
        <Input />
      </Form.Item>

      <Form.Item name="unit" label="Unité" rules={[{ required: true, message: 'Requis.' }]}>
        <Select>
          {units.map(unit => (
            <Select.Option key={unit.value} value={unit.value}>
              {unit.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="limitToLessor" label="Cacher aux locataires" valuePropName="checked">
        <Checkbox />
      </Form.Item>

      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          Valider
        </Button>
      </Form.Item>
    </Form>
  )
}

LocationTypeForm.propTypes = {
  locationType: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default LocationTypeForm
