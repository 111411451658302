import { PushNotifications } from '@capacitor/push-notifications'
import { client } from '@/utils/api'
import {userService,localisationTypeService,signalementTitleService} from '@/services'

const authToken = new URLSearchParams(window.location.search).get('auth_token')
if (authToken) {
  localStorage.setItem('token', authToken)
}

const initialState = {
  token: localStorage.getItem('token'),
  userType: localStorage.getItem('user-type'),
  icons: undefined,
  intervenants: undefined,
  config: undefined,
  user: undefined,
  users: undefined,
  localisationTypes: undefined,
  signalementTitles: undefined,
  userView: false,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setToken(state, token) {
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
    },
    setUserType(state, userType) {
      state.userType = userType
      if (userType) {
        localStorage.setItem('user-type', userType)
      } else {
        localStorage.removeItem('user-type')
      }
    },
    setIntervenants(state, intervenants) {
      state.intervenants = intervenants
    },
    setUser(state, user) {
      state.user = user
    },
    setConfig(state, config) {
      state.config = config
    },
    setIcons(state, icons) {
      state.icons = icons
    },
    setUsers(state, users) {
      state.users = users
    },
    setLocalisationTypes(state, localisationTypes) {
      state.localisationTypes = localisationTypes
    },
    addLocationType(state, { payload }) {
      state.localisationTypes = [
        ...(state.localisationTypes || []),
        payload,
      ]
    },
    deleteLocationType(state, { id }) {
      state.localisationTypes = state.localisationTypes?.filter(v => v._id !== id)
    },
    setSignalementTitles(state, signalementTitles) {
      state.signalementTitles = signalementTitles
    },
    addSignalementTitle(state, { payload }) {
      state.signalementTitles = [
        ...(state.signalementTitles || []),
        payload,
      ]
    },
    deleteSignalementTitle(state, { id }) {
      state.signalementTitles = state.signalementTitles?.filter(v => v._id !== id)
    },
    setUserView(state, userView) {
      state.userView = userView
    },
  },
  actions: {
    async login({ dispatch, commit }, { username, password }) {
      const res = await client.get('token', {
        auth: {
          username,
          password,
        }
      })
      commit('setToken', res.data.token)
      PushNotifications.requestPermissions()
        .then(({ receive }) => {
          if (receive === 'granted') {
            return PushNotifications.register()
          }
        })
      return dispatch('fetchConfig')
    },
    async resetPassword(_, { id }) {
      const res = await client.post(`users/${id}/reset_password`)
      return res.data.generatedPassword
    },
    async fetchUsers({ commit }, { query, limit, skip }) {
      const res = await client.get('users', {
        params: {
          query,
          limit,
          skip,
        }
      })
      commit('setUsers', res.data.users)
      return res.data.users
    },
    async loginAs({ commit, dispatch }, { id }) {
      const res = await client.post('users/login_as', { id })
      commit('setToken', res.data.token)
      return dispatch('fetchConfig')
    },
    async fetchIcons({ commit }) {
      const res = await client.get('icons')
      commit('setIcons', res.data.data)
      return res.data.data
    },
    async fetchIntervenants({ commit }) {
      const res = await client.get('intervenant')
      commit('setIntervenants', res.data.data)
      return res.data.data
    },
    async fetchUser({ commit }) {
      const res = await client.get('me')
      commit('setUser', res.data.data)
      return res.data.data
    },
    async fetchConfig({ commit }) {
      const res = await client.get('config')
      commit('setConfig', res.data.data)
      if (res.data.data && res.data.data.type) {
        commit('setUserType', res.data.data.type)
      }
      return res.data.data
    },
    async changePassword(_, payload) {
      const res = await client.post('me/change_password', payload)
      return res.data
    },
    async createIcon(_, payload) {
      const res = await client.post('icons', payload)
      return res.data
    },
    async fetchStatistics(_, { startAt, endAt, localisations }) {
      const res = await client.get('superowner/statistics', {
        params: {
          startAt,
          endAt,
          localisations: JSON.stringify(localisations),
        },
      })
      return res.data
    },
    async fetchLocalisationTypes({ commit }) {
      const localisationTypes = await localisationTypeService.list()
      commit('setLocalisationTypes', localisationTypes)
      return localisationTypes
    },
    async fetchSignalementTitles({ commit }) {
      const signalementTitles = await signalementTitleService.list()
      commit('setSignalementTitles', signalementTitles)
      return signalementTitles
    },
    async updateDevice(_, { token }) {
      await userService.updateMe({ deviceId: token })
    },
    async updateFirstAuth(_, id) {
      await userService.updateUser({ id ,firstAuth: false })
    },
    async createUser({ dispatch }, { token, email, password }) {
      const res = await client.post('user', { email, password }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.status !== 201) {
        throw new Error('Impossible de créer un compte.')
      }
      return dispatch('login', { username: email, password })
    },
    async firstLoginToken(_, { code }) {
      const res = await client.get('user/first/token', {
        headers: {
          Authorization: `Bearer ${code}`,
        },
      })
      return res?.data?.token
    },
  },
}
