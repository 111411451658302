<template>
  <div class="tribune">
    <div class="container">
      <div class="go-back" @click="$router.back()">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="title">
            <TribuneIcon></TribuneIcon> Tribune
          </div>
          <Error :error="error"></Error>

          <template v-if="tribune">
            <table class="details">
              <tbody>
                <tr>
                  <th>Titre</th>
                  <td>{{tribune.name}}</td>
                </tr>
<!--                <tr>-->
<!--                  <th>De</th>-->
<!--                  <td>Hafida G</td>-->
<!--                </tr>-->
                <tr>
                  <th>Date</th>
                  <td>
                    Du {{tribune.startAt | moment("DD/MM/YYYY")}}
                  </td>
                </tr>
                <tr>
                  <th>Lieu</th>
                  <td>
                    <LogementFormatter :locations="tribune.localisations"></LogementFormatter>
                  </td>
                </tr>
                  <tr v-if="tribune.availableAt">
                  <th>Programmé le</th>
                  <td>
                    {{ tribune.availableAt | moment("DD/MM/YYYY") }}
                  </td>
                </tr>
                <tr>
                  <th>Statistiques</th>
                  <td>
                    <div class="metric-line">
                      <div class="metric">UL concernés : {{tribune.ulTargeted}}</div>
                      <div class="metric">UL actifs : {{tribune.ulActive}}</div>
                    </div>
                    <!-- <div class="metric-line">
                      <div class="metric">UL atteints : {{tribune.views}}</div>
                      <div class="metric">Interactions : {{tribune.interactions}}</div>
                    </div> -->
                    <div class="btn default" style="padding: 0" @click="downloadStats">
                      Télécharger les statistiques
                    </div>
                  </td>
                </tr>
                <tr v-if="tribune.description">
                  <th>Description</th>
                  <td class="description">
                    <RenderReact
                      :component="TextEditorRenderer"
                      :children="tribune.description"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Pictogramme associé</th>
                  <td v-if="findIcon(tribune.pictogram)">
                    <component v-bind:is="findIcon(tribune.pictogram).image"></component>
                  </td>
                </tr>
                <tr v-if="tribune.file">
                  <th>Photo</th>
                  <td>
                    <img :src="tribune.file">
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="delete-report">
              <div class="btn default" @click="$router.push({ name: 'edit-tribune', params: { id: tribune.id } })"
               :class="{ disabled: !canUpdate }">
                <div>Modifier la tribune</div>
              </div>
            </div>
            <div class="delete-report">
              <div class="btn default" @click="askDelete" :class="{ disabled: !canDelete }">
                <DeleteIcon></DeleteIcon>
                <div>Supprimer la tribune</div>
              </div>
            </div>
          </template>
          <div v-else-if="!error">
            Chargement...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import icons from '@/utils/icons'
import TribuneIcon from '@/assets/picto/tribune.svg'
import DeleteIcon from '@/assets/picto/supprimer.svg'
import ErrorComponent from '@/components/Error'
import Modal from '@/components/Modal'
import LogementFormatter from '@/components/LogementFormatter'
import {API_ENDPOINT} from '@/utils/api'
import {tribuneService} from '@/services'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'ViewTribune',
  data: () => ({
    TextEditorRenderer,
    error: undefined,
    tribune: undefined,
  }),
  components: {
    RenderReact,
    LogementFormatter,
    Modal,
    Error: ErrorComponent,
    TribuneIcon,
    DeleteIcon,
  },
  computed: {
    ...mapState('user', ['config']),
    canUpdate() {
      return this.config?.metadata?.permissions?.tribune?.update !== false
    },
    canDelete() {
      return this.config?.metadata?.permissions?.tribune?.delete !== false
    },
  },
  methods: {
    findIcon(name) {
      return icons.find(({ value }) => value === name)
    },
    async askDelete() {
      if (confirm('Voulez-vous vraiment supprimer la tribune ?')) {
        try {
          await tribuneService.destroy({
            id: this.tribune.id,
          })
          this.$router.back()
        } catch (error) {
          this.error = error
        }
      }
    },
    async downloadStats() {
      window.open(`${API_ENDPOINT}tribune/${this.tribune.id}/stats.csv`, '_blank')
    },
  },
  async mounted() {
    try {
      this.tribune = await tribuneService.get({
        id: this.$route.params.id,
      })
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.tribune {
  margin-bottom: 48px;
}

.title {
  font-size: 24px;
  color: $color-tribune;
  font-weight: 600;
  margin: 32px 0;

  svg {
    height: 42px;
    margin-right: 12px;
    fill: $color-tribune;
  }
}

.metric-line {
  display: flex;
  flex-direction: row;
  align-items: center;

  .metric {
    margin-right: 16px;
  }
}

.details th,
.track-status {
  color: #a2a2a2;
  font-weight: 500;
  font-size: 16px;
}

.track-status {
  margin: 16px 0;
}

.details {
  th {
    padding: 16px 16px 16px 0;
  }

  td {
    font-size: 16px;
    font-weight: 600;

    svg {
      height: 64px;
    }

    img {
      height: 128px;
    }

    &.description {
      font-weight: 400;
    }
  }
}

.track-status-details {
  width: 100%;

  th {
    background: #4c4c4c;
    color: #a2a2a2;
    font-weight: 500;
    padding: 8px;
  }

  td {
    padding: 8px;
  }
}

.delete-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 16px 0;

  .btn {
    &,
    div {
      display: flex;
      flex-direction: row;
      align-self: center;
    }

    svg {
      height: 32px;
      margin-right: 8px;
      fill: $color-blue-light;
    }
  }
}
</style>
