import { concertationService } from '@/services'

const initialState = {
  concertations: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setConcertations(state, concertations) {
      state.concertations = concertations
    },
    markAsAnswered(state, { id }) {
      state.concertations = state.concertations?.map(value => id ? ({
        ...value,
        hasAnswered: true,
      }) : value)
    },
  },
  actions: {
    async fetchConcertations({commit}) {
      const concertations = await concertationService.list()
      const sortedConcertations = concertations
        .sort(({createdAt: a}, {createdAt: b}) => new Date(b).getTime() - new Date(a).getTime())
      commit('setConcertations', sortedConcertations)
      return sortedConcertations
    },
  },
}
