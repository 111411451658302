<template>
  <div @click="onClick">
    <div v-if="loading" class="loader"></div>
    <slot v-else :class="{ 'loader-wrapper': loading }"></slot>
  </div>
</template>

<script>
import { Dialog } from '@capacitor/dialog'

export default {
  name: 'ClickLoaderWrapper',
  data: () => ({ loading: false }),
  methods: {
    async onClick() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        await new Promise((resolve, reject) => {
          this.$emit(
            'click',
            res => res.then(resolve).catch(reject)
          )
        })
      } catch (error) {
        await Dialog.alert({
          title: 'Erreur',
          message: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  opacity: 0;
}

.loader {
  opacity: 1;
  margin: 8px 0;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #fff;
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;

  &,
  ::after {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
