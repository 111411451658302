<template>
  <div
    class="user-layout"
    :class="{
      'user-admin':
        $store.state.user.user && $store.state.user.user.role !== 'user',
    }"
  >
    <div class="switch-user" @click="$router.push('/admin/home')">
      Toucher pour passer en vue administrateur
    </div>
    <div v-if="toggle" class="sidebar-overlay" @click="dismissSidebar">
      <div class="sidebar-container">
        <div class="sidebar-header">
          <img src="../../assets/images/logo.png" />
        </div>
        <div class="sidebar-content">
          <div class="sidebar-link" @click="goToDashboard">
            <i class="ion-md-home"></i>
            Tableau de bord
          </div>
          <div class="sidebar-link" @click="goToProfile">
            <i class="ion-md-person"></i>
            Profil
          </div>
          <div class="sidebar-link" @click="logout">
            <i class="ion-md-log-out"></i>
            Déconnexion
          </div>
        </div>
      </div>
    </div>
    <div class="full-header">
      <div class="header" :class="{ 'header-center-alone': homeScreenToggled }">
        <div
          v-if="!homeScreenToggled"
          class="header-left"
          @click="toggle = !toggle"
        >
          <i class="ion-ios-menu"></i>
        </div>
        <div v-if="config && config.logo" class="header-center">
          <img :src="config.logo" alt="Logo" />
        </div>
        <div
          v-if="!homeScreenToggled"
          class="header-right"
          @click="$router.push({ name: 'user-tutorial' })"
          :class="{ disabled: config && !config.features.includes('video') }"
        >
          <i class="ion-ios-film"></i>
        </div>
      </div>
      <div class="navigation" v-if="!homeScreenToggled">
        <div
          class="navigation-link"
          @click="$router.push({ name: 'user-home' })"
          :class="{ disabled: config && !config.features.includes('tribune') }"
        >
          <div class="navigation-link-icon">
            <span v-if="tribunesToRead > 0" class="navigation-link-badge">
              {{ tribunesToRead }}
            </span>
            <TribuneIcon></TribuneIcon>
          </div>
        </div>
        <div
          class="navigation-link"
          @click="$router.push({ name: 'user-signalements-list' })"
          :class="{
            disabled: config && !config.features.includes('signalement'),
          }"
        >
          <div class="navigation-link-icon">
            <span v-if="signalementsToRead > 0" class="navigation-link-badge">
              {{ signalementsToRead }}
            </span>
            <ReportIcon></ReportIcon>
          </div>
        </div>
        <div
          class="navigation-link"
          @click="$router.push({ name: 'user-votes-list' })"
          :class="{
            disabled: config && !config.features.includes('concertation'),
          }"
        >
          <div class="navigation-link-icon">
            <span v-if="votesToRead > 0" class="navigation-link-badge">
              {{ votesToRead }}
            </span>
            <VoteIcon></VoteIcon>
          </div>
        </div>
        <div
          class="navigation-link"
          @click="$router.push({ name: 'user-travaux-list' })"
          :class="{ disabled: config && !config.features.includes('travaux') }"
        >
          <div class="navigation-link-icon">
            <span v-if="travauxToRead > 0" class="navigation-link-badge">
              {{ travauxToRead }}
            </span>
            <WorkIcon></WorkIcon>
          </div>
        </div>
        <div
          class="navigation-link"
          @click="$router.push({ name: 'user-interventions-list' })"
          :class="{
            disabled: config && !config.features.includes('intervention'),
          }"
        >
          <div class="navigation-link-icon">
            <span v-if="interventionsToRead > 0" class="navigation-link-badge">
              {{ interventionsToRead }}
            </span>
            <InterventionIcon></InterventionIcon>
          </div>
        </div>
        <div
          class="navigation-link"
          @click="$router.push({ name: 'user-contacts' })"
          :class="{ disabled: config && !config.features.includes('contact') }"
        >
          <ContactIcon></ContactIcon>
        </div>
      </div>
    </div>
    <div
      v-if="homeScreenToggled"
      :class="{
        'home-screen':
          config.homeScreenButtonMessage || config.homeScreenMessage,
      }"
    >
      <RenderReact
        :component="TextEditorRenderer"
        :children="config.homeScreenMessage"
      />
      <img
        v-if="config.homeScreenPicture"
        class="home-screen-image"
        :src="config.homeScreenPicture"
        :class="{
          'home-screen-image-fullscreen':
            !config.homeScreenButtonMessage && !config.homeScreenMessage,
        }"
        @click="onClickImage"
      />
      <!-- <div
        v-if="config.homeScreenButtonMessage || config.homeScreenMessage"
        class="home-screen-button"
        @click="setFirstAuth()"
      >
        {{config.homeScreenButtonMessage || 'Continuer'}}
      </div> -->
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { mapActions, mapMutations, mapState } from "vuex";
import ReportIcon from "@/assets/picto/signalement.svg";
import VoteIcon from "@/assets/picto/voter.svg";
import WorkIcon from "@/assets/picto/travaux.svg";
import InterventionIcon from "@/assets/picto/interventions.svg";
import TribuneIcon from "@/assets/picto/tribune.svg";
import ContactIcon from "@/assets/picto/contacts.svg";
import TextEditorRenderer from "@/components/TextEditor/TextEditorRenderer";
import RenderReact from "@/components/RenderReact";

export default {
  name: "UserLayout",
  data: () => ({
    TextEditorRenderer,
    toggle: false,
    homeScreenToggled: false,
    userId: null,
  }),
  components: {
    RenderReact,
    ReportIcon,
    VoteIcon,
    WorkIcon,
    InterventionIcon,
    TribuneIcon,
    ContactIcon,
  },
  watch: {
    toggle: {
      immediate: true,
      handler(toggle) {
        document.body.style.overflow = toggle ? "hidden" : "auto";
      },
    },
  },
  computed: {
    ...mapState("reporting", ["reports"]),
    ...mapState("concertation", ["concertations"]),
    ...mapState("satisfaction", ["satisfactions"]),
    ...mapState("work", ["works"]),
    ...mapState("intervention", ["interventions"]),
    ...mapState("tribune", ["tribunes"]),
    ...mapState("user", ["config"]),
    tribunesToRead() {
      return this.tribunes?.filter(({ hasRead }) => !hasRead).length ?? 0;
    },
    signalementsToRead() {
      return this.reports?.filter(({ hasRead }) => !hasRead).length ?? 0;
    },
    votesToRead() {
      return (
        (this.concertations?.filter(({ hasVoted }) => !hasVoted).length ?? 0) +
        (this.satisfactions?.filter(({ hasVote }) => !hasVote).length ?? 0)
      );
    },
    travauxToRead() {
      return this.works?.filter(({ hasRead }) => !hasRead).length ?? 0;
    },
    interventionsToRead() {
      return this.interventions?.filter(({ hasRead }) => !hasRead).length ?? 0;
    },
  },
  methods: {
    ...mapMutations("user", ["setToken"]),
    ...mapActions("user", [
      "fetchConfig",
      "fetchLocalisationTypes",
      "fetchSignalementTitles",
      "updateFirstAuth",
    ]),
    ...mapActions("reporting", ["fetchReports"]),
    ...mapActions("concertation", ["fetchConcertations"]),
    ...mapActions("satisfaction", ["fetchSatisfactions"]),
    ...mapActions("work", ["fetchWorks"]),
    ...mapActions("intervention", ["fetchInterventions"]),
    ...mapActions("tribune", ["fetchTribunes"]),
    ...mapActions("localisation", ["fetchLocalisations"]),
    goToDashboard() {
      this.$router.push({ name: "user-home" });
      this.toggle = false;
    },
    onClickImage() {
      // this.homeScreenToggled = !(!config.homeScreenButtonMessage && !config.homeScreenMessage)
      this.setFirstAuth();
    },
    goToProfile() {
      this.$router.push({ name: "user-profile" });
      this.toggle = false;
    },
    setFirstAuth() {
      this.homeScreenToggled = false;
      this.updateFirstAuth(this.userId);
    },
    logout() {
      this.setToken(undefined);
      this.$router.push({ name: "login" });
    },
    dismissSidebar(event) {
      if (event.srcElement.className === "sidebar-overlay") {
        this.toggle = false;
      }
    },
  },
  async mounted() {
    const config = await this.fetchConfig();

    if (Capacitor.getPlatform() !== "web") {
      Promise.all([
        FirebaseAnalytics.setUserId({ userId: config.id }),
        FirebaseAnalytics.setUserProperty(config.analytics),
      ]).catch(console.error);
    }
    if (
      !this.$store.state.user.user ||
      this.$store.state.user.user.role === "user"
    ) {
      this.$router.push({ name: config.home || "home" });
    }

    if (config.firstAuth === false) {
      this.homeScreenToggled = false;
    } else {
      this.homeScreenToggled = config.homeScreenEnabled;
    }
    this.userId = config.id;

    Promise.all([
      this.fetchReports(),
      this.fetchConcertations(),
      this.fetchSatisfactions(),
      this.fetchWorks(),
      this.fetchInterventions(),
      this.fetchTribunes(),
      this.fetchLocalisations(),
      this.fetchSignalementTitles(),
      this.fetchLocalisationTypes(),
    ]).catch(console.error);

    setTimeout(() => {
      this.setFirstAuth();
    }, 5000);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.user-layout {
  padding-top: 122px;
}

.full-header {
  top: env(safe-area-inset-top);
  position: fixed;
  width: 100%;
  background: $color-white;
  z-index: 9000;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $color-blue;
  padding: 8px 16px;

  .header-left,
  .header-right {
    color: $color-white;
    font-size: 32px;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .header-center img {
    height: 48px;
  }
}

.sidebar-overlay {
  top: env(safe-area-inset-top);
  position: fixed;
  height: calc(100vh - env(safe-area-inset-top));
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000;
}

.sidebar-container {
  display: block;
  position: absolute;
  height: 100vh;
  width: 70vw;
  background: $color-white;
  box-shadow: rgba(0, 0, 0, 0.09) 0 2px 22px 0, rgba(0, 0, 0, 0.18) 4px 0 16px 0;

  .sidebar-header {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    background: $color-blue;
    border-bottom: 1px solid $color-grey;

    img {
      width: 96px;
      height: 96px;
    }
  }

  .sidebar-content {
    padding: 24px 0;
  }

  .sidebar-link {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    padding: 12px 16px;
    align-items: center;

    i {
      color: $color-blue;
      font-size: 24px;
      margin-right: 16px;
    }
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  .navigation-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: calc(100% / 6);
    padding: 12px;
    border: 1px solid #dcdcdc;

    svg {
      height: 32px;
      width: 32px;
    }

    &:nth-child(1) {
      fill: $color-tribune;
    }

    &:nth-child(2) {
      fill: $color-reporting;
    }

    &:nth-child(3) {
      fill: $color-concertation;
    }

    &:nth-child(4) {
      fill: $color-work;
    }

    &:nth-child(5) {
      fill: $color-intervention;
    }

    &:nth-child(6) {
      fill: $color-contact;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .navigation-link-icon {
    position: relative;
  }

  .navigation-link-badge {
    background: $color-blue;
    color: $color-white;
    position: absolute;
    top: -4px;
    right: -4px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
  }
}

.switch-user {
  display: none;
}

.user-admin {
  &.user-layout {
    padding-top: 170px;
  }

  .full-header {
    top: calc(48px + env(safe-area-inset-top));
  }

  .switch-user {
    display: block;
    position: fixed;
    z-index: 10000;
    top: env(safe-area-inset-top);
    left: 0;
    width: 100%;
    padding: 12px 24px;
    color: #fff;
    background: $color-green-light;
  }
}

.header-center-alone {
  justify-content: center;
}

.home-screen {
  padding: 16px;
}

.home-screen-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 16px;
  font-weight: 600;
  background: $color-blue-light;
  border-radius: 8px;
  color: #fff;
  box-shadow: 2px 4px 17px 0 rgba(0, 0, 0, 0.12);
}

.home-screen-image {
  display: block;
  margin: 8px auto 16px;
  width: auto;
  max-width: 100%;
  height: 55vh;
}

.home-screen-image-fullscreen {
  margin: 0;
  width: 100vw;
  height: calc(100vh - 64px);
  top: 64px;
  left: 0;
  position: fixed;
}
</style>
