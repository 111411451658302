import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {Button, Checkbox, Form, Input, notification} from 'antd'
import {configService} from '@/services'

function GeneralFeaturesForm({ config, updateConfig }) {
  const [form] = Form.useForm()
  const initialValues = useMemo(() => config, [config])

  useEffect(() => {
    form.resetFields()
  }, [initialValues])

  function onSubmit(values) {
    return configService.update({
      ...config,
      ...values,
    })
      .then((values) => {
        updateConfig({
          ...config,
          ...values,
        })
        notification.success({
          message: 'Mis à jour !',
          description: 'Configuration mise à jour.'
        })
      })
      .catch(error => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
  }

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name="disableUserReport" label="Désactiver le signalement locataire" valuePropName="checked">
        <Checkbox />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Valider
        </Button>
      </Form.Item>
    </Form>
  )
}

GeneralFeaturesForm.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
}

export default GeneralFeaturesForm
