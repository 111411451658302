import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import user from './user'
import work from './work'
import intervention from './intervention'
import reporting from './reporting'
import tribune from './tribune'
import satisfaction from './satisfaction'
import concertation from './concertation'
import owner from './owner'
import superowner from './superowner'
import admin from './admin'
import localisation from './localisation'
import appInfo from './appInfo'

Vue.use(Vuex)

const store = new Store({
  modules: {
    user,
    work,
    intervention,
    reporting,
    tribune,
    satisfaction,
    concertation,
    owner,
    superowner,
    admin,
    localisation,
    appInfo
  },
})

export default store
