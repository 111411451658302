import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {Row, Col} from 'antd'
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons'
import styles from './ConcertationAnswer.module.scss'
import {API_ENDPOINT} from '@/utils/api'

function ConcertationAnswer({ concertation, answer }) {
  const [toggle, setToggle] = useState(true)
  const answers = useMemo(() => {
    return Object.entries(answer.answers)
      .map(([questionId, value]) => {
        const question = concertation.questions.find(v => v.id === questionId)
        if (!question) {
          return null
        }
        return {
          question,
          value,
        }
      })
      .filter(v => !!v)
  })

  function formatAnswer(answer) {
    switch (answer.question.type) {
    case 'TEXT':
    case 'PHONE_NUMBER':
      return answer.value
    case 'SIMPLE_CHOICE':
      return answer.question.options.find(v => v.id === answer.value)?.name ?? 'Réponse introuvable'
    case 'YES_NO':
      return answer.value ? 'Oui' : 'Non'
    case 'MULTIPLE_CHOICE':
      return answer.value.map(value => {
        return answer.question.options.find(v => v.id === value)?.name ?? 'Réponse introuvable'
      }).join(', ')
    case 'FILE':
      return (
        <ul style={{ marginBottom: 0 }}>
          {answer.value.map((url, index) => {
            const filename = url.split('/').pop()
            const visualisable = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'pdf'].some(v => filename.endsWith(`.${v}`))

            return (
              <li key={index}>
                <a
                  href={`${API_ENDPOINT}files/download-proxy?url=${encodeURIComponent(url)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Télécharger la pièce jointe
                </a>
                {visualisable && (
                  <>
                    &nbsp;(<a href={url} target="_blank" rel="noreferrer">Visualiser</a>)
                  </>
                )}
              </li>
            )
          })}
        </ul>
      )
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.header} onClick={() => setToggle(!toggle)}>
        <div>
          {answer.ul ? `UL ${answer.ul}` : 'UL inconnu'}&nbsp;
          ({new Intl.DateTimeFormat('fr-FR').format(new Date(answer.createdAt))})
        </div>
        {toggle ? <CaretDownOutlined /> : <CaretUpOutlined />}
      </div>
      {!toggle && (
        <div className={styles.body}>
          <Row gutter={[16, 24]}>
            {answers.map(answer => (
              <Col key={answer.question.id} className="gutter-row" span={6}>
                <div className={styles.questionHeader}>
                  {answer.question.name}
                </div>
                <div className={styles.questionAnswer}>
                  {formatAnswer(answer)}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  )
}

ConcertationAnswer.propTypes = {
  concertation: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
}

export default ConcertationAnswer
