import "intl";
import "intl/locale-data/jsonp/fr-FR";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import moment from "moment";
import "antd/dist/antd.css";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { VuePlugin } from "vuera";
import "moment/locale/fr";
import Vue from "vue";
import VueMoment from "vue-moment";
import store from "./store";
import router from "./router";
import App from "./App.vue";

const sentryEnv = process.env.VUE_APP_SENTRY_ENV;
Sentry.init({
  dsn: "https://8e86453ae2374748ba8628940c239c1e@o434838.ingest.sentry.io/5392379",
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
    }),
  ],
  environment: sentryEnv || "staging",
  enabled: sentryEnv && sentryEnv !== "dev",
});

defineCustomElements(window);

Vue.config.productionTip = false;
Vue.use(VueMoment, { moment });
Vue.use(VuePlugin);

Vue.filter("status_label", (value) => {
  switch (value) {
    case 0:
      return "En attente de traitement";
    case 1:
      return "Ajourné";
    case 2:
      return "En cours";
    case 3:
      return "Prévu";
    case 4:
      return "Clôturé";
    case 5:
      return "Clôturé";
    // case 6:
    //   return "En attente d'approbation";
    // case 7:
    //   return "Traité en interne";
  }
});

Vue.filter("capitalize", (value) => {
  if (value && value.length) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value;
});

const app = new Vue({
  store,
  router,
  render: (h) => h(App),
});

app.$mount("#app");
