<template>
  <div class="tribune">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center" v-if="tribune">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="TribuneForm"
            :tribune="tribune"
            title="Modifier une tribune"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TribuneForm from '@/components/TribuneForm'
import {tribuneService} from '@/services'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'EditTribune',
  components: {
    RenderReact,
  },
  data: () => ({
    tribune: null,
    TribuneForm,
  }),
  methods: {
    async onSubmit(values) {
      const id = this.$route.params.id
      await tribuneService.update({
        ...values,
        id,
      })
      this.$router.push({ name: 'view-tribune', params: { id } })
    },
  },
  async mounted() {
    this.tribune = await tribuneService.get({ id: this.$route.params.id })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.tribune {
  margin-bottom: 48px;
}
</style>
