<template>
  <div class="root" ref="root">
    <div class="side-nav" :class="{ 'side-nav-collapsed': toggleNav }">
      <div class="side-nav-header">
        <img class="side-nav-logo" src="../../assets/images/logo.png" title="Neybs" alt="Neybs"
             @click="sidebarLink({ name: 'superadmin-home' })"/>
        <div class="button" @click="toggleNav = !toggleNav">
          <i class="ion-ios-menu"></i>
        </div>
      </div>
      <div class="side-nav-content">
        <SideNavSection title="Gestion">
          <SideNavLink :to="{ name: 'superadmin-company-list' }" v-model="toggleNav">
            <CompaniesIcon></CompaniesIcon>
            Clients
          </SideNavLink>
          <SideNavLink :to="{ name: 'superadmin-user-list' }" v-model="toggleNav">
            <UsersIcon></UsersIcon>
            Utilisateurs
          </SideNavLink>
          <SideNavLink :to="{ name: 'superadmin-notification-list' }" v-model="toggleNav">
            <SignalementIcon></SignalementIcon>
            Notifications
          </SideNavLink>
        </SideNavSection>
        <SideNavSection title="Mon compte">
          <SideNavLink :to="{ name: 'profile' }" v-model="toggleNav">
            <UtilisateurIcon></UtilisateurIcon>
            Mon profil
          </SideNavLink>
          <SideNavLink :to="{ name: 'profile' }" :disabled="true" v-model="toggleNav">
            <MessagingIcon></MessagingIcon>
            Messagerie
          </SideNavLink>
          <SideNavLink @click="logoutWrapper" v-model="toggleNav">
            <DeconnexionIcon></DeconnexionIcon>
            Déconnexion
          </SideNavLink>
        </SideNavSection>
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CompaniesIcon from '@/assets/picto/business.svg'
import UsersIcon from '@/assets/picto/people.svg'
import MessagingIcon from '@/assets/picto/messagerie.svg'
import UtilisateurIcon from '@/assets/picto/person.svg'
import DeconnexionIcon from '@/assets/picto/deconnexion.svg'
import SignalementIcon from '@/assets/picto/signalement.svg'
import {mapActions, mapMutations} from 'vuex'
import SideNavSection from '../../components/SideNavSection'
import SideNavLink from '../../components/SideNavLink'

export default {
  name: 'SuperAdminHome',
  components: {
    SideNavLink,
    SideNavSection,
    CompaniesIcon,
    UsersIcon,
    UtilisateurIcon,
    MessagingIcon,
    DeconnexionIcon,
    SignalementIcon,
  },
  data: () => ({
    toggleNav: false,
    hammer: undefined,
  }),
  methods: {
    ...mapMutations('user', ['setToken']),
    ...mapActions('user', ['fetchUser', 'fetchConfig']),
    logoutWrapper() {
      this.setToken(undefined)
      this.$router.push({ name: 'login' })
    },
    sidebarLink(data) {
      this.$router.push(data)
      this.toggleNav = false
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.fetchUser(),
        this.fetchConfig(),
      ])
    } catch (e) {
      console.error(e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.root {
  display: flex;
  flex-direction: row;
}

.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100vh;
  max-height: 100vh;
  background: $color-blue;
  overflow-y: auto;
  z-index: 9000;
}

.side-nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 12px;

  & > img {
    height: 64px;
  }

  .avatar {
    cursor: pointer;
    font-weight: 500;
    color: $color-white;

    img {
      height: 48px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  .button {
    display: none;
    color: $color-white;
    font-size: 36px;
  }
}

.side-nav-content {
  .side-nav-section:last-child {
    border-bottom: none;
  }
}

.side-nav-logo {
  display: flex;
  align-self: center;
}

.side-nav-link-list {
  list-style: none;
  margin-bottom: 24px;
  padding: 0;

  &.bottom {
    border-top: 1px solid $color-grey-dark;
    padding-top: 16px;
  }

  a,
  & > div {
    display: flex;
    flex-direction: row;
    align-self: center;
    text-decoration: none;
    margin-bottom: 8px;
    padding: 8px 0 8px 28px;
    font-size: 18px;
    line-height: 24px;
    color: $color-white;
    cursor: pointer;

    svg {
      height: 32px;
      padding-right: 8px;
      fill: #fff;
    }

    div {
      display: flex;
      align-items: center;
    }

    &:hover {
      background: $color-blue-light;
    }

    &.disabled {
      opacity: 0.4;
      user-select: none;
      pointer-events: none;
      cursor: default;

      &:hover {
        background: none;
      }
    }
  }
}

.badge {
  background: $color-red;
  padding: 0 7px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;
  margin-left: 8px;
}

.content {
  display: block;
  width: 100%;
  margin-left: 300px;
}


@media screen and (max-width: $breakpoint-mobile) {
  .root {
    flex-direction: column;
    padding-top: 70px;
  }

  .content {
    margin-left: 0;
  }

  .side-nav {
    width: 100%;
    height: 70px;
    top: env(safe-area-inset-top);

    &.side-nav-collapsed {
      height: calc(100vh - env(safe-area-inset-top));
      max-height: calc(100vh - env(safe-area-inset-top));
    }
  }

  .side-nav-header {
    padding: 8px 24px;

    img {
      height: 48px;
    }

    & > .avatar {
      display: none;
    }

    & > .button {
      display: block;
    }
  }

  .side-nav-content {
    display: none;
    background: $color-blue;
    top: calc(70px + env(safe-area-inset-top));
    width: 100%;
    z-index: 9999;
    overflow-y: auto;
  }

  .side-nav-collapsed .side-nav-content {
    display: block;
  }
}
</style>
