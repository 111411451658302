// eslint-disable-next-line no-sparse-arrays
import React from 'react'
import { VueInReact } from 'vuera'

const icons = [
  {name: 'Agenda', value: 'agenda', image: () => import('@/assets/picto/agenda.svg')},
  // notif express
  {name: 'Statistiques', value: 'statistiques', image: () => import('@/assets/picto/statistiques.svg')},
  {name: 'Concertation', value: 'concertation', image: () => import('@/assets/picto/concertation.svg')},
  {name: 'Terrain de basket', value: 'basket', image: () => import('@/assets/picto/basket.svg')},
  {name: 'Vitre cassée', value: 'vitrecassee', image: () => import('@/assets/picto/vitrecassee.svg')},
  {name: 'Immeuble', value: 'immeuble', image: () => import('@/assets/picto/immeuble.svg')},
  {name: 'Valider', value: 'valider', image: () => import('@/assets/picto/valider.svg')},
  {name: 'Propreté', value: 'proprete', image: () => import('@/assets/picto/proprete.svg')},
  {name: "Coupure d'électricité", value: 'coupureelectricite', image: () => import('@/assets/picto/coupureelectricite.svg')},
  {name: 'Ascenseur en panne', value: 'ascenseur', image: () => import('@/assets/picto/asenseur.svg')},
  {name: 'Jardiner / Jardin', value: 'jardin', image: () => import('@/assets/picto/jardin.svg')},
  {name: 'Travaux', value: 'travaux', image: () => import('@/assets/picto/travaux.svg')},
  {name: 'Historique', value: 'historique', image: () => import('@/assets/picto/historique.svg')},
  {name: 'Insecte', value: 'insecte', image: () => import('@/assets/picto/insecte.svg')},
  {name: 'Messagerie', value: 'messagerie', image: () => import('@/assets/picto/messagerie.svg')},
  {name: 'Signalement', value: 'signalement', image: () => import('@/assets/picto/signalement.svg')},
  {name: 'Tribune', value: 'tribune', image: () => import('@/assets/picto/tribune.svg')},
  {name: 'Déconnexion', value: 'deconnexion', image: () => import('@/assets/picto/deconnexion.svg')},
  {name: 'Stationnement privé', value: 'stationnementprivé', image: () => import('@/assets/picto/stationnementprive.svg')},
  {name: 'Portail cassé', value: 'portail', image: () => import('@/assets/picto/portail.svg')},
  {name: 'Contacts', value: 'contacts', image: () => import('@/assets/picto/contacts.svg')},
  {name: 'Réunion', value: 'reunion', image: () => import('@/assets/picto/reunion.svg')},
  {name: 'Paramètres admin', value: 'parametres', image: () => import('@/assets/picto/parametres.svg')},
  {name: 'Relation entre locataires', value: 'relationlocataire', image: () => import('@/assets/picto/relationlocataire.svg')},
  {name: 'Interventions', value: 'interventions', image: () => import('@/assets/picto/interventions.svg')},
  {name: 'Supprimer', value: 'supprimer', image: () => import('@/assets/picto/supprimer.svg')},
  {name: 'Vidéo', value: 'video', image: () => import('@/assets/picto/tutosvideos.svg')},
  {name: 'Voter', value: 'voter', image: () => import('@/assets/picto/voter.svg')},
  {name: 'Attention', value: 'attention', image: () => import('@/assets/picto/attention.svg')},
  {name: 'Chaudière', value: 'chaudiere', image: () => import('@/assets/picto/chaudiere.svg')},
  {name: "Fuite d'eau", value: 'fuiteeau', image: () => import('@/assets/picto/fuiteeau.svg')},
  {name: 'Wifi', value: 'wifi', image: () => import('@/assets/picto/wifi.svg')},
  {name: 'Equipement urbain', value: 'équipement urbain musculation', image: () => import('@/assets/picto/musculation.svg')},
  {name: 'Sablier', value: 'sablier', image: () => import('@/assets/picto/sablier.svg')},
  {name: "Coupure d'eau", value: 'coupureeau', image: () => import('@/assets/picto/coupureeau.svg')},
  {name: 'Coupure eau froide', value: 'coupureeaufroide', image: () => import('@/assets/picto/eaufroide.svg')},
  {name: 'Coupure eau chaude', value: 'coupureeauchaude', image: () => import('@/assets/picto/eauchaude.svg')},
  {name: 'Chauffage collectif', value: 'chauffagecollectif', image: () => import('@/assets/picto/chauffagecollectif.svg')},
  {name: 'Aire de jeux', value: 'airedejeux', image: () => import('@/assets/picto/airedejeux.svg')},
  {name: 'Astreinte', value: 'astreinte', image: () => import('@/assets/picto/astreinte.svg')},
  {name: 'Mobiliers abandonnés', value: 'mobiliersabandonnes', image: () => import('@/assets/picto/mobiliersabandonnes.svg')},
  {name: 'Encombrants', value: 'encombrants', image: () => import('@/assets/picto/encombrants.svg')},
  {name: 'Satisfaction', value: 'satisfaction', image: () => import('@/assets/picto/satisfaction.svg')},
  {name: 'Coupure robinet', value: 'coupure_robinet', image: () => import('@/assets/picto/coupure_robinet.svg')},
  {name: 'Satisfait vert', value: 'satisfait_vert', image: () => import('@/assets/picto/satisfait_vert.svg')},
  {name: 'Insatisfait rouge', value: 'insatisfait_rouge', image: () => import('@/assets/picto/insatisfait_rouge.svg')},
  {name: 'Bouton', value: 'bouton', image: () => import('@/assets/picto/bouton.svg')},
  {name: 'Toilettes bouchées', value: 'toilettes_bouchees', image: () => import('@/assets/picto/toilettes_bouchees.svg')},
  {name: 'Masse tableau élec', value: 'masse_tableau_elec', image: () => import('@/assets/picto/masse_tableau_elec.svg')},
  {name: 'Accès hall cassé', value: 'acces_hall_casse', image: () => import('@/assets/picto/acces_hall_casse.svg')},
  {name: 'Odeur gaz', value: 'odeur_gaz', image: () => import('@/assets/picto/odeur_gaz.svg')},
  {name: 'Agression', value: 'agression', image: () => import('@/assets/picto/agression.svg')},
  {name: 'Echafaudage', value: 'echafaudage', image: () => import('@/assets/picto/echafaudage.svg')},
  {name: 'Melangeur chantier', value: 'melangeur_chantier', image: () => import('@/assets/picto/melangeur_chantier.svg')},
  {name: 'Nuisances', value: 'nuisances', image: () => import('@/assets/picto/nuisances.svg')},
  {name: 'Echafaudage2', value: 'echafaudage2', image: () => import('@/assets/picto/echafaudage2.svg')},
  {name: 'Porte palier', value: 'porte_palier', image: () => import('@/assets/picto/porte_palier.svg')},
  {name: 'Fermeture véhicule', value: 'fermeture_vehicule', image: () => import('@/assets/picto/fermeture_vehicule.svg')},
  {name: 'Vol', value: 'vol', image: () => import('@/assets/picto/vol.svg')},
  {name: 'Danger électrocution', value: 'danger_electrocution', image: () => import('@/assets/picto/danger_electrocution.svg')},
  {name: 'Collegial', value: 'collegial', image: () => import('@/assets/picto/collegial.svg')},
  {name: 'Espace vert', value: 'espaces_verts', image: () => import('@/assets/picto/espaces_verts.svg')},
  {name: 'Escaliers', value: 'escaliers', image: () => import('@/assets/picto/escaliers.svg')},
  {name: 'Poubelle', value: 'poubelle', image: () => import('@/assets/picto/poubelle.svg')},
  {name: 'Départ de feu', value: 'depart_de_feu', image: () => import('@/assets/picto/depart_de_feu.svg')},
  {name: 'Peintre', value: 'peintre', image: () => import('@/assets/picto/peintre.svg')},
  {name: 'Absence de lumière', value: 'absence_de_lumiere', image: () => import('@/assets/picto/absence_de_lumiere.svg')},
  {name: 'Effritement mur peint', value: 'effritement_mur_peint', image: () => import('@/assets/picto/effritement_mur_peint.svg')},
  {name: 'Carreau mur cassé', value: 'carreau_mur_casse', image: () => import('@/assets/picto/carreau_mur_casse.svg')},
  {name: 'Absence d’eau', value: 'absence_d_eau', image: () => import('@/assets/picto/absence_d_eau.svg')},
  {name: 'MàJ NEYBS', value: 'maj_neybs', image: () => import('@/assets/picto/maj_neybs.svg')},
  {name: 'Immeuble en fête', value: 'immeuble_en_fete', image: () => import('@/assets/picto/immeuble_en_fete.svg')},
  {name: 'Parking', value: 'parking', image: () => import('@/assets/picto/parking.svg')},
  // {name: 'Baignoire.', value: 'baignoire.', image: () => import('@/assets/picto/baignoire..svg')},
  {name: 'Inondation', value: 'inondation', image: () => import('@/assets/picto/inondation.svg')},
  {name: 'Drainage epuration 2', value: 'drainage_epuration2', image: () => import('@/assets/picto/drainage_epuration2.svg')},
  {name: 'Carreleur', value: 'carreleur', image: () => import('@/assets/picto/carreleur.svg')},
  {name: 'Electricien.', value: 'electricien.', image: () => import('@/assets/picto/electricien..svg')},
  {name: 'Agent plombier', value: 'agent_plombier', image: () => import('@/assets/picto/agent_plombier.svg')},
  {name: 'Collecte ordures', value: 'collecte_ordures', image: () => import('@/assets/picto/collecte_ordures.svg')},
  // {name: 'Perceuse', value: 'perceuse', image: () => import('@/assets/picto/perceuse.svg')},
  // {name: 'Camion Benne', value: 'camion_benne', image: () => import('@/assets/picto/camion_benne.svg')},
  {name: 'Evier', value: 'evier', image: () => import('@/assets/picto/evier.svg')},
  {name: 'Distribution BAL', value: 'distribution_bal', image: () => import('@/assets/picto/distribution_bal.svg')},
  {name: 'Decapage des sols', value: 'decapage_des_sols', image: () => import('@/assets/picto/decapage_des_sols.svg')},
  {name: 'Lavage vitre elevateur', value: 'lavage_vitre_elevateur', image: () => import('@/assets/picto/lavage_vitre_elevateur.svg')},
  {name: 'Drainage epuration', value: 'drainage_epuration', image: () => import('@/assets/picto/drainage_epuration.svg')},
  {name: 'Lavage vitre', value: 'lavage_vitre', image: () => import('@/assets/picto/lavage_vitre.svg')},
  {name: 'Temperature', value: 'temperature', image: () => import('@/assets/picto/temperature.svg')},
  {name: 'Cuisine', value: 'cuisine', image: () => import('@/assets/picto/cuisine.svg')},
  {name: 'Peinture', value: 'peinture', image: () => import('@/assets/picto/peinture.svg') },
  {name: 'Prise de mesure', value: 'prise_de_mesure', image: () => import('@/assets/picto/prise_de_mesure.svg') },
  {name: 'Maçonnerie', value: 'maconnerie', image: () => import('@/assets/picto/maconnerie.svg') },
  {name: 'Serrurier', value: 'serrurier', image: () => import('@/assets/picto/serrurier.svg')},
  {name: 'Electricien', value: 'electricien', image: () => import('@/assets/picto/electricien.svg') },
  {name: 'Reparation toiture', value: 'reparation_toiture', image: () => import('@/assets/picto/reparation_toiture.svg') },
  {name: 'Fuite de gaz', value: 'fuite_de_gaz', image: () => import('@/assets/picto/fuite_de_gaz.svg') },
  {name: 'Pioche', value: 'pioche', image: () => import('@/assets/picto/pioche.svg')},
  // {name: 'No graffiti', value: 'no_graffiti', image: () => import('@/assets/picto/no_graffiti.svg') },
  // {name: 'Grue de chantier', value: 'grue_de_chantier', image: () => import('@/assets/picto/grue_de_chantier.svg') },
  // {name: 'Chantier', value: 'chantier', image: () => import('@/assets/picto/chantier.svg') },
  // {name: 'Chantier travaux', value: 'chantier_travaux', image: () => import('@/assets/picto/chantier_travaux.svg') },
  {name: 'Gardien', value: 'gardien', image: () => import('@/assets/picto/gardien.svg')},
  // {name: 'Remorqueur', value: 'remorqueur', image: () => import('@/assets/picto/remorqueur.svg') },
  // {name: 'Dépôt clé', value: 'depot_cle', image: () => import('@/assets/picto/depot_cle.svg') },
  // {name: 'Remise des clés', value: 'remise_des_cles', image: () => import('@/assets/picto/remise_des_cles.svg')},
  // {name: 'Enquête', value: 'enquete', image: () => import('@/assets/picto/enquete.svg')},
  {name: 'Danger obstacle', value: 'danger_obstacle', image: () => import('@/assets/picto/danger_obstacle.svg')},
  // {name: 'Visite', value: 'visite', image: () => import('@/assets/picto/visite.svg')},
  {name: 'Infos', value: 'infos', image: () => import('@/assets/picto/infos.svg')},
  {name: 'Ventilation', value: 'ventilation', image: () => import('@/assets/picto/ventilation.svg')},
  {name: 'Volet', value: 'volet', image: () => import('@/assets/picto/volet.svg')},
  {name: 'Rack velo', value: 'rack_velo', image: () => import('@/assets/picto/rack_velo.svg')},
  {name: 'Salle de bain', value: 'sdb', image: () => import('@/assets/picto/sdb.svg')},
  {name: 'Radiateur', value: 'radiateur', image: () => import('@/assets/picto/radiateur.svg')},
  {name: 'Balcon', value: 'balcon', image: () => import('@/assets/picto/balcon.svg')},
  {name: 'Chaudière', value: 'chaudiere2', image: () => import('@/assets/picto/chaudiere2.svg')},
  {name: 'Hall', value: 'hall', image: () => import('@/assets/picto/hall.svg')},
  {name: 'Fenetre', value: 'fenetre', image: () => import('@/assets/picto/fenetre.svg')},
  {name: 'Isolation thermique', value: 'isolation_thermique', image: () => import('@/assets/picto/isolation_thermique.svg')},
  {name: 'Isolation des combles', value: 'isolation_combles', image: () => import('@/assets/picto/isolation_combles.svg')},
  {name: 'Interphone', value: 'interphone', image: () => import('@/assets/picto/interphone.svg')},
  {name: 'Cuisine', value: 'cuisine2', image: () => import('@/assets/picto/cuisine2.svg')},
  {name: 'Coupure gaz', value: 'coupure_gaz', image: () => import('@/assets/picto/coupure_gaz.svg')},
]

icons.forEach(async (icon) => {
  const Component = VueInReact((await icon.image()).default)
  // eslint-disable-next-line react/display-name
  icon.component = () => (
    <div>
      <Component />
    </div>
  )
})

export default icons
