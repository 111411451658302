import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Button, Modal, notification} from 'antd'
import {concertationService} from '@/services'
import useVueState from '@/hooks/useVueState'
import Pictogram from '@/components/Pictogram'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import LocationFormatter from '@/components/LocationFormatter'
import styles from './ConcertationOverview.module.scss'

function ConcertationOverview({ concertation, routerPush }) {
  const config = useVueState((s) => s.user.config)
  const canUpdate = useMemo(
    () => config?.metadata?.permissions?.concertation?.update !== false,
    [config],
  )
  const canDelete = useMemo(
    () => config?.metadata?.permissions?.concertation?.delete !== false,
    [config],
  )

  async function askDelete() {
    Modal.confirm({
      title: 'Confirmer',
      icon: null,
      content: 'Voulez-vous vraiment supprimer la concertation ?',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      confirmLoading: true,
      async onOk() {
        try {
          await concertationService.destroy({
            id: concertation.id,
          })
          routerPush({ name: 'list-concertation' })
        } catch (error) {
          notification.error({
            message: 'Erreur !',
            description: error.toString(),
          })
        }
      },
    })
  }

  return (
    <div className="row justify-content-center">
      <div className="col-md-6">
        <table className={styles.details}>
          <tbody>
            <tr>
              <th>Titre</th>
              <td>{concertation.name}</td>
            </tr>
            {concertation.description && (
              <tr>
                <th>Description</th>
                <td>
                  <TextEditorRenderer>
                    {concertation.description}
                  </TextEditorRenderer>
                </td>
              </tr>
            )}
            {concertation.welcomeMessage && (
              <tr>
                <th>Message de bienvenue</th>
                <td>
                  <TextEditorRenderer>
                    {concertation.welcomeMessage}
                  </TextEditorRenderer>
                </td>
              </tr>
            )}
            {concertation.thankyouMessage && (
              <tr>
                <th>Message de remerciement</th>
                <td>
                  <TextEditorRenderer>
                    {concertation.thankyouMessage}
                  </TextEditorRenderer>
                </td>
              </tr>
            )}
            <tr>
              <th>Pictogramme associé</th>
              <td>
                <Pictogram name={concertation.pictogram} />
              </td>
            </tr>
            {concertation.file && (
              <tr>
                <th>Photo</th>
                <td>
                  <img src={concertation.file} />
                </td>
              </tr>
            )}
            <tr>
              <th>Date</th>
              <td>
                Du {moment(concertation.startAt).format('DD/MM/YYYY')}&nbsp;
                au {moment(concertation.endAt).format('DD/MM/YYYY')}
              </td>
            </tr>
            <tr>
              <th>Lieu</th>
              <td>
                <LocationFormatter locations={concertation.localisations}/>
              </td>
            </tr>
            <tr>
              <th>Participants</th>
              <td>
                {concertation.interactions}/{concertation.ulActive}
                ({(concertation.interactions / concertation.ulActive).toFixed(2) * 100}%)
              </td>
            </tr>
            <tr>
              <th>Statistiques</th>
              <td>
                <div className={styles.metricLine}>
                  <div className={styles.metric}>UL concernés : {concertation.ulTargeted}</div>
                  <div className={styles.metric}>UL actifs : {concertation.ulActive}</div>
                </div>
                <div className={styles.metricLine}>
                  <div className={styles.metric}>UL atteints : {concertation.views}</div>
                  <div className={styles.metric}>Interactions : {concertation.interactions}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={styles.actions}>
          <Button
            disabled={!canUpdate}
            onClick={() => routerPush({ name: 'edit-concertation', params: { id: concertation.id } })}
          >
            Modifier
          </Button>
          <Button type="primary" danger disabled={!canDelete} onClick={askDelete}>
            Supprimer
          </Button>
        </div>
      </div>
    </div>
  )
}

ConcertationOverview.propTypes = {
  concertation: PropTypes.object.isRequired,
  routerPush: PropTypes.func.isRequired,
}

export default ConcertationOverview
