import React, {useEffect, useState, useMemo} from 'react'
import {Button, Drawer, Modal, notification, Space, Table} from 'antd'
import styles from './Config.module.scss'
import {localisationTypeService} from '@/services'
import LocationTypeForm from '@/views/admin/Config/LocationTypeForm'
import units from '@/utils/locationUnitList'

function LocationType() {
  const [currentLocationType, setCurrentLocationType] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [locationTypes, setLocationTypes] = useState(null)
  const [error, setError] = useState(null)
  const columns = useMemo(() => [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Unité',
      dataIndex: 'unit',
      key: 'unit',
      render: unit => units.find(v => v.value === unit)?.name ?? 'Inconnu',
    },
    {
      title: 'Cacher aux locataires',
      dataIndex: 'limitToLessor',
      key: 'limitToLessor',
      render: limitToLessor => limitToLessor ? 'Oui' : 'Non',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => {
            setCurrentLocationType(record)
            setDrawerVisible(true)
          }}>
            Editer
          </Button>
          <Button type="link" danger onClick={() => deleteLocationType(record)}>
            Supprimer
          </Button>
        </Space>
      ),
    },
  ], [locationTypes])

  useEffect(() => {
    setLoading(true)
    localisationTypeService.list()
      .then((localisationTypes) => setLocationTypes(localisationTypes))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!drawerVisible) {
      setCurrentLocationType(null)
    }
  }, [drawerVisible])

  function createOrUpdateLocationType(values) {
    (currentLocationType ? updateLocationType : createLocationType)(values)
      .then(() => setDrawerVisible(false))
      .catch(error => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
  }

  function createLocationType(values) {
    return localisationTypeService.create(values)
      .then(locationType => setLocationTypes([...locationTypes, locationType]))
  }

  function updateLocationType(values) {
    return localisationTypeService.update({
      id: currentLocationType.id,
      ...values,
    }).then(locationType => setLocationTypes(locationTypes.map(v => v.id === locationType.id ? locationType : v)))
  }

  async function deleteLocationType(locationType) {
    Modal.confirm({
      title: 'Confirmer',
      icon: null,
      content: 'Voulez-vous vraiment supprimer ce type de localisation ?',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      confirmLoading: true,
      onOk: () =>
        localisationTypeService.destroy({ id: locationType.id })
          .then(() => setLocationTypes(locationTypes.filter(v => v.id !== locationType.id)))
          .catch((error) => notification.error({
            message: 'Erreur !',
            description: error.toString(),
          })),
    })
  }

  function renderContent() {
    if (loading) {
      return null
    }

    if (error) {
      return error.message
    }

    return <Table dataSource={locationTypes} columns={columns} pagination={false} rowKey="id"/>
  }

  return (
    <>
      <div className={styles.header}>
        <Button type="primary" size="large" onClick={() => setDrawerVisible(true)}>
          Ajouter un type de localisation
        </Button>
      </div>

      {renderContent()}

      <Drawer
        title={currentLocationType ? 'Editer un type de localisation' : 'Ajouter un type de localisation'}
        placement="right"
        width="min(100vw, 600px)"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <LocationTypeForm locationType={currentLocationType} onSubmit={createOrUpdateLocationType} />
      </Drawer>
    </>
  )
}

export default LocationType
