import client from '@/utils/apollo'
import UPDATE_MUTATION from '@/queries/updateConfig.graphql'
import NEW_SETUP_INTENT_MUTATION from '@/queries/newSetupIntent.graphql'
import PAYMENT_METHOD_QUERY from '@/queries/paymentMethod.graphql'
import LIST_INVOICES_QUERY from '@/queries/invoices.graphql'
import UPDATE_PAYMENT_METHOD_MUTATION from '@/queries/updatePaymentMethod.graphql'

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {
      input: Object.fromEntries(
        Object.entries(input)
          .filter(([k]) => [
            'reportOrder', 'concertationOrder', 'disableUserReport', 'homeScreenEnabled',
            'homeScreenMessage', 'homeScreenPicture', 'homeScreenButtonMessage'].includes(k))
      )
    },
  })
  return res.data.updateConfig
}

export async function newSetupIntent() {
  const res = await client.mutate({
    mutation: NEW_SETUP_INTENT_MUTATION,
  })
  return res.data.newSetupIntent
}

export async function getPaymentMethod() {
  const res = await client.query({
    query: PAYMENT_METHOD_QUERY,
  })
  return res.data.paymentMethod
}

export async function updatePaymentMethod(input) {
  const res = await client.mutate({
    mutation: UPDATE_PAYMENT_METHOD_MUTATION,
    variables: {input},
  })
  return res.data.updatePaymentMethod
}

export async function listInvoices() {
  const res = await client.query({
    query: LIST_INVOICES_QUERY,
  })
  return res.data.invoices
}
