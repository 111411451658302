import React, {useEffect, useState} from 'react'
import {Button, Card, Drawer, Modal, notification} from 'antd'
import {EditOutlined, DeleteOutlined} from '@ant-design/icons'
import styles from './Config.module.scss'
import {contactService} from '@/services'
import LocationFormatter from '@/components/LocationFormatter'
import ContactForm from '@/views/admin/Config/ContactForm'

function Contact() {
  const [currentContact, setCurrentContact] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [contacts, setContacts] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
    contactService.list()
      .then((contacts) => setContacts(contacts))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!drawerVisible) {
      setCurrentContact(null)
    }
  }, [drawerVisible])

  function createOrUpdateContact(values) {
    (currentContact ? updateContact : createContact)(values)
      .then(() => setDrawerVisible(false))
      .catch(error => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
  }

  function createContact(values) {
    return contactService.create(values)
      .then(contact => setContacts([...contacts, contact]))
  }

  function updateContact(values) {
    return contactService.update({
      id: currentContact.id,
      ...values,
    }).then(contact => setContacts(contacts.map(v => v.id === contact.id ? contact : v)))
  }

  async function deleteContact(contact) {
    Modal.confirm({
      title: 'Confirmer',
      icon: null,
      content: 'Voulez-vous vraiment supprimer le contact ?',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      confirmLoading: true,
      onOk: () =>
        contactService.destroy({ id: contact.id })
          .then(() => setContacts(contacts.filter(v => v.id !== contact.id)))
          .catch((error) => notification.error({
            message: 'Erreur !',
            description: error.toString(),
          })),
    })
  }

  function renderContent() {
    if (loading) {
      return null
    }

    if (error) {
      return error.message
    }

    return contacts.map(contact => (
      <Card
        key={contact.id}
        className={styles.contactCard}
        actions={[
          <EditOutlined key="edit" onClick={() => {
            setCurrentContact(contact)
            setDrawerVisible(true)
          }}/>,
          <DeleteOutlined key="delete" onClick={() => deleteContact(contact)}/>,
        ]}
      >
        {contact.name && (
          <p>
            <strong>Nom :</strong> {contact.name}
          </p>
        )}
        {contact.role && (
          <p>
            <strong>Rôle :</strong> {contact.role}
          </p>
        )}
        {contact.phone && (
          <p>
            <strong>Téléphone :</strong>
            {contact.phone}
          </p>
        )}
        {contact.locations?.length > 0 && (
          <p>
            <strong>Localisations :</strong>
            <LocationFormatter locations={contact.locations} />
          </p>
        )}
        {contact.hours?.length > 0 && (
          <p>
            <strong>Horaires :</strong>
            <ul>
              {contact.hours.map((text) => <li key={text}>{text}</li>)}
            </ul>
          </p>
        )}
      </Card>
    ))
  }

  return (
    <>
      <div className={styles.header}>
        <Button type="primary" size="large" onClick={() => setDrawerVisible(true)}>
          Ajouter un contact
        </Button>
      </div>

      {renderContent()}

      <Drawer
        title={currentContact ? 'Editer un contact' : 'Ajouter un contact'}
        placement="right"
        width="min(100vw, 600px)"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <ContactForm contact={currentContact} onSubmit={createOrUpdateContact} />
      </Drawer>
    </>
  )
}

export default Contact
