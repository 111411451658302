<template>
  <div class="side-nav-link" @click="click" :class="{ disabled }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SideNavLink',
  props: ['to', 'disabled'],
  methods: {
    click(event) {
      if (this.to) {
        this.$router.push(this.to)
      }
      this.$emit('input', false)
      this.$emit('click', event)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.side-nav-link {
  color: $color-white;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color .4s ease-out;
  font-weight: 400;
  font-size: 16px;
  line-height: 1rem;
  cursor: pointer;

  &:hover {
    background: darken($color-blue, 1);
  }

  svg {
    height: 24px;
    fill: $color-white;
    margin-right: 8px;
  }

  &.disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
    opacity: .75;
  }
}
</style>
