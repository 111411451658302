import { client } from '@/utils/api'

const initialState = {
  owners: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setOwners(state, owners) {
      state.owners = owners
    },
  },
  actions: {
    async fetchOwners({ commit }) {
      const res = await client.get('owners')
      commit('setOwners', res.data.data)
      return res.data.data
    },
    async fetchOwner(_, id) {
      const res = await client.get(`owner/${id}`)
      return res.data.data
    },
    async updateOwner(_, { id, ...payload }) {
      await client.patch(`owner/${id}`, payload)
    },
    async deleteOwner(_, { id }) {
      await client.delete(`owner?_id=${id}`)
    },
  },
}
