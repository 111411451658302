<template>
  <div class="satisfaction">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="SatisfactionForm"
            title="Ajouter une satisfaction"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'
import {satisfactionService} from '@/services'
import RenderReact from '@/components/RenderReact'
import SatisfactionForm from '@/components/SatisfactionForm'

export default {
  name: 'NewSatisfaction',
  data: () => ({
    SatisfactionForm,
  }),
  components: {
    RenderReact,
  },
  methods: {
    async onSubmit(values) {
      await satisfactionService.create(values)
      this.$router.push({ name: 'list-satisfaction' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.satisfaction {
  margin-bottom: 48px;
}

.title {
  font-size: 24px;
  color: $color-satisfaction;
  font-weight: 600;
  margin: 32px 0;

  svg {
    height: 42px;
    margin-right: 12px;
    fill: $color-satisfaction;
  }
}
</style>
