import {localisationService} from '@/services'

const initialState = {
  localisations: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setLocalisations(state, localisations) {
      state.localisations = localisations
    },
  },
  actions: {
    async fetchLocalisations({ commit }) {
      const data = await localisationService.list()
      commit('setLocalisations', data)
      return data
    },
  },
}
