import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {TreeSelect} from 'antd'
import useVueState from '@/hooks/useVueState'

function LocationInput({ unit = 'bloc', value, onChange, disabled }) {
  const localisations = useVueState((s) => s.localisation.localisations)
  const locations = useMemo(() => {
    function buildLocalisations(reamingTypes = ['site', 'building', 'bloc', 'homestay'], parentId = null) {
      return reamingTypes.length !== 0 && reamingTypes.includes(unit) ?
        localisations
          ?.filter(l => l.type === reamingTypes[0] && l.parentId === parentId)
          .sort(({ name: a }, { name: b }) => a.localeCompare(b))
          .map(l => ({
            title: l.name.toUpperCase(),
            value: `${reamingTypes[0]}:${l.id}`,
            children: buildLocalisations(reamingTypes.slice(1), l.id),
          }))
        : undefined
    }
    return buildLocalisations()
  }, [localisations, unit])
  const formattedValue = useMemo(
    () => value?.map(v => `${v.type}:${v.id}`) ?? [],
    [value],
  )

  function remapValues(values) {
    const newValues = values.map(value => {
      const [type, id] = value.split(':')
      return { type, id }
    })
    onChange(newValues)
  }

  return (
    <TreeSelect
      showSearch
      treeCheckable
      placeholder="Sélectionner"
      treeData={locations}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      value={formattedValue}
      onChange={remapValues}
      disabled={disabled}
    />
  )
}

LocationInput.propTypes = {
  unit: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default LocationInput
