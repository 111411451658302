<template>
  <div class="home">
    <div class="header">
      <ImmeubleIcon></ImmeubleIcon> Localisation
    </div>
    <div class="container">
      <Error :error="error"></Error>
      <div class="actions">
        <div class="btn primary" @click="$refs.input.click()">
          Importer des localisations
        </div>
        <router-link :to="{ name: 'admin-create-localisation' }" class="btn primary">
          Ajouter une localisation
        </router-link>
      </div>
      <div class="locations">
        <LocationCollapse v-for="location in locations" :key="location.id" :location="location">
        </LocationCollapse>
      </div>
    </div>
    <input ref="input" class="hidden-input" type="file" accept=".csv" @change="uploadLocalisations"/>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ImmeubleIcon from '@/assets/picto/immeuble.svg'
import ErrorComponent from '@/components/Error'
import LocationCollapse from '../../components/LocationCollapse'

export default {
  name: 'AdminListLocalisation',
  components: {
    LocationCollapse,
    Error: ErrorComponent,
    ImmeubleIcon,
  },
  data: () => ({
    error: undefined,
  }),
  methods: {
    ...mapActions('localisation', ['fetchLocalisations']),
    ...mapActions('admin', ['importLocations']),
    async uploadLocalisations(event) {
      this.error = undefined
      try {
        if (event.srcElement && event.srcElement.files && event.srcElement.files[0]) {
          await this.importLocations({
            file: event.srcElement.files[0],
          })
          await this.fetchLocalisations()
        }
      } catch (error) {
        if (error.isAxiosError && error.response && error.response.data && error.response.data.status === 400) {
          this.error = new Error(error.response.data.message || 'Erreur inconnu.')
        } else {
          this.error = error
        }
      }
    },
  },
  computed: {
    ...mapState('localisation', ['localisations']),
    locations() {
      if (!this.localisations) {
        return []
      }
      return this.localisations
        .filter(v => v.type === 'site')
        .sort(({ name: a }, { name: b }) => a.localeCompare(b))
        .map(site => ({
          id: site.id,
          name: site.name.toUpperCase(),
          children: this.localisations
            .filter(v => v.type === 'building' && v.parentId === site.id)
            .sort(({ name: a }, { name: b }) => a.localeCompare(b))
            .map(building => ({
              id: building.id,
              name: building.name.toUpperCase(),
              children: this.localisations
                .filter(v => v.type === 'bloc' && v.parentId === building.id)
                .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                .map(bloc => ({
                  id: bloc.id,
                  name: bloc.name.toUpperCase(),
                  children: this.localisations
                    .filter(v => v.type === 'homestay' && v.parentId === bloc.id)
                    .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                    .map(homestay => ({
                      id: homestay.id,
                      name: homestay.name.toUpperCase(),
                    }))
                }))
            }))
        }))
    }
  },
  async mounted() {
    try {
      await this.fetchLocalisations()
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.home {
  padding-bottom: 48px;
}

.hidden-input {
  display: none;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 32px;

  .btn {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.locations {
  display: flex;
  flex-wrap: wrap;

  & > .location-wrapper {
    width: 33%;
    margin-left: 0;
    margin-bottom: 16px;

    & > .location-name {
      font-weight: 700;
    }

    & > .location-wrapper {
      & > .location-name {
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
