import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { BoldOutlined, ItalicOutlined, UnorderedListOutlined } from '@ant-design/icons'
import {Editor, EditorState, RichUtils, CompositeDecorator, convertToRaw, convertFromRaw} from 'draft-js'
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js'
import styles from './TextEditor.module.scss'
import TextEditorLinkForm from './TextEditorLinkForm'
import TextEditorLink from './TextEditorLink'

const inlineStyles = [
  {
    icon: <BoldOutlined />,
    style: 'BOLD',
    type: 'inline',
  },
  {
    icon: <ItalicOutlined />,
    style: 'ITALIC',
    type: 'inline',
  },
  {
    icon: <UnorderedListOutlined />,
    style: 'unordered-list-item',
    type: 'block',
  },
]

const decorator = new CompositeDecorator([
  {
    component: TextEditorLink,
    strategy: TextEditorLink.findLinkEntities,
  },
])

function TextEditor({ value, onChange }) {
  const [editorState, setEditorState] = useState(() =>
    value
      ? EditorState.createWithContent(convertFromRaw(markdownToDraft(value)), decorator)
      : EditorState.createEmpty(decorator),
  )

  function handleKeyCommand(command, editorState) {
    if (!inlineStyles.some(v => v.style.toLowerCase() === command.toLowerCase())) {
      return
    }
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      editorChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  function toggleStyle(style) {
    return (event) => {
      event.preventDefault()

      if (style.type === 'inline') {
        editorChange(RichUtils.toggleInlineStyle(editorState, style.style))
      } else if (style.type === 'block') {
        editorChange(RichUtils.toggleBlockType(editorState, style.style))
      }
    }
  }

  function isStyleToggled(style) {
    if (style.type === 'inline') {
      return editorState.getCurrentInlineStyle().has(style.style)
    } else if (style.type === 'block') {
      return editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey())
        .getType() === style.style
    }
  }

  function editorChange(editorState) {
    if (onChange) {
      onChange(draftToMarkdown(convertToRaw(editorState.getCurrentContent())))
    }
    setEditorState(editorState)
  }

  return (
    <div className={styles.root}>
      <div className={styles.toolbar}>
        {inlineStyles.map(curr => (
          <button
            key={curr.style}
            onMouseDown={toggleStyle(curr)}
            className={clsx(styles.toolbarItem, { [styles.toolbarItemActive]: isStyleToggled(curr) })}
            type="button"
          >
            {curr.icon}
          </button>
        ))}
        <TextEditorLinkForm
          editorState={editorState}
          setEditorState={editorChange}
        />
      </div>
      <div className={styles.editor}>
        <Editor
          editorState={editorState}
          onChange={editorChange}
          handleKeyCommand={handleKeyCommand}
        />
      </div>
    </div>
  )
}

TextEditor.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default TextEditor
