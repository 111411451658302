import React, {useMemo, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Form, Typography, Button, Input, DatePicker, notification} from 'antd'
import PictogramInput from '@/components/PictogramInput'
import LocationInput from '@/components/LocationInput'
import TextEditor from '@/components/TextEditor/TextEditor'
import PictureInput from '@/components/PictureInput'
import useVueState from '@/hooks/useVueState'

const { Title } = Typography

function disabledDate(current) {
  return current && current < moment().endOf('day')
}

function TribuneForm({ title, tribune, onSubmit, routerBack }) {
  const [form] = Form.useForm()
  const userData = useVueState((s) => s.user.user)
  const [localisationTypes, setLocalisationType] = useState("");

  useEffect(() => {
    if (userData) {
      if (userData.role === "user") {
        setLocalisationType("bloc");
      } else {
        setLocalisationType("homestay");
      }
    }
  }, [userData])

  const initialValues = useMemo(() => {
    return tribune ? {
      ...tribune,
      availableAt: tribune.availableAt ? moment(tribune.availableAt) : null,
      startAt: tribune.startAt ? moment(tribune.startAt) : null,
    } : {}
  }, [tribune])

  function preSubmit(values) {
    const payload = {
      ...values,
    }
    return onSubmit(payload).catch(error => {
      return notification.error({
        message: 'Erreur !',
        description: error.toString(),
      })
    })
  }

  return (
    <div>
      <Title level={3}>
        {title}
      </Title>
      <Form form={form} initialValues={initialValues} onFinish={preSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Titre"
          extra="Exemples : Réunion d'information, Fête de quartier, Communication DSU, etc..."
          rules={[{ required: true, message: 'Titre requis.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <TextEditor />
        </Form.Item>

        <Form.Item
          name="pictogram"
          label="Pictogramme"
          rules={[{ required: true, message: 'Pictogramme requis.' }]}
        >
          <PictogramInput />
        </Form.Item>

        <Form.Item
          name="file"
          label="Photo"
        >
          <PictureInput />
        </Form.Item>

        <Form.Item
          name="localisations"
          label="Localisations"
          rules={[{ required: true, message: 'Localisations requises.' }]}
        >
          <LocationInput unit={localisationTypes} />
        </Form.Item>

        <Form.Item
          name="startAt"
          label="Date"
          rules={[{ required: true, message: 'Date requise.' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Sélectionner"
          />
        </Form.Item>

        <Form.Item name="availableAt" label="Programmer">
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Sélectionner"
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={routerBack}>
            Annuler
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

TribuneForm.propTypes = {
  title: PropTypes.string.isRequired,
  tribune: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  routerBack: PropTypes.func.isRequired,
}

export default TribuneForm
