<template>
  <div class="tutorial" id="tutorials">
    <div class="tutorial-title">
      Vidéos tutoriels
    </div>
<!--    <div class="tutorial-list">-->
<!--      <div-->
<!--        v-for="(tutorial, index) in tutorials"-->
<!--        :key="index"-->
<!--        @click="openVideo(tutorial)"-->
<!--        class="tutorial-item"-->
<!--      >-->
<!--        <div class="tutoria-item-icon">-->
<!--          <component v-bind:is="tutorial.icon"></component>-->
<!--        </div>-->
<!--        <div class="tutoria-item-name">{{tutorial.name}}</div>-->
<!--      </div>-->
<!--    </div>-->
    <template v-if="partnersTutorials.length">
      <div class="tutorial-list">
        <div
          v-for="tutorial in partnersTutorials"
          :key="tutorial._id"
          @click="openVideo(tutorial)"
          class="tutorial-item"
        >
          <div class="tutoria-item-icon">
            <img :src="tutorial.image" :alt="tutorial.name" :title="tutorial.name"/>
          </div>
          <div class="tutoria-item-name">
            {{tutorial.name}}
          </div>
        </div>
      </div>
    </template>
    <div id="video-player"></div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core'
import { CapacitorVideoPlayer } from 'capacitor-video-player'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { mapState } from 'vuex'
import SignalementIcon from '@/assets/picto/signalement.svg'
import VoteIcon from '@/assets/picto/voter.svg'
import TravauxIcon from '@/assets/picto/travaux.svg'
import InterventionIcon from '@/assets/picto/interventions.svg'
import TribuneIcon from '@/assets/picto/tribune.svg'
import ContactIcon from '@/assets/picto/contacts.svg'

export default {
  name: 'UserTutorial',
  data: () => ({
    tutorials: [
      {
        name: 'Signalement',
        video: 'https://youtu.be/FQJxVhY4EK8',
        videoId: 'wuw_BDCRRWk',
        icon: SignalementIcon,
      },
      {
        name: 'Concertation',
        video: 'https://youtu.be/uMfWE7St7Gc',
        videoId: 'uMfWE7St7Gc',
        icon: VoteIcon,
      },
      {
        name: 'Contact',
        video: 'https://youtu.be/e-hS4C2HVhQ',
        videoId: 'e-hS4C2HVhQ',
        icon: ContactIcon,
      },
      {
        name: 'Travaux',
        video: 'https://youtu.be/4XOzon6hLhQ',
        videoId: '4XOzon6hLhQ',
        icon: TravauxIcon,
      },
      {
        name: 'Intervention',
        video: 'https://youtu.be/2zOWM7pbkTY',
        videoId: '2zOWM7pbkTY',
        icon: InterventionIcon,
      },
      {
        name: 'Tribune',
        video: 'https://youtu.be/I-A_avOjquc',
        videoId: 'I-A_avOjquc',
        icon: TribuneIcon,
      },
    ],
  }),
  computed: {
    ...mapState('user', ['config']),
    partnersTutorials() {
      return this.config?.tutorials ?? []
    },
  },
  methods: {
    async openVideo(tutorial) {
      await CapacitorVideoPlayer.initPlayer({
        url: tutorial.video,
        mode: 'fullscreen',
        playerId: 'video-player',
        componentTag: '#tutorials',
        pageTitle: tutorial.name,
      })
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      FirebaseAnalytics.setScreenName({
        screenName: 'tutorial',
        nameOverride: 'TutorialScreen',
      }).catch(console.error)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

.tutorial {
  margin: 32px 16px;
}

.tutorial-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
}

.tutorial-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}

.tutorial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  .tutoria-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 50%;

    svg, img {
      fill: $color-white;
      height: 48px;
      width: 48px;
    }
  }

  .tutoria-item-name {
    font-weight: 500;
    text-align: center;
  }

  &:nth-child(1) .tutoria-item-icon {
    background: $color-reporting;
  }

  &:nth-child(2) .tutoria-item-icon {
    background: $color-concertation;
  }

  &:nth-child(3) .tutoria-item-icon {
    background: $color-contact;
  }

  &:nth-child(4) .tutoria-item-icon {
    background: $color-work;
  }

  &:nth-child(5) .tutoria-item-icon {
    background: $color-intervention;
  }

  &:nth-child(6) .tutoria-item-icon {
    background: $color-tribune;
  }
}

.partner-list {
  .tutoria-item-icon {
    background: #fff !important;
    border: 1px solid $color-grey;
  }
}
</style>
