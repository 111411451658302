import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/satisfactions.graphql'
import GET_QUERY from '@/queries/satisfaction.graphql'
import CREATE_MUTATION from '@/queries/createSatisfaction.graphql'
import UPDATE_MUTATION from '@/queries/updateSatisfaction.graphql'
import DELETE_MUTATION from '@/queries/deleteSatisfaction.graphql'
import VOTE_MUTATION from '@/queries/voteSatisfaction.graphql'

export async function list({filter, localisations = []} = {}) {
  const res = await client.query({
    query: LIST_QUERY,
    variables: {
      filter,
      localisations,
    },
  })
  return res.data.satisfactions
}

export async function get({ id }) {
  const res = await client.query({
    query: GET_QUERY,
    variables: { id },
  })
  return res.data.satisfaction
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createSatisfaction
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {input},
  })
  return res.data.updateSatisfaction
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteSatisfaction
}

export async function vote(input) {
  const res = await client.mutate({
    mutation: VOTE_MUTATION,
    variables: {input},
  })
  return res.data.voteSatisfaction
}
