import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/signalementTitles.graphql'
import CREATE_MUTATION from '@/queries/createSignalementTitle.graphql'
import DELETE_MUTATION from '@/queries/deleteSignalementTitle.graphql'

export async function list() {
  const res = await client.query({
    query: LIST_QUERY,
  })
  return res.data.signalementTitles
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createSignalementTitle
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteSignalementTitle
}
