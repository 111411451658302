<template>
  <div class="user-list-signalements">
    <router-link
      v-if="config && config.features.includes('signalement_locataire')"
      class="new-signalement"
      :to="{ name: 'user-signalements-new' }"
    >
      <i class="ion-ios-add-circle-outline"></i>
      Envoyer un signalement
    </router-link>

    <template v-if="mappedReports.length > 0">
      <div class="card" v-for="report in reports" :key="report.id">
        <div class="card-header">
          <div class="card-type-icon">
            <ReportIcon></ReportIcon>
          </div>
          <div class="card-icon">
            <component
              v-if="report.pictogram"
              v-bind:is="findIcon(report.pictogram).image"
            ></component>
          </div>
          <div class="card-right" v-if="report.status !== undefined">
            <div class="card-status">
              <StatusIcon :status="report.status"></StatusIcon>
              <div class="text">
                {{ report.status | status_label }}
                <!-- {{ statusReport[report.status] }} -->
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-title">{{ report.name }}</div>
          <!-- <div class="card-duration">
            <DurationIcon></DurationIcon>
            Créé {{report.createdAt | moment("from", "now")}}
          </div> -->
          <div class="card-description">
            <RenderReact
              :component="TextEditorRenderer"
              :children="report.description"
            />
          </div>
          <img
            v-if="report.picture && !report.hidePicture"
            class="card-image"
            :src="report.picture"
          />
        </div>
        <div class="card-footer">
          <div style="display: flex; justify-content: space-between">
            <div>
              <div v-if="report.hasRead" class="card-button read">
                <i class="ion-ios-checkmark-circle"></i>
                Information déjà lue
              </div>
              <ClickLoaderWrapper
                v-else
                class="card-button"
                @click="markAsReadWrapper(report, $event)"
              >
                <!--            <i class="ion-ios-checkmark-circle-outline"></i>-->
                J'ai lu l'information
              </ClickLoaderWrapper>
            </div>
            <div
              v-if="
                user.role !== 'user' ||
                (report.createdBy === user._id &&
                  report.displayCommentEnable === true)
              "
              style="color: #a6acaf; font-size: 35px"
              @click="setOpenComments(report)"
            >
              <div
                class="red-dot"
                v-if="!report.commentsReadBy.includes(user._id)"
              ></div>
              <i class="ion-ios-chatbubbles"></i>
            </div>
          </div>
        </div>
      </div>
      <Modal v-model="openComments">
        <div
          style="
            margin-top: 10%;
            width: 90%;
            max-height: 80vh;
            background-color: white;
          "
        >
          <div style="padding: 3%">
            <div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span style="font-weight: bold; font-size: 25px">
                  Commentaires
                </span>
                <div
                  style="color: #a6acaf; font-size: 35px"
                  @click="setOpenComments"
                >
                  <i class="ion-ios-close"></i>
                </div>
              </div>
            </div>
            <div>
              <div style="overflow: scroll; max-height: 50vh">
                <div
                  v-for="comment in comments"
                  style="
                    display: flex;
                    margin-top: 2%;
                    padding: 5%;
                    align-items: baseline;
                    border-bottom: 1px solid #bdc3c7;
                  "
                >
                  <div>
                    <div class="avatar-cirlce">
                      {{ comment.username.charAt(0).toUpperCase() }}
                    </div>
                  </div>
                  <div style="width: 100%; margin-left: 3%">
                    <div
                      style="width: 100%; display: flex; align-items: baseline"
                    >
                      <div style="font-size: 17px; font-weight: bold">
                        {{ comment.username }}
                      </div>
                      <div
                        style="
                          font-size: 13px;
                          color: #bdc3c7;
                          margin-left: 10px;
                        "
                      >
                        | {{ comment.createdDate | moment("DD/MM/YYYY") }}
                      </div>
                      <div
                        v-if="comment.userId === user._id"
                        @click="removeComment(comment._id)"
                        style="margin-left: auto"
                      >
                        <i class="ion-ios-trash"></i>
                      </div>
                    </div>
                    <div style="color: #797d7f">
                      {{ comment.comment }}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div v-if="selectedReport.commentEnable" style="margin-top: 3%">
                <textarea
                  v-model="commentaireText"
                  style="
                    border: 1px solid #cacfd2;
                    border-radius: 10px;
                    width: 100%;
                    padding: 2%;
                  "
                  rows="3"
                >
                </textarea>
                <span v-if="no_comment" style="color: red"
                  >Vous ne pouvez pas envoyer un message vide</span
                >
                <div style="justify-content: flex-end; display: flex">
                  <div
                    class="btn primary"
                    style="margin-top: 3%"
                    @click="sendComment()"
                  >
                    <div>Envoyer</div>
                  </div>
                </div>
              </div>
              <div
                v-if="!selectedReport.commentEnable"
                style="
                  margin-top: 3%;
                  display: flex;
                  justify-content: center;
                  color: gray;
                  font-style: italic;
                "
              >
                <span>Les commentaires ont été désactivé</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </template>
    <div v-else class="nothing-to-show">
      <ReportIcon></ReportIcon>
      Rien à signaler
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";
import { Capacitor } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { mapActions, mapState } from "vuex";
import ClickLoaderWrapper from "@/components/ClickLoaderWrapper";
import StatusIcon from "@/components/StatusIcon";
import DurationIcon from "@/assets/picto/historique.svg";
import ReportIcon from "@/assets/picto/signalement.svg";
import icons from "@/utils/icons";
import TextEditorRenderer from "@/components/TextEditor/TextEditorRenderer";
import RenderReact from "@/components/RenderReact";
import Modal from "@/components/Modal";
import { signalementService } from "@/services";

export default {
  name: "UserListSignalements",
  components: {
    RenderReact,
    ClickLoaderWrapper,
    ReportIcon,
    StatusIcon,
    DurationIcon,
    Modal,
  },
  data: () => ({
    TextEditorRenderer,
    hammer: undefined,
    openComments: false,
    comments: [],
    commentaireText: "",
    selectedReport: {},
    no_comment: false,
    // statusReport: [
    //   "En attente de traitement",
    //   "Ajourné",
    //   "En cours",
    //   "Prévu",
    //   "Clôturé",
    //   // "En attente d'approbation",
    //   // "Traité en interne",
    // ],
  }),
  computed: {
    ...mapState("reporting", ["reports"]),
    ...mapState("user", ["config", "user"]),
    mappedReports() {
      return (
        this.reports?.sort(
          ({ createdAt: a }, { createdAt: b }) =>
            new Date(b).getTime() - new Date(a).getTime()
        ) ?? []
      );
    },
  },
  methods: {
    ...mapActions("reporting", ["fetchReports", "markAsRead"]),
    ...mapActions("user", ["fetchUser"]),

    findIcon(name) {
      return icons.find(({ value }) => value === name) ?? {};
    },
    async setOpenComments(report = {}) {
      if (this.openComments) {
        this.openComments = !this.openComments;
        this.selectedReport = {};
      } else {
        if (!report.commentsReadBy.includes(this.user._id)) {
          let readBy = report.commentsReadBy + `${this.user._id},`;
          await signalementService.update({
            id: report.id,
            commentsReadBy: readBy,
          });
          report.commentsReadBy = readBy;
        }
        this.openComments = !this.openComments;
        this.selectedReport = report;
        this.comments = this.selectedReport.comments;
      }
    },
    markAsReadWrapper(report, cb) {
      cb(this.markAsRead({ id: report.id }));
    },
    async removeComment(commentId) {
      this.comments = await signalementService.removeComment({
        signalementId: this.selectedReport.id,
        commentId: commentId,
      });
    },
    async sendComment() {
      try {
        if (!(this.commentaireText.trim().length === 0)) {
          let usernameToDisplay = this.user.firstName ?? "Anonyme";
          if (this.user.role !== "user") {
            usernameToDisplay = "L'équipe chantier";
          }
          this.comments = await signalementService.addComment({
            signalementId: this.selectedReport.id,
            userId: this.user._id,
            comment: this.commentaireText,
            username: usernameToDisplay,
            userUL: this.user.ul,
          });
          this.commentaireText = "";
          this.no_comment = false;
        } else {
          this.no_comment = true;
        }
      } catch (error) {
        this.error = error;
      }
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== "web") {
      FirebaseAnalytics.setScreenName({
        screenName: "list-signalements",
        nameOverride: "ListSignalementsScreen",
      }).catch(console.error);
    }
    this.hammer = new Hammer(document.body);
    this.hammer.on("swipe", (event) => {
      if (event?.direction === 4) {
        this.$router.push({ name: "user-home" });
      } else if (event?.direction === 2) {
        this.$router.push({ name: "user-votes-list" });
      }
    });
    try {
      await this.fetchReports();
      await this.fetchUser();
    } catch (error) {
      await Dialog.alert({
        title: "Erreur",
        message: error.message,
      });
    }
  },
  async beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/card";

.commentaire-title {
  font-size: 24px;
  font-weight: 600;
}

.red-dot:before {
  position: absolute;
  content: "";
  background-color: #ff0000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  pointer-events: none;
  margin-left: 5%;
  margin-top: 1%;
}

.avatar-cirlce {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  background: #cccccc;
  display: inline-block;
}

.card {
  @include user-card($color-reporting);
}

.nothing-to-show {
  @include nothing-to-show($color-reporting);
}

.new-signalement {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $color-reporting;
  color: $color-white;
  font-size: 16px;
  padding: 8px 16px;
  font-weight: 600;
  margin-bottom: 16px;

  i {
    font-size: 42px;
    line-height: 48px;
    margin-right: 12px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
</style>
