<template>
  <div>
    <RenderReact
      :component="Concertation"
      :id="$route.params.id"
      :routerPush="$router.push.bind($router)"
    />
  </div>
</template>

<script>
import RenderReact from '@/components/RenderReact'
import Concertation from './index'

export default {
  name: 'ViewConcertation',
  components: {
    RenderReact,
  },
  data: () => ({ Concertation }),
}
</script>
