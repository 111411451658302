import { render, staticRenderFns } from "./ListSatisfaction.vue?vue&type=template&id=363c126c&scoped=true&"
import script from "./ListSatisfaction.vue?vue&type=script&lang=js&"
export * from "./ListSatisfaction.vue?vue&type=script&lang=js&"
import style0 from "./ListSatisfaction.vue?vue&type=style&index=0&id=363c126c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "363c126c",
  null
  
)

export default component.exports