import { Remarkable } from "remarkable"

const md = new Remarkable("default")

md.core.ruler.disable([
  'references',
  'replacements',
  'smartquotes',
  'references',
  'abbr2',
  'footnote_tail',
])
md.block.ruler.disable([
  'code',
  'fences',
  'footnote',
  'heading',
  'hr',
  'htmlblock',
  'lheading',
  'table',
])
md.inline.ruler.disable([
  'autolink',
  'backticks',
  'entity',
  'footnote_ref',
  'htmltag',
])

export default md
