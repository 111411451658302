<template>
  <div class="home">
    <div class="header">
      <ImmeubleIcon></ImmeubleIcon> Ajouter une localisation
    </div>
    <div class="container">
      <form class="form" @submit="submit">
        <Error :error="error"></Error>
        <div class="form-field">
          <div class="form-field-label">
            Type de localisation
            <span class="required">*</span>
          </div>
          <div class="select">
            <select v-model="type">
              <option value="" selected>Sélectionner</option>
              <option value="homestay">Logement</option>
              <option value="bloc">Bloc</option>
              <option value="building">Batiment</option>
              <option value="site">Site</option>
            </select>
          </div>
        </div>
        <div class="form-field" v-if="['bloc', 'building', 'site'].includes(type)">
          <div class="form-field-label">
            Nom
            <span class="required">*</span>
          </div>
          <input v-model="name" class="form-field-input" type="text">
        </div>
        <div class="form-field" v-if="['bloc', 'site'].includes(type)">
          <div class="form-field-label">
            Adresse
            <span class="required">*</span>
          </div>
          <input v-model="address" class="form-field-input" type="text">
        </div>
        <div class="form-field" v-if="['site'].includes(type)">
          <div class="form-field-label">
            Téléphone
            <span class="required">*</span>
          </div>
          <input v-model="phone" class="form-field-input" type="text">
        </div>
        <div class="form-field" v-if="['homestay'].includes(type)">
          <div class="form-field-label">
            UL
            <span class="required">*</span>
          </div>
          <input v-model="ul" class="form-field-input" type="text">
        </div>
        <div class="form-field" v-if="['homestay'].includes(type)">
          <div class="form-field-label">
            Etage
            <span class="required">*</span>
          </div>
          <input v-model="floor" class="form-field-input" type="text">
        </div>
        <div class="form-field" v-if="['homestay'].includes(type)">
          <div class="form-field-label">
            Numéro
            <span class="required">*</span>
          </div>
          <input v-model="number" class="form-field-input" type="text">
        </div>
        <div class="form-field" v-if="['bloc', 'building', 'site'].includes(type)">
          <div class="form-field-label">
            Gestionnaire
            <span class="required">*</span>
          </div>
          <div class="select">
            <select v-model="superownerId">
              <option value="" selected>Sélectionner</option>
              <option v-for="so in superOwners" :key="so.id" :value="so.id">
                {{so.firstName}} {{so.lastName}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-field" v-if="['homestay', 'bloc', 'building'].includes(type)">
          <div class="form-field-label">
            Localisation parente
            <span class="required">*</span>
          </div>
          <div class="select">
            <select v-model="parentId">
              <option value="" selected>Sélectionner</option>
              <option v-for="loc in parentLocalisations" :key="loc.id" :value="loc.id">
                {{loc.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-submission">
          <div class="btn default" @click="$router.go(-1)">
            Annuler
          </div>
          <button type="submit" class="btn primary">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ImmeubleIcon from '@/assets/picto/immeuble.svg'
import ErrorComponent from '@/components/Error'
import {userService} from '@/services'

export default {
  name: 'AdminCreateLocalisation',
  components: {
    Error: ErrorComponent,
    ImmeubleIcon,
  },
  data: () => ({
    loading: false,
    error: undefined,
    type: '',
    name: '',
    address: '',
    phone: '',
    ul: '',
    floor: '',
    number: '',
    superownerId: '',
    parentId: '',
    superOwners: [],
  }),
  watch: {
    type() {
      this.parentId = ''
    },
  },
  computed: {
    ...mapState('localisation', ['localisations']),
    parentLocalisations() {
      if (!this.localisations && this.type && this.type !== 'site') {
        return []
      }
      const parentType = {
        homestay: 'bloc',
        bloc: 'building',
        building: 'site',
      }[this.type]
      return this.localisations.filter(v => v.type === parentType)
    },
  },
  methods: {
    ...mapActions('localisation', ['fetchLocalisations']),
    ...mapActions('admin', ['createLocation']),
    async submit(event) {
      event.preventDefault()
      if (this.loading) return
      this.loading = true
      try {
        switch (this.type) {
        case 'homestay':
          if (!this.ul || !this.floor || !this.number || !this.parentId) {
            throw new Error('Champs manquant.')
          }
          await this.createLocation({
            type: 'homestay',
            ul: this.ul,
            floor: this.floor,
            number: this.number,
            blocId: this.parentId,
          })
          break
        case 'bloc':
          if (!this.name || !this.address || !this.superownerId || !this.parentId) {
            throw new Error('Champs manquant.')
          }
          await this.createLocation({
            type: 'bloc',
            name: this.name,
            address: this.address,
            superownerId: this.superownerId,
            buildingId: this.parentId,
          })
          break
        case 'building':
          if (!this.name || !this.superownerId || !this.parentId) {
            throw new Error('Champs manquant.')
          }
          await this.createLocation({
            type: 'building',
            name: this.name,
            superownerId: this.superownerId,
            siteId: this.parentId,
          })
          break
        case 'site':
          if (!this.name || !this.address || !this.phone || !this.superownerId) {
            throw new Error('Champs manquant.')
          }
          await this.createLocation({
            type: 'site',
            name: this.name,
            address: this.address,
            phone: this.phone,
            superownerId: this.superownerId,
          })
          break
        default:
          throw new Error('Localisation incorrect.')
        }
        this.$router.go(-1)
      } catch (error) {
        if (error.isAxiosError && error.response && error.response.data && error.response.data.status === 400) {
          this.error = new Error(error.response.data.message || 'Erreur inconnu.')
        } else {
          this.error = error
        }
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    try {
      const [superOwners] = await Promise.all([
        userService.list({ only: ['SUPER_OWNER'] }),
        this.fetchLocalisations(),
      ])
      this.superOwners = superOwners
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.home {
  padding-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.form {
  margin: 0 auto;
  max-width: 720px;
  padding: 0 16px;
}

.select {
  z-index: 3000;
  display: inline;
  border: 1px solid #000;
  padding: 4px;
  border-radius: 24px;

  select {
    border: none;
    box-shadow: none;
    background: transparent;
    color: #000;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
