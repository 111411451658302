<template>
  <div class="work">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="WorkForm"
            title="Ajouter un signalement travaux"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'
import {workService} from '@/services'
import RenderReact from '@/components/RenderReact'
import WorkForm from '@/components/WorkForm'

export default {
  name: 'NewWork',
  components: {RenderReact},
  data: () => ({ WorkForm }),
  methods: {
    async onSubmit(values) {
      await workService.create(values)
      this.$router.push({ name: 'list-work' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.work {
  margin-bottom: 48px;
}
</style>
