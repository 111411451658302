<template>
  <div class="home">
    <div class="header">
      <StatistiquesIcon></StatistiquesIcon>
      Statistiques
    </div>
    <div class="container">
      <div class="filter-container">
        <div class="filter">
          <div class="filter-name">Localisations :</div>
          <LocalisationInput v-model="localisations" unit="bloc" />
        </div>
        <div class="filter">
          <div class="filter-name">Filtrer :</div>
          <div class="select">
            <select v-model="textDuration">
              <option value="week">Semaine</option>
              <option value="month">Mois</option>
              <option value="year">Année</option>
              <option value="other">Autre</option>
            </select>
          </div>
          <DatePicker
            v-if="textDuration === 'other'"
            style="margin-top: 12px"
            v-model="duration"
            :lang="lang"
            format="DD-MM-YYYY"
            range
          />
        </div>
      </div>
      <div class="actions">
        <div
          class="btn primary"
          @click="downloadULReport"
          :class="{
            disabled: config && !config.features.includes('download_stats'),
          }"
        >
          Télécharger le rapport UL
        </div>
        <div
          class="btn primary"
          @click="downloadActiveULReport"
          :class="{
            disabled: config && !config.features.includes('download_stats'),
          }"
        >
          Télécharger le rapport UL actif
        </div>
        <!-- <div
          class="btn primary"
          @click="downloadGlobalReport"
          :class="{
            disabled: config && !config.features.includes('download_stats'),
          }"
        >
          Télécharger le rapport global
        </div> -->
      </div>

      <Error :error="error"></Error>

      <!-- STATISTIC PART -->
      <!-- <div style="margin: 32px 0 16px">
        <canvas ref="conversions"></canvas>
      </div>

      <div class="card-list">
        <div class="card">
          <div class="title">
            Nombre de cartes créées
          </div>
          <canvas ref="created"></canvas>
        </div>
        <div class="card">
          <div class="title">
            Moyenne des logements concernés
          </div>
          <canvas ref="averageConcerned"></canvas>
        </div>
        <div class="card">
          <div class="title">
            Moyenne des logements concernés équipés
          </div>
          <canvas ref="averageActive"></canvas>
        </div>
        <div class="card">
          <div class="title">
            Moyenne des logements atteints
          </div>
          <canvas ref="averageAchieved"></canvas>
        </div>
        <div class="card">
          <div class="title">
            Moyenne des logements ayant interagi
          </div>
          <canvas ref="averageConverted"></canvas>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Chart from "chart.js";
import { mapActions, mapState } from "vuex";
import { API_ENDPOINT } from "@/utils/api";
import StatistiquesIcon from "@/assets/picto/analytics.svg";
import Error from "@/components/Error";
import LocalisationInput from "@/components/LocalisationInput";

export default {
  name: "SuperOwnerStatistics",
  components: {
    LocalisationInput,
    Error,
    DatePicker,
    StatistiquesIcon,
  },
  data: () => ({
    error: undefined,
    textDuration: "week",
    localisations: [],
    duration: [],
    statistics: undefined,
    conversionsChart: undefined,
    createdChart: undefined,
    averageConcernedChart: undefined,
    averageActiveChart: undefined,
    averageAchievedChart: undefined,
    averageConvertedChart: undefined,
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    },
  }),
  watch: {
    textDuration: {
      immediate: true,
      async handler(value) {
        if (value === "other") return;
        const endAt = new Date();
        const startAt = moment(endAt).subtract(1, value).toDate();
        this.duration = [startAt, endAt];
      },
    },
    localisations() {
      return this.updateStatistics();
    },
    duration: {
      immediate: true,
      handler() {
        return this.updateStatistics();
      },
    },
    statistics(statistics) {
      this.destroyCharts();
      this.conversionsChart = new Chart(this.$refs.conversions, {
        type: "radar",
        data: {
          datasets: [
            {
              label: "Concernés",
              data: this.convertData(statistics.averageConcerned),
              fill: false,
              borderColor: "#80deea",
            },
            {
              label: "Actifs",
              data: this.convertData(statistics.averageActive),
              fill: false,
              borderColor: "#29b6f6",
            },
            {
              label: "Atteints",
              data: this.convertData(statistics.averageAchieved),
              fill: false,
              borderColor: "#1e88e5",
            },
            {
              label: "Convertis",
              data: this.convertData(statistics.averageConverted),
              fill: false,
              borderColor: "#283593",
            },
          ],
          labels: [
            "Signalement",
            "Concertation",
            "Satisfaction",
            "Travaux",
            "Intervention",
            "Tribune",
          ],
        },
      });
      this.createdChart = this.createBasicChart(
        this.$refs.created,
        statistics.createdCards
      );
      this.averageConcernedChart = this.createBasicChart(
        this.$refs.averageConcerned,
        statistics.averageConcerned
      );
      this.averageActiveChart = this.createBasicChart(
        this.$refs.averageActive,
        statistics.averageActive
      );
      this.averageAchievedChart = this.createBasicChart(
        this.$refs.averageAchieved,
        statistics.averageAchieved
      );
      this.averageConvertedChart = this.createBasicChart(
        this.$refs.averageConverted,
        statistics.averageConverted
      );
    },
  },
  computed: mapState("user", ["token", "config"]),
  methods: {
    ...mapActions("owner", ["fetchOwners", "updateOwner"]),
    ...mapActions("user", ["fetchStatistics"]),
    downloadActiveULReport() {
      const urlParams = new URLSearchParams();
      urlParams.append("token", this.token);
      urlParams.append("localisations", JSON.stringify(this.localisations));

      window.open(
        `${API_ENDPOINT}superowner/active_ul.csv?${urlParams.toString()}`,
        "_blank"
      );
    },
    downloadGlobalReport() {
      const urlParams = new URLSearchParams();
      urlParams.append("token", this.token);
      urlParams.append("startAt", this.duration[0]);
      urlParams.append("endAt", this.duration[1]);
      urlParams.append("localisations", JSON.stringify(this.localisations));

      window.open(
        `${API_ENDPOINT}superowner/global.csv?${urlParams.toString()}`,
        "_blank"
      );
    },
    downloadULReport() {
      const urlParams = new URLSearchParams();
      urlParams.append("token", this.token);
      urlParams.append("localisations", JSON.stringify(this.localisations));

      window.open(
        `${API_ENDPOINT}superowner/ul.csv?${urlParams.toString()}`,
        "_blank"
      );
    },
    convertData({
      signalement,
      concertation,
      satisfaction,
      travaux,
      intervention,
      tribune,
    }) {
      return [
        Math.round(signalement * 100) / 100,
        Math.round(concertation * 100) / 100,
        Math.round(satisfaction * 100) / 100,
        Math.round(travaux * 100) / 100,
        Math.round(intervention * 100) / 100,
        Math.round(tribune * 100) / 100,
      ];
    },
    createBasicChart(
      el,
      {
        signalement,
        concertation,
        satisfaction,
        travaux,
        intervention,
        tribune,
      }
    ) {
      return new Chart(el, {
        type: "pie",
        data: {
          datasets: [
            {
              data: this.convertData({
                signalement,
                concertation,
                satisfaction,
                travaux,
                intervention,
                tribune,
              }),
              backgroundColor: [
                "#fa5757",
                "#5983f7",
                "#1bd566",
                "#efd62f",
                "#66CCFC",
                "#ffa554",
              ],
            },
          ],
          labels: [
            "Signalement",
            "Concertation",
            "Satisfaction",
            "Travaux",
            "Intervention",
            "Tribune",
          ],
        },
        options: {},
      });
    },
    destroyCharts() {
      if (this.conversionsChart) {
        this.conversionsChart.destroy();
      }
      if (this.createdChart) {
        this.createdChart.destroy();
      }
      if (this.averageConcernedChart) {
        this.averageConcernedChart.destroy();
      }
      if (this.averageActiveChart) {
        this.averageActiveChart.destroy();
      }
      if (this.averageAchievedChart) {
        this.averageAchievedChart.destroy();
      }
      if (this.averageConvertedChart) {
        this.averageConvertedChart.destroy();
      }
    },
    async updateStatistics() {
      try {
        this.statistics = await this.fetchStatistics({
          startAt: this.duration[0],
          endAt: this.duration[1],
          localisations: this.localisations,
        });
      } catch (error) {
        this.error = error;
      }
    },
  },
  destroyed() {
    this.destroyCharts();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.home {
  padding-bottom: 48px;
}

.card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.card {
  width: 50%;

  .title {
    font-size: 16px;
    font-weight: 600;
    margin: 32px 0 16px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

table th,
table td {
  padding: 8px;
}

.filter-container {
  background: $color-grey-light;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 32px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .filter {
    width: 30%;
    margin-right: 1%;

    &:last-child {
      margin-right: 0;
    }
  }

  .filter-name {
    font-weight: 600;
    color: $color-grey-dark;
    margin-bottom: 6px;
  }

  .filter .select {
    display: inline-block;
    margin-right: 16px;
    width: 100%;

    select {
      width: 100%;
      height: 34px;
      padding: 6px 30px 6px 10px;
      font-size: 14px;
      line-height: 1.4;
      color: #555;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.disabledRow th {
  opacity: 0.4;
}

.success {
  background: $color-green;
  padding: 16px;
  margin-bottom: 16px;
  color: $color-white;
  border-radius: 4px;
}

.mx-datepicker-range {
  width: 240px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }

  .filter-container {
    flex-direction: column;

    .filter {
      width: 100%;
      margin-right: 0;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .card {
    width: 100%;
  }
}
</style>
