import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Typography } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import styles from './Config.module.scss'
import LocationInput from '@/components/LocationInput'
import PictureInput from '@/components/PictureInput'

const { Title } = Typography

function ContactForm({ contact, onSubmit }) {
  const [form] = Form.useForm()
  const initialValues = useMemo(() => contact, [contact])

  useEffect(() => {
    form.resetFields()
  }, [form, contact])

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name="role" label="Rôle" rules={[{ required: true, message: 'Requis.' }]}>
        <Input />
      </Form.Item>

      {/* <Form.Item name="image" label="Image">
        <PictureInput />
      </Form.Item> */}

      <Form.Item name="name" label="Nom">
        <Input />
      </Form.Item>

      <Form.Item name="phone" label="Téléphone">
        <Input />
      </Form.Item>

      <Form.Item name="localisations" label="Localisation" rules={[{ required: true, message: 'Requis.' }]}>
        <LocationInput />
      </Form.Item>

      <Title level={5}>
        Horaires
      </Title>

      <Form.List name="hours">
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <div key={field.key} className={styles.hourLine}>
                <Form.Item
                  name={[field.name]} fieldKey={[field.fieldKey]}
                  className={styles.hourLineInput}
                >
                  <Input />
                </Form.Item>

                <div className={styles.hourLineRemove} onClick={() => remove(field.name)}>
                  <MinusCircleOutlined />
                </div>
              </div>
            ))}

            <Button type="dashed" style={{ marginBottom: 24 }} onClick={() => add()} block>
              Ajouter un horaire
            </Button>
          </>
        )}
      </Form.List>

      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          Valider
        </Button>
      </Form.Item>
    </Form>
  )
}

ContactForm.propTypes = {
  contact: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default ContactForm
