<template>
  <div v-if="loaded" class="user-list-interventions" style="margin-top: 3%">
    <template v-if="mappedInterventions.length > 0">
      <div
        class="card"
        v-for="intervention in interventions"
        :key="intervention.id"
      >
        <div class="card-header">
          <div class="card-type-icon">
            <InterventionIcon></InterventionIcon>
          </div>
          <div class="card-icon">
            <component
              v-if="intervention.pictogram"
              v-bind:is="findIcon(intervention.pictogram).image"
            ></component>
          </div>
        </div>
        <div class="card-body">
          <div class="card-title">
            {{ intervention.name }}
          </div>
          <div class="card-duration">
            <CalendarIcon></CalendarIcon>
            <div>
              <span v-if="intervention.startAt">{{
                intervention.startAt | moment("DD/MM/YYYY")
              }}</span>
              {{ intervention.time ? ` | ${intervention.time}` : null }}
            </div>
            <!-- <RenderReact
              :component="DateRangeFormatter"
              :startAt="intervention.startAt"
              :endAt="intervention.endAt"
            /> -->
          </div>
          <!-- <div class="card-duration">
            <DurationIcon></DurationIcon>
            {{intervention.createdAt | moment("from", "now")}}
          </div> -->
          <div class="card-data" v-if="intervention.company">
            Prestataire : {{ intervention.company }}
          </div>
          <div class="card-description">
            <RenderReact
              :component="TextEditorRenderer"
              :children="intervention.description"
            />
          </div>
          <img
            v-if="intervention.picture"
            class="card-image"
            :src="intervention.picture"
          />
        </div>
        <div class="card-footer">
          <div
            v-if="
              !intervention.answers.some((item) => item.userId === user._id)
            "
          >
            <!-- v-if="textArea[intervention.id].display" -->
            <input
              v-if="displayFieldID === intervention.id"
              class="form-field-input"
              v-model="textArea[intervention.id].text"
              placeholder="Proposer une autre date + heure"
            />
            <span style="color: red" v-if="errorFieldID === intervention.id"
              >Merci de proposer une nouvelle date & heure</span
            >

            <div class="interventionAnswer">
              <div
                class="answer disabled"
                :class="{ active: displayFieldID === intervention.id }"
                @click="setCancel(intervention)"
              >
                Annuler
              </div>
              <div
                class="answer avaidable"
                :class="{ disabled: displayFieldID === intervention.id }"
                @click="
                  displayFieldID !== intervention.id
                    ? setAnswer('disponible', intervention)
                    : null
                "
              >
                <!-- :class="{ disabled: textArea[intervention.id].display }" -->
                Disponible
              </div>
              <!-- <div class="answer notAvaidable" @click="setAbsent(intervention)"> -->
              <div class="answer notAvaidable" @click="setAbsent(intervention)">
                Absent
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="getResponse(intervention)" class="card-button read">
              <i class="ion-ios-checkmark-circle"></i>
              Intervention le
              <span v-if="intervention.startAt">{{
                intervention.startAt | moment("DD/MM/YYYY")
              }}</span>
              <span v-else style="color: grey">&nbsp(non défini)</span>
            </div>
            <div v-else class="card-button read" style="color: #ff7b72">
              <i class="ion-ios-close"></i>
              Intervention refusée
            </div>
          </div>

          <!-- 
          <ClickLoaderWrapper
            v-else
            class="card-button"
            @click="markAsReadWrapper(intervention)">
            <i class="ion-ios-checkmark-circle-outline"></i>
            J'ai lu l'information
          </ClickLoaderWrapper> -->
        </div>
      </div>
    </template>
    <div v-else class="nothing-to-show">
      <InterventionIcon></InterventionIcon>
      Rien à signaler
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Hammer from "hammerjs";
import { Capacitor } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { mapActions, mapState } from "vuex";
import ClickLoaderWrapper from "@/components/ClickLoaderWrapper";
import DurationIcon from "@/assets/picto/historique.svg";
import CalendarIcon from "@/assets/picto/agenda.svg";
import InterventionIcon from "@/assets/picto/interventions.svg";
import icons from "@/utils/icons";
import TextEditorRenderer from "@/components/TextEditor/TextEditorRenderer";
import RenderReact from "@/components/RenderReact";
import DateRangeFormatter from "@/components/DateRangeFormatter";
import { interventionService } from "@/services";

export default {
  name: "UserListInterventions",
  components: {
    RenderReact,
    ClickLoaderWrapper,
    InterventionIcon,
    CalendarIcon,
    DurationIcon,
  },
  data: () => ({
    TextEditorRenderer,
    DateRangeFormatter,
    hammer: undefined,
    textArea: {},
    loaded: false,
    displayFieldID: null,
    errorFieldID: null,
  }),
  computed: {
    ...mapState("intervention", ["interventions"]),
    ...mapState("user", ["user"]),
    mappedInterventions() {
      return (
        this.interventions?.sort(
          ({ availableAt: a }, { availableAt: b }) =>
            new Date(b).getTime() - new Date(a).getTime()
        ) ?? []
      );
    },
  },
  methods: {
    ...mapActions("intervention", ["fetchInterventions", "markAsRead"]),
    ...mapActions("user", ["fetchUser"]),

    findIcon(name) {
      return icons.find(({ value }) => value === name) ?? {};
    },
    markAsReadWrapper(intervention, cb) {
      cb(this.markAsRead({ id: intervention.id }));
    },
    getResponse(intervention) {
      const index = intervention.answers.findIndex((object) => {
        return object.userId === this.user._id;
      });
      if (intervention.answers[index].answer === "absent") return false;
      return true;
    },
    setCancel(intervention) {
      this.textArea[intervention.id].display = false;
      this.displayFieldID = null;
      this.errorFieldID = null;
    },
    setAbsent(intervention) {
      if (!this.textArea[intervention.id].display) {
        this.textArea[intervention.id].display = true;
        this.displayFieldID = intervention.id;
      } else {
        if (this.textArea[intervention.id].text.length > 0) {
          this.displayFieldID = null;
          this.errorFieldID = null;
          this.textArea[intervention.id].display = false;
          this.setAnswer("absent", intervention);
        } else {
          this.errorFieldID = intervention.id;
        }
      }
    },
    getTextArea(intervention) {
      return this.textArea[intervention.id];
    },
    async setAnswer(answer, intervention) {
      const data = {
        id: intervention.id,
        answer,
        text: this.textArea[intervention.id].text,
      };
      const res = await interventionService.answer(data);
      intervention.answers.push(res);
      // cb(this.markAsRead({ id: intervention.id }));
    },
    mapCalendarDate(intervention) {
      const startAt = moment(intervention.startAt).format("DD/MM/YYYY");
      const endAt = moment(intervention.endAt).format("DD/MM/YYYY");
      if (startAt === endAt) {
        return `Le ${startAt}`;
      }
      return `Du ${startAt} au ${endAt}`;
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== "web") {
      FirebaseAnalytics.setScreenName({
        screenName: "list-interventions",
        nameOverride: "ListInterventionsScreen",
      }).catch(console.error);
    }
    this.hammer = new Hammer(document.body);
    this.hammer.on("swipe", (event) => {
      if (event?.direction === 4) {
        this.$router.push({ name: "user-travaux-list" });
      } else if (event?.direction === 2) {
        this.$router.push({ name: "user-contacts" });
      }
    });
    try {
      await this.fetchInterventions();
      await this.fetchUser();
      if (this.interventions.length) {
        this.interventions.map((elem) => {
          this.textArea[elem.id] = {
            text: "",
            display: false,
          };
        });
      }
      this.loaded = true;
    } catch (error) {
      await Dialog.alert({
        title: "Erreur",
        message: error.message,
      });
    }
  },
  async beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/card";

.interventionAnswer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 5%;
}

.answer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px;
  width: 40%;
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.02), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.disabled {
  background-color: red;
  display: none;
}

.active {
  background: grey;
  display: flex;
}

.avaidable {
  background: $color-green-light;
}

.notAvaidable {
  background: $color-red-light;
}

.card {
  @include user-card($color-intervention);
  padding: 0 15px;
}

.nothing-to-show {
  @include nothing-to-show($color-intervention);
}
</style>
