import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Form, Typography, Button, Input, DatePicker, notification } from 'antd'
import PictogramInput from '@/components/PictogramInput'
import LocationInput from '@/components/LocationInput'
import TextEditor from '@/components/TextEditor/TextEditor'
import PictureInput from '@/components/PictureInput'
import LocationTypeInput from '@/components/LocationTypeInput'
import useVueState from '@/hooks/useVueState'

const { Title } = Typography

function disabledDate(current) {
  return current && current < moment().endOf('day')
}

function ReportForm({ title, report, onSubmit, routerBack }) {
  const [form] = Form.useForm()
  const localisationTypes = useVueState((s) => s.user.localisationTypes)
  const [currentLocalisationTypeId, setCurrentLocalisationTypeId] = useState('')
  const currentLocalisationType = useMemo(
    () => localisationTypes?.find(v => v.id === currentLocalisationTypeId),
    [localisationTypes, currentLocalisationTypeId],
  )
  const initialValues = useMemo(() => {
    if (report) {
      setCurrentLocalisationTypeId(report.localisationTypeId)
    }
    return report ? {
      ...report,
      availableAt: report.availableAt ? moment(report.availableAt) : null,
      automaticClosingAt: report.automaticClosingAt ? moment(report.automaticClosingAt) : null,
    } : {}
  }, [report])

  function preSubmit(values) {
    const payload = {
      ...values,
    }
    return onSubmit(payload).catch(error => {
      return notification.error({
        message: 'Erreur !',
        description: error.toString(),
      })
    })
  }

  function onLocalisationTypeIdChange(type) {
    setCurrentLocalisationTypeId(type)
    return type
  }

  return (
    <div>
      <Title level={3}>
        {title}
      </Title>
      <Form form={form} initialValues={initialValues} onFinish={preSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Titre"
          extra="Exemples : Fuite d'eau, Panne d'ascenseur, Portail en panne, etc..."
          rules={[{ required: true, message: 'Titre requis.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <TextEditor />
        </Form.Item>

        <Form.Item
          name="pictogram"
          label="Pictogramme"
          rules={[{ required: true, message: 'Pictogramme requis.' }]}
        >
          <PictogramInput />
        </Form.Item>

        <Form.Item
          name="picture"
          label="Photo"
        >
          <PictureInput />
        </Form.Item>

        <Form.Item
          name="localisationTypeId"
          label="Type de localisation"
          rules={[{ required: true, message: 'Type de localisation requis.' }]}
        >
          <LocationTypeInput onChange={onLocalisationTypeIdChange} />
        </Form.Item>

        <Form.Item
          name="localisations"
          label="Localisations"
          rules={[{ required: true, message: 'Localisations requises.' }]}
        >
          <LocationInput
            unit={currentLocalisationType?.unit}
            disabled={!currentLocalisationType}
          />
        </Form.Item>

        {/* <Form.Item name="availableAt" label="Programmer">
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Sélectionner"
            disabledDate={disabledDate}
          />
        </Form.Item> */}

        {/* <Form.Item name="automaticClosingAt" label="Cloture">
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Sélectionner"
            disabledDate={disabledDate}
          />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={routerBack}>
            Annuler
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

ReportForm.propTypes = {
  title: PropTypes.string.isRequired,
  report: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  routerBack: PropTypes.func.isRequired,
}

export default ReportForm
