import client from '@/utils/apollo'
import ADMIN_LIST_QUERY from '@/queries/adminListCompanies.graphql'
import ADMIN_GET_QUERY from '@/queries/adminGetCompany.graphql'
import ADMIN_UPDATE_MUTATION from '@/queries/adminUpdateCompany.graphql'
import ADMIN_CREATE_MUTATION from '@/queries/adminCreateCompany.graphql'
import ADMIN_DELETE_MUTATION from '@/queries/adminDeleteCompany.graphql'

export async function adminList() {
  const res = await client.query({
    query: ADMIN_LIST_QUERY,
  })
  return res.data.companies
}

export async function adminGet({ id }) {
  const res = await client.query({
    query: ADMIN_GET_QUERY,
    variables: { id },
  })
  return res.data.company
}

export async function adminCreate(input) {
  const res = await client.mutate({
    mutation: ADMIN_CREATE_MUTATION,
    variables: { input },
  })
  return res.data.createCompany
}

export async function adminUpdate(input) {
  const res = await client.mutate({
    mutation: ADMIN_UPDATE_MUTATION,
    variables: { input },
  })
  return res.data.updateCompany
}

export async function adminDelete(input) {
  const res = await client.mutate({
    mutation: ADMIN_DELETE_MUTATION,
    variables: { input },
  })
  return res.data.deleteCompany
}
