import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from 'antd'
import GeneralOrderForm from '@/views/admin/Config/GeneralOrderForm'
import GeneralFeaturesForm from '@/views/admin/Config/GeneralFeaturesForm'
import styles from './Config.module.scss'

const {Title} = Typography

function General({ config, updateConfig }) {
  return (
    <>
      <div className={styles.generalForm}>
        <Title level={4}>Fonctionalités</Title>
        <GeneralFeaturesForm config={config} updateConfig={updateConfig}/>
      </div>

      <div className={styles.generalForm}>
        <Title level={4}>Consignes</Title>
        <GeneralOrderForm config={config} updateConfig={updateConfig}/>
      </div>
    </>
  )
}

General.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
}

export default General
