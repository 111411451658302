import React from 'react'
import PropTypes from 'prop-types'
import {Card, Col, Row} from 'antd'

function ConcertationStatistics({ concertation }) {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
        {/*<Card title="Card title" style={{ width: '100%' }}>*/}
        {/*  statistics*/}
        {/*</Card>*/}
      </Col>
    </Row>
  )
}

ConcertationStatistics.propTypes = {
  concertation: PropTypes.object.isRequired,
}

export default ConcertationStatistics
