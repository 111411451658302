<template>
  <div :class="{ 'select': !light, 'light-select': light }">
    <select :value="value" @input="$emit('input', $event.srcElement.value)">
      <option :value="undefined" selected>Sélectionner</option>
      <SelectOption v-for="curr in localisationTypes" :key="curr.id" :data="curr">
      </SelectOption>
    </select>
  </div>
</template>

<script>
import SelectOption from '@/components/SelectOption'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LocalisationTypeInput',
  props: ['value', 'light'],
  components: {
    SelectOption,
  },
  computed: mapState('user', ['localisationTypes']),
  methods: mapActions('user', ['fetchLocalisationTypes']),
  async mounted() {
    await this.fetchLocalisationTypes()
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.select {
  z-index: 3000;
  display: inline;
  border: 1px solid #000;
  padding: 4px 4px;
  border-radius: 12px;

  select {
    border: none;
    box-shadow: none;
    background: transparent;
    color: #000;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.light-select {
  z-index: 3000;
  display: block;
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 4px;

  select {
    border: none;
    box-shadow: none;
    font-size: $font-family;
    color: #bdbdbd;
    padding: 0 6px 0 4px;
    width: 100%;
    height: 36px;
    appearance: none;
    background: transparent url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.83294 2.13566C8.72168 2.02432 8.58981 1.96869 8.43743 1.96869H0.562542C0.410101 1.96869 0.278316 2.02432 0.166971 2.13566C0.0556262 2.24713 0 2.37891 0 2.53126C0 2.68358 0.0556262 2.81537 0.166971 2.92674L4.10443 6.8642C4.2159 6.97554 4.34768 7.03129 4.5 7.03129C4.65232 7.03129 4.78423 6.97554 4.89548 6.8642L8.83294 2.92671C8.94416 2.81537 9 2.68358 9 2.53123C9 2.37891 8.94416 2.24713 8.83294 2.13566V2.13566Z' fill='%23CCCCCC'/%3E%3C/svg%3E%0A") no-repeat;
    background-position-x: 98%;
    background-position-y: 12px;

    option {
      color: #000;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
