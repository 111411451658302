<template>
  <div class="satisfaction">
    <div class="container">
      <div class="go-back" @click="$router.back()">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="title">
            <SatisfactionIcon></SatisfactionIcon> Satisfaction
          </div>
          <Error :error="error"></Error>

          <template v-if="satisfaction">
            <table class="details">
              <tbody>
                <tr>
                  <th>Titre</th>
                  <td>{{satisfaction.name}}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>
                    Du {{satisfaction.startAt | moment("DD/MM/YYYY")}} au {{satisfaction.endAt | moment("DD/MM/YYYY")}}
                  </td>
                </tr>
                <tr v-if="satisfaction.file">
                  <th>Photo</th>
                  <td>
                    <img :src="satisfaction.file">
                  </td>
                </tr>
                <tr>
                  <th>Lieu</th>
                  <td>
                    <LogementFormatter :locations="satisfaction.localisations"></LogementFormatter>
                  </td>
                </tr>
                <tr>
                  <th>Satisfaits</th>
                  <td class="upvote">
                    <SatisfactionIcon></SatisfactionIcon>
                    {{toward.counter}} votants ({{toward.percentage}} %)
                  </td>
                </tr>
                <tr>
                  <th>Insatisfaits</th>
                  <td class="downvote">
                    <SatisfactionIcon></SatisfactionIcon>
                    {{against.counter}} votants  ({{against.percentage}} %)
                  </td>
                </tr>
                <tr>
                  <th>Nombre total de votants</th>
                  <td class="negativ" style="color: orange">
                    {{satisfaction.interactions}} votants
                  </td>
                </tr>
                <tr>
                  <th>Statistiques</th>
                  <td>
                    <div class="metric-line">
                      <div class="metric">UL concernés : {{satisfaction.ulTargeted}}</div>
                      <div class="metric">UL actifs : {{satisfaction.ulActive}}</div>
                    </div>
                    <!-- <div class="metric-line">
                      <div class="metric">UL atteints : {{satisfaction.views}}</div>
                      <div class="metric">Interactions : {{satisfaction.interactions}}</div>
                    </div> -->
                  </td>
                </tr>
                <tr>
                  <th>Pictogramme associé</th>
                  <td v-if="icon">
                    <component v-bind:is="icon.image"></component>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="delete-report">
              <div class="btn default" @click="$router.push({ name: 'edit-satisfaction', params: { id: satisfaction.id } })"
                   :class="{ disabled: !canUpdate }">
                <div>Modifier l'enquête satisfaction</div>
              </div>
            </div>
            <div class="delete-report" @click="downloadCsv">
              <div class="btn default">
                <div>Télécharger fichier CSV</div>
              </div>
            </div>
            <div class="delete-report">
              <div class="btn default" @click="askDelete" :class="{ disabled: !canDelete }">
                <DeleteIcon></DeleteIcon>
                <div>Supprimer l'enquête satisfaction</div>
              </div>
            </div>
          </template>
          <div v-else-if="!error">
            Chargement...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import icons from '@/utils/icons'
import { API_ENDPOINT } from '@/utils/api'
import { satisfactionService } from '@/services'
import SatisfactionIcon from '@/assets/picto/satisfaction.svg'
import DeleteIcon from '@/assets/picto/supprimer.svg'
import ErrorComponent from '@/components/Error'
import LogementFormatter from '@/components/LogementFormatter'

export default {
  name: 'ViewSatisfaction',
  data: () => ({
    error: undefined,
    satisfaction: undefined,
    activeUl: 0
  }),
  components: {
    LogementFormatter,
    Error: ErrorComponent,
    SatisfactionIcon,
    DeleteIcon,
  },
  computed: {
    ...mapState('user', ['config']),
    canUpdate() {
      return this.config?.metadata?.permissions?.satisfaction?.update !== false
    },
    canDelete() {
      return this.config?.metadata?.permissions?.satisfaction?.delete !== false
    },
    icon() {
      return icons.find(({ value }) => value === this.satisfaction?.pictogram)
    },
    toward() {
      return this.satisfaction?.options.find(v => v.type === 'TOWARD')
    },
    against() {
      return this.satisfaction?.options.find(v => v.type === 'AGAINST')
    },
  },
  methods: {
    async askDelete() {
      if (confirm("Voulez-vous vraiment supprimer l'enquête satisfaction ?")) {
        try {
          await satisfactionService.destroy({
            id: this.satisfaction.id,
          })
          this.$router.back()
        } catch (error) {
          this.error = error
        }
      }
    },
    downloadCsv() {
      window.open(`${API_ENDPOINT}satisfaction/${this.satisfaction.id}/stats.csv`, '_blank')
    },
  },
  async mounted() {
    try {
      this.satisfaction = await satisfactionService.get({
        id: this.$route.params.id,
      })
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.importantInfo {
  td {
    font-size: 45px;
  }
}

.metric-line {
  display: flex;
  flex-direction: row;
  align-items: center;

  .metric {
    margin-right: 16px;
  }
}

.satisfaction {
  margin-bottom: 48px;
}

.upvote {
  svg {
    height: 42px !important;
    fill: $color-satisfaction;
  }
}

.downvote {
  svg {
    height: 42px !important;
    fill: $color-red;
    transform: rotate(180deg);
  }
}

.title {
  font-size: 24px;
  color: $color-satisfaction;
  font-weight: 600;
  margin: 32px 0;

  svg {
    height: 42px;
    margin-right: 12px;
    fill: $color-satisfaction;
  }
}

.details th,
.track-status {
  color: #a2a2a2;
  font-weight: 500;
  font-size: 16px;
}

.track-status {
  margin: 16px 0;
}

.details {
  th {
    padding: 16px 16px 16px 0;
  }

  td {
    font-size: 16px;
    font-weight: 600;

    svg {
      height: 64px;
    }

    img {
      height: 128px;
    }

    &.description {
      font-weight: 400;
    }
  }
}

.track-status-details {
  width: 100%;

  th {
    background: #4c4c4c;
    color: #a2a2a2;
    font-weight: 500;
    padding: 8px;
  }

  td {
    padding: 8px;
  }
}

.delete-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 16px 0;

  .btn {
    &,
    div {
      display: flex;
      flex-direction: row;
      align-self: center;
    }

    svg {
      height: 32px;
      margin-right: 8px;
      fill: $color-blue-light;
    }
  }
}
</style>
