import React, {useMemo, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Form, Typography, Button, Input, DatePicker, notification} from 'antd'
import ConcertationFormQuestion from '@/components/ConcertationFormQuestion'
import styles from '@/assets/styles/ConcertationForm.module.scss'
import PictogramInput from '@/components/PictogramInput'
import LocationInput from '@/components/LocationInput'
import TextEditor from '@/components/TextEditor/TextEditor'
import PictureInput from "@/components/PictureInput";
import useVueState from '@/hooks/useVueState'

const { Title } = Typography

function ConcertationForm({ title, concertation, config, onSubmit, routerBack }) {
  const [form] = Form.useForm()

  const userData = useVueState((s) => s.user.user)
  const [localisationTypes, setLocalisationType] = useState("");

  useEffect(() => {
    if (userData) {
      if (userData.role === "user") {
        setLocalisationType("bloc");
      } else {
        setLocalisationType("homestay");
      }
    }
  }, [userData])
  const initialValues = useMemo(() => {
    return concertation ? {
      ...concertation,
      date: [moment(concertation.startAt), moment(concertation.endAt)],
      availableAt: concertation.availableAt ? moment(concertation.availableAt) : null,
    } : { questions: [{}] }
  }, [concertation])

  function preSubmit(values) {
    const payload = {
      ...values,
      date: undefined,
      startAt: values.date[0],
      endAt: values.date[1],
    }
    return onSubmit(payload).catch(error => {
      return notification.error({
        message: 'Erreur !',
        description: error.toString(),
      })
    })
  }

  return (
    <div>
      <Title level={3}>
        {title}
      </Title>
      <Form form={form} initialValues={initialValues} onFinish={preSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Titre"
          rules={[{ required: true, message: 'Titre requis.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Description requise.' }]}
        >
          <TextEditor />
        </Form.Item>

        <Form.Item
          name="welcomeMessage"
          label="Message de bienvenue"
          rules={[{ required: true, message: 'Message de bienvenue requis.' }]}
        >
          <TextEditor />
        </Form.Item>

        <Form.Item
          name="thankyouMessage"
          label="Message de remerciement"
          rules={[{ required: true, message: 'Message de remerciement requis.' }]}
        >
          <TextEditor />
        </Form.Item>

        <Form.Item
          name="pictogram"
          label="Pictogramme"
          rules={[{ required: true, message: 'Pictogramme requis.' }]}
        >
          <PictogramInput />
        </Form.Item>

        <Form.Item
          name="file"
          label="Photo"
        >
          <PictureInput />
        </Form.Item>

        <div className={styles.questionList}>
          <Title level={5} className={styles.questionListTitle}>
            Questions
          </Title>

          <Form.List name="questions">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <ConcertationFormQuestion
                    key={field.key}
                    field={field}
                    canRemove={fields.length > 1}
                    remove={remove}
                    config={config}
                  />
                ))}

                {!(config && !config.features.includes('concertation-full')) && (
                  <Button type="dashed" onClick={() => add()} block>
                    Ajouter une question
                  </Button>
                )}
              </>
            )}
          </Form.List>
        </div>

        <Form.Item
          name="date"
          label="Date de début et de fin"
          rules={[{ required: true, message: 'Dates requises.' }]}
        >
          <DatePicker.RangePicker style={{ width: '100%' }} placeholder={['Sélectionner', 'Sélectionner']} />
        </Form.Item>

        <Form.Item name="availableAt" label="Résultat disponible le">
          <DatePicker style={{ width: '100%' }} placeholder="Sélectionner" />
        </Form.Item>

        <Form.Item
          name="localisations"
          label="Localisations"
          rules={[{ required: true, message: 'Localisations requises.' }]}
        >
          <LocationInput unit={localisationTypes} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={routerBack}>
            Annuler
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

ConcertationForm.propTypes = {
  title: PropTypes.string.isRequired,
  concertation: PropTypes.object,
  config: PropTypes.object,
  localisations: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  routerBack: PropTypes.func.isRequired,
}

export default ConcertationForm
