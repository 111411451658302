<template>
  <div>
    <RenderReact
      :component="Intervention"
      :id="$route.params.id"
      :routerPush="$router.push.bind($router)"
    />
  </div>
</template>

<script>
import RenderReact from "@/components/RenderReact";
import Intervention from "./index";

export default {
  name: "ViewIntervention",
  components: {
    RenderReact,
  },
  data: () => ({ Intervention }),
};
</script>
