import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {Alert} from 'antd'
import {concertationService} from '@/services'
import styles from './Concertation.module.scss'
import ConcertationOverview from './ConcertationOverview'
import ConcertationResults from './ConcertationResults'
import ConcertationStatistics from './ConcertationStatistics'

function Concertation({ id, routerPush }) {
  const [page, setPage] = useState('overview')
  const [loading, setLoading] = useState(true)
  const [concertation, setConcertation] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
    concertationService.get({ id })
      .then((concertation) => setConcertation(concertation))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [id])

  function renderContent() {
    switch (page) {
    case 'overview':
      return <ConcertationOverview
        concertation={concertation}
        routerPush={routerPush}
      />
    case 'results':
      return <ConcertationResults
        concertation={concertation}
      />
    case 'statistics':
      return <ConcertationStatistics
        concertation={concertation}
      />
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerItem} onClick={() => routerPush({ name: 'list-concertation' })}>
          Concertations
        </div>
        <div className={styles.headerItem}>
          {concertation ? concertation.name : 'Chargement...'}
        </div>
      </div>
      <div className={styles.navigation}>
        <div
          onClick={() => setPage('overview')}
          className={clsx(styles.navigationLink, { [styles.active]: page === 'overview' })}
        >
          Informations
        </div>
        <div
          onClick={() => setPage('results')}
          className={clsx(styles.navigationLink, { [styles.active]: page === 'results' })}
        >
          Résultats
        </div>
        <div
          onClick={() => setPage('statistics')}
          className={clsx(styles.navigationLink, { [styles.active]: page === 'statistics' })}
        >
          Statistiques
        </div>
      </div>

      <div className={clsx(styles.container, 'container')}>
        {loading && 'Chargement...'}

        {(!loading && error) && (
          <Alert
            showIcon
            type="error"
            message="Erreur"
            description={error?.message ?? error}
          />
        )}

        {(!loading && concertation) && renderContent()}
      </div>
    </div>
  )
}

Concertation.propTypes = {
  id: PropTypes.string.isRequired,
  routerPush: PropTypes.func.isRequired,
}

export default Concertation
