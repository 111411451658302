import PropTypes from 'prop-types'
import useVueState from '@/hooks/useVueState'

function LocationFormatter({locations}) {
  const allLocations = useVueState(s => s.localisation.localisations)
  if (!allLocations) {
    return 'Chargement...'
  }
  return locations?.map(loc => {
    const found = allLocations.find(v => v.type === loc.type && v.id === loc.id)
    return found?.name?.toUpperCase() ?? 'Inconnu'
  }).join('; ')
}

LocationFormatter.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
}

export default LocationFormatter
