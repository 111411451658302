<template>
  <div class="new-signalement">
    <div class="container">
      <Error :error="error"></Error>
      <form @submit="submit">
        <template v-if="step === 0">
          <div class="signalement-header">
            Votre signalement...
          </div>

          <div class="signalement-example">
            Uniquement parties communes et exterieurs
          </div>

          <div class="signalement-title-grid">
            <div class="signalement-title" v-for="title in signalementParentTitles" :key="title.id"
              @click="selectParentTitle(title.id)">
              {{title.name}}
            </div>
          </div>
        </template>
        <template v-if="step === 1">
          <div class="signalement-header">
            Précisez...
          </div>

          <div class="signalement-title-grid">
            <div class="signalement-title" v-for="title in signalementChildTitles" :key="title.id"
                 @click="selectChildTitle(title.name)">
              {{title.name}}
            </div>
          </div>
        </template>
        <template v-else-if="step === 2">
          <div class="signalement-header">
            Ajoutez une description...
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Description
            </div>
            <textarea v-model="form.description" class="form-field-input" rows="3" @keydown="checkDescription">
            </textarea>
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Photo
            </div>
            <PictureUrlInput v-model="form.picture"/>
            <span> Une seule photo autorisée</span>
          </div>
        </template>

        <template v-else-if="step === 3">
          <div class="signalement-header">
            Sélectionnez une localisation...
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Type de localisation
              <span class="required">
                *
                <span class="required-text">
                  (Obligatoire)
                </span>
              </span>
            </div>
            <LocalisationTypeInput v-model="form.localisationType" :light="true" />
          </div>

          <div class="form-field" v-if="form.localisationType">
            <div class="form-field-label">
              Localisation
              <span class="required">
                *
                <span class="required-text">
                  (Obligatoire)
                </span>
              </span>
            </div>
            <LocalisationInput
                v-model="form.localisations"
                :unit="(currentLocalisationType || {}).unit"
                :disabled="!form.localisationType"
            />
          </div>
        </template>

        <div class="signalement-submit">
<!--          <button v-if="[3].includes(step)" :class="{ disabled: loading }"-->
<!--                  type="submit" class="btn link" style="margin-bottom: 8px">-->
<!--            Passer-->
<!--          </button>-->
          <button v-if="[2, 3].includes(step)" type="submit" class="btn success" :class="{ disabled: loading }">
            <div v-if="loading" class="loader"></div>
            <template v-else>
              {{step === 3 ? 'Valider' : 'Continuer'}}
            </template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {Capacitor} from '@capacitor/core'
import { Toast } from '@capacitor/toast'
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics'
import {mapState} from 'vuex'
import {signalementService} from '@/services'
import ErrorComponent from '@/components/Error'
import LocalisationInput from '@/components/LocalisationInput'
import LocalisationTypeInput from '@/components/LocalisationTypeInput'
import PictureUrlInput from '@/components/PictureUrlInput'

export default {
  name: 'NewSignalementV2',
  data: () => ({
    loading: false,
    error: undefined,
    step: 0,
    form : {
      parentTitleId: undefined,
      name: undefined,
      description: undefined,
      picture: undefined,
      availableAt: undefined,
      localisationType: undefined,
      localisations: [],
    },
    priorities: ['Gênant', 'Très gênant', 'Dangereux'],
  }),
  components: {
    LocalisationInput,
    PictureUrlInput,
    Error: ErrorComponent,
    LocalisationTypeInput,
  },
  watch: {
    step() {
      this.error = undefined
    }
  },
  computed: {
    ...mapState('user', ['localisationTypes', 'signalementTitles', 'config']),
    currentLocalisationType() {
      return this.localisationTypes?.find(v => v.id === this.form.localisationType)
    },
    signalementParentTitles() {
      return this.signalementTitles?.filter(v => !v.parentId) ?? []
    },
    signalementChildTitles() {
      return this.signalementTitles?.filter(v => v.parentId === this.form.parentTitleId) ?? []
    },
  },
  methods: {
    selectParentTitle(id) {
      this.form.parentTitleId = id
      this.step = 1
    },
    selectChildTitle(name) {
      this.form.name = name
      this.step = 2
    },
    checkDescription(event) {
      if (event.target.value.length > 160 && event.keyCode !== 8) {
        event.preventDefault()
      }
    },
    async submit(event) {
      event.preventDefault()
      if (this.loading) return

      switch (this.step) {
      case 0:
        if (!this.form.parentTitleId?.trim()) {
          this.error = new Error('Champs requis manquant.')
          break
        }
        this.step++
        break
      case 1:
        if (!this.form.name?.trim()) {
          this.error = new Error('Champs requis manquant.')
          break
        }
        this.step++
        break
      case 3:
        if (!this.form.localisationType?.length) {
          this.error = new Error('Champs requis manquant.')
          break
        }
        if (!this.form.localisations?.length) {
          this.error = new Error('Champs requis manquant.')
          break
        }
        this.loading = true
        try {
          await signalementService.create({
            name: this.form.name,
            picture: this.form.picture,
            description: this.form.description?.trim(),
            localisations: this.form.localisations,
            localisationTypeId: this.form.localisationType,
          })
          this.$router.go(-1)
          await Toast.show({
            text: 'Signalement bien reçu. Merci pour votre contribution. Il sera traité dans les plus brefs délais.',
            duration: 'long',
          })
        } catch (error) {
          this.error = error
        } finally {
          this.loading = false
        }
        break
      default:
        this.step++
      }
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      FirebaseAnalytics.setScreenName({
        screenName: 'new-signalement',
        nameOverride: 'NewSignalementScreen',
      }).catch(console.error)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.new-signalement {
  position: relative;
  padding-bottom: 72px;
}

.signalement-header {
  font-size: 18px;
  text-align: center;
  margin: 16px 0;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.signalement-example {
  margin: -8px 0 16px;
  text-align: center;
  color: $color-text-muted;
  text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2);
}

.signalement-title-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  grid-auto-rows: 1fr;
}

.signalement-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-red-light;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  color: #FFF;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.02), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.signalement-submit {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
  width: 100%;

  .btn {
    width: 100%;

    &.success {
      border-radius: 8px;
    }
  }
}

.form-field-input {
  border: 1px solid #ddd;
}

.select {
  z-index: 3000;
  display: block;
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 4px;

  select {
    border: none;
    box-shadow: none;
    font-size: $font-family;
    color: #bdbdbd;
    width: 100%;
    height: 36px;
    padding: 0 4px;
    appearance: none;
    background: transparent url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.83294 2.13566C8.72168 2.02432 8.58981 1.96869 8.43743 1.96869H0.562542C0.410101 1.96869 0.278316 2.02432 0.166971 2.13566C0.0556262 2.24713 0 2.37891 0 2.53126C0 2.68358 0.0556262 2.81537 0.166971 2.92674L4.10443 6.8642C4.2159 6.97554 4.34768 7.03129 4.5 7.03129C4.65232 7.03129 4.78423 6.97554 4.89548 6.8642L8.83294 2.92671C8.94416 2.81537 9 2.68358 9 2.53123C9 2.37891 8.94416 2.24713 8.83294 2.13566V2.13566Z' fill='%23CCCCCC'/%3E%3C/svg%3E%0A") no-repeat;
    background-position-x: 98%;
    background-position-y: 12px;

    option {
      color: #000;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.loader {
  opacity: 1;
  margin: 0 8px;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #fff;
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;

  &,
  ::after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
