<template>
  <div class="profile">
    <div class="header">
      <UtilisateurIcon></UtilisateurIcon>
      Mon profil
    </div>
    <div class="profile-form">
      <Error :error="error"></Error>
      <template v-if="user">
        <div class="form-field">
          <div class="form-field-label">
            Prénom
          </div>
          <input :value="user.firstName" class="form-field-input" type="text" disabled>
        </div>

        <div class="form-field">
          <div class="form-field-label">
            Nom
          </div>
          <input :value="user.lastName" class="form-field-input" type="text" disabled>
        </div>

        <div class="form-divider"></div>

        <form @submit="submitChangePassword">
          <div class="form-field">
            <div class="form-field-label">
              Ancien mot de passe
              <span class="required">*</span>
            </div>
            <input v-model="form.oldPassword" class="form-field-input" type="password">
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Nouveau mot de passe
              <span class="required">*</span>
            </div>
            <input v-model="form.newPassword" class="form-field-input" type="password">
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Confirmer le mot de passe
              <span class="required">*</span>
            </div>
            <input v-model="form.confirmPassword" class="form-field-input" type="password">
          </div>

          <div class="form-submission">
            <div class="btn default" @click="$router.go(-1)">
              Annuler
            </div>
            <button type="submit" class="btn primary">
              Changer le mot de passe
            </button>
          </div>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UtilisateurIcon from '@/assets/picto/person.svg'
import ErrorComponent from '@/components/Error'

export default {
  name: 'Profile',
  data: () => ({
    error: undefined,
    form: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  }),
  components: { Error: ErrorComponent, UtilisateurIcon },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    ...mapActions('user', ['fetchUser', 'changePassword']),
    async submitChangePassword(event) {
      event.preventDefault()
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.error = new Error('Mot de passe différent.')
        return
      }
      try {
        await this.changePassword(this.form)
        this.$router.go(-1)
      } catch (error) {
        if (error.isAxiosError && error.response && error.response.data) {
          this.error = new Error(error.response.data.message)
        } else {
          this.error = error
        }
      }
    }
  },
  async mounted() {
    try {
      await this.fetchUser()
    } catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.profile-form {
  margin: 0 auto;
  max-width: 720px;
  padding: 0 16px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
