<template>
  <div class="list-users">
    <div class="container">
      <div class="header">
        <div class="header-title">
          Utilisateurs
        </div>
        <form class="header-search" @submit="onSubmitSearch">
          <input v-model="search" type="text" placeholder="Email, nom, prénom...">
        </form>
      </div>
      <Error :error="error"></Error>
      <table class="users-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Client</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td>{{user.email}}</td>
            <td></td>
            <td>{{user.role}}</td>
            <td>
              <div class="link" @click="loginAsHandler(user)">
                Accéder
              </div>
              <div class="link" @click="resetPasswordHandler(user)">
                Réinitialiser le mot de passe
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Error from '@/components/Error'

export default {
  name: 'ListUsers',
  components: {Error},
  data: () => ({
    error: undefined,
    search: '',
  }),
  watch: {
    '$route.query.query': {
      immediate: true,
      async handler(query) {
        this.error = undefined
        try {
          await this.fetchUsers({ query })
        } catch (error) {
          this.error = error
        }
      },
    },
  },
  computed: mapState('user', ['users']),
  methods: {
    ...mapActions('user', ['fetchUsers', 'loginAs', 'resetPassword']),
    onSubmitSearch(event) {
      event.preventDefault()
      this.$router.push({
        name: 'superadmin-user-list',
        query: {
          query: this.search,
        },
      })
    },
    async loginAsHandler(user) {
      this.error = undefined
      try {
        await this.loginAs({ id: user._id })
        this.$router.push({ name: 'home' })
      } catch (error) {
        this.error = error
      }
    },
    async resetPasswordHandler(user) {
      this.error = undefined
      try {
        if (confirm('Voulez vous vraiment réinitialiser le mot de passe ?')) {
          const generatedPassword = await this.resetPassword({ id: user._id })
          alert(`Nouveau mot de passe : ${generatedPassword}`)
        }
      } catch (error) {
        this.error = error
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.list-users {
  margin-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 16px;

  .header-title {
    font-weight: 600;
    font-size: 24px;
  }

  .header-search {
    input {
      border: 2px solid $color-grey;
      padding: 8px 12px;
      border-radius: 4px;
      box-shadow: none;
      min-width: 300px;

      &:focus {
        outline: none;
        border-color: $color-blue;
      }
    }
  }
}

.users-table {
  width: 100%;

  th {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-transform: uppercase;
    padding: 21px 0;
  }

  td {
    padding: 16px 0;
    font-weight: 500;
    color: #999999;
    font-size: 16px;
  }

  tr {
    border-bottom: 1px solid $color-grey;
  }

  .link {
    font-weight: 400;
  }
}
</style>
