<template>
  <div class="list-companies">
    <div class="container">
      <div class="header">
        Notifications
      </div>
      <Error :error="error"></Error>
      <table class="notifications-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Titre</th>
            <th>Contenu</th>
            <th>Succès</th>
            <th>Echec</th>
            <th>Envoyé le</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="notification in notifications" :key="notification.id">
            <td>{{notification.type}}</td>
            <td>{{notification.title}}</td>
            <td>{{notification.body}}</td>
            <td>{{notification.successCount}}</td>
            <td>{{notification.failureCount}}</td>
            <td>{{notification.createdAt | moment("DD/MM/YYYY HH:mm")}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Error from '@/components/Error'
import {notificationService} from '@/services'

export default {
  name: 'ListNotifications',
  components: {Error},
  data: () => ({
    error: undefined,
    notifications: [],
  }),
  async mounted() {
    try {
      this.notifications = await notificationService.list()
    }  catch (error) {
      this.error = error
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.list-companies {
  margin-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 16px;
  font-weight: 600;
  font-size: 24px;
}

.notifications-table {
  width: 100%;

  th {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-transform: uppercase;
    padding: 21px 0;
  }

  td {
    padding: 16px 0;
    font-weight: 500;
    color: #999999;
    font-size: 16px;
  }

  tr {
    border-bottom: 1px solid $color-grey;
  }

  .link {
    font-weight: 400;
  }
}
</style>
