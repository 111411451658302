<template>
  <div class="reporting">
    <div class="container">
      <div class="go-back" @click="$router.back()">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="title"><ReportingIcon></ReportingIcon> Signalement</div>
          <Error :error="error"></Error>

          <template v-if="report">
            <!-- <div class="warning" v-if="report.status === 6">
              Carte en attente d'approbation. Procéder aux modifications
              souhaitées avant de pouvoir la publier.
            </div> -->

            <div class="report-actions">
              <div
                class="btn primary"
                style="margin-bottom: 16px"
                @click="askIntervention"
                :class="{ disabled: !canUpdate || report.status === 0 }"
              >
                {{
                  report.interventions.length === 0
                    ? "Demander une intervention"
                    : "Relancer le prestataire"
                }}
              </div>
            </div>

            <table class="details">
              <tbody>
                <tr>
                  <th>Titre</th>
                  <td>{{ report.name }}</td>
                </tr>
                <tr>
                  <th>De</th>
                  <td v-if="report.owner">
                    <template
                      v-if="
                        report.owner.role === 'USER' && report.owner.homestay
                      "
                    >
                      UL {{ report.owner.homestay.ul }} ({{
                        report.owner.homestay.bloc.name
                      }})
                    </template>
                    <template v-else>
                      {{ report.owner.firstName }} {{ report.owner.lastName }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>{{ report.createdAt | moment("DD/MM/YYYY") }}</td>
                </tr>
                <tr v-if="report.availableAt">
                  <th>Programmer pour le</th>
                  <td>{{ report.availableAt | moment("DD/MM/YYYY") }}</td>
                </tr>
                <tr v-if="report.automaticClosingAt">
                  <th>Cloture automatique le</th>
                  <td>
                    {{ report.automaticClosingAt | moment("DD/MM/YYYY") }}
                  </td>
                </tr>
                <tr>
                  <th>Lieu</th>
                  <td>
                    <LogementFormatter
                      :setLocations="updateLocations"
                      :locations="report.localisations"
                    ></LogementFormatter>
                  </td>
                </tr>
                <tr>
                  <th>Statut</th>
                  <td>
                    <!-- {{ report.status | }} -->
                    {{ report.status | status_label }}
                    <!-- {{ statusReport[report.status]}} -->

                    <div
                      class="btn default"
                      style="padding: 0"
                      @click="statusModal = true"
                      :class="{ disabled: !canUpdate }"
                    >
                      Mettre à jour le statut
                    </div>
                  </td>
                </tr>
                <tr v-if="report.priority">
                  <th>Priorité</th>
                  <td>
                    {{ report.priority }}
                  </td>
                </tr>
                <tr>
                  <th>Statistiques</th>
                  <td>
                    <div class="metric-line">
                      <div class="metric">
                        UL concernés : {{ report.ulTargeted }}
                      </div>
                      <div class="metric">
                        UL actifs : {{ report.ulActive }}
                      </div>
                    </div>
                    <!-- <div class="metric-line">
                      <div class="metric">UL atteints : {{ report.views }}</div>
                      <div class="metric">
                        Interactions : {{ report.interactions }}
                      </div>
                    </div> -->
                    <div
                      class="btn default"
                      style="padding: 0"
                      @click="downloadStats"
                    >
                      Télécharger les statistiques
                    </div>
                  </td>
                </tr>
                <tr v-if="report.description">
                  <th>Description</th>
                  <td class="description">
                    <RenderReact
                      :component="TextEditorRenderer"
                      :children="report.description"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Pictogramme associé</th>
                  <td v-if="findIcon(report.pictogram)">
                    <component
                      v-bind:is="findIcon(report.pictogram).image"
                    ></component>
                  </td>
                </tr>
                <tr v-if="report.picture">
                  <th>Photo</th>
                  <td>
                    <img :src="report.picture" @click="fileModal = true" />
                    <div v-if="report.hidePicture" class="hide-picture">
                      Photo cachée aux locataires
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="track-status">Suivi des statuts</div>
            <table class="track-status-details" border="1px solid #a2a2a2">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Agent</th>
                  <th>Statut</th>
                  <th>Remarque</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="curr in report.history" :key="curr._id">
                  <td>{{ curr.createdAt | moment("DD/MM/YYYY HH:mm") }}</td>
                  <td>
                    <template v-if="curr.owner">
                      <template
                        v-if="curr.owner.role === 'USER' && curr.owner.homestay"
                      >
                        UL {{ curr.owner.homestay.ul }}
                      </template>
                      <template v-else>
                        {{ curr.owner.firstName }} {{ curr.owner.lastName }}
                      </template>
                      <template
                        v-if="curr.owner.role !== 'OWNER' || curr.owner.office"
                      >
                        ({{
                          curr.owner.role === "OWNER"
                            ? curr.owner.office
                            : curr.owner.role.toLowerCase()
                        }})
                      </template>
                    </template>
                  </td>
                  <td>{{ curr.status | status_label }}</td>
                  <td>{{ curr.comment }}</td>
                </tr>
              </tbody>
            </table>

            <div class="delete-report">
              <div
                class="btn default"
                @click="
                  $router.push({
                    name: 'edit-reporting',
                    params: { id: report.id },
                  })
                "
                :class="{ disabled: !canUpdate }"
              >
                <div>Modifier la fiche signalement</div>
              </div>
            </div>
            <div
              v-if="report.status === 0"
              class="delete-report report-approval-actions"
            >
              <!-- <div
                class="btn primary"
                style="margin-bottom: 16px"
                @click="submitDisplayForUserOnly"
              >
                Rendre visible pour l'auteur
              </div> -->
              <!-- <div
                class="btn primary"
                style="margin-bottom: 16px"
                @click="submitProcessed"
              >
                Traiter en interne
              </div> -->
              <div
                class="btn primary"
                style="margin-bottom: 16px"
                @click="submitPublish"
              >
                Rendre public
              </div>
            </div>
            <div class="delete-report">
              <div
                class="btn default"
                @click="askDelete"
                :class="{ disabled: !canDelete }"
              >
                <DeleteIcon></DeleteIcon>
                <div>Supprimer la fiche signalement</div>
              </div>
            </div>
            <div
              v-if="user.role !== 'user' || user._id === this.report.createdBy"
              style="margin-top: 10%"
            >
              <div class="commentaire-title">Commentaires</div>
              <div>
                <input
                  type="checkbox"
                  v-model="displayComment"
                  v-on:change="onDisplayComment()"
                />
                <span style="margin-left: 3%"
                  >Afficher l’option commentaires</span
                >
              </div>
              <div>
                <input
                  type="checkbox"
                  v-model="commentEnable"
                  v-on:change="onActivateComment()"
                />
                <span style="margin-left: 3%">Activer les commentaires</span>
              </div>
              <div>
                <div
                  v-for="comment in comments"
                  style="
                    display: flex;
                    margin-top: 2%;
                    padding: 5%;
                    align-items: baseline;
                    border-bottom: 1px solid #bdc3c7;
                  "
                >
                  <div>
                    <div class="avatar-cirlce">
                      {{ comment.username.charAt(0).toUpperCase() }}
                    </div>
                  </div>
                  <div style="margin-left: 3%; width: 100%">
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        width: 100%;
                      "
                    >
                      <div style="font-size: 17px; font-weight: bold">
                        {{ comment.username }}&nbsp
                      </div>
                      <div style="font-size: 14px">
                        {{ comment.userUL ? `(${comment.userUL})` : null }}
                      </div>
                      <div
                        style="
                          font-size: 13px;
                          color: #bdc3c7;
                          margin-left: 10px;
                        "
                      >
                        |
                        {{
                          comment.createdDate | moment("DD/MM/YYYY - (HH:mm)")
                        }}
                      </div>
                      <div
                        @click="removeComment(comment._id)"
                        style="margin-left: auto; cursor: pointer"
                      >
                        <i class="ion-ios-trash"></i>
                      </div>
                    </div>
                    <div style="color: #797d7f">
                      {{ comment.comment }}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <!-- <hr style="margin-top: 4%" /> -->
              <div style="margin-top: 3%">
                <textarea
                  v-model="commentaireText"
                  style="
                    border: 1px solid #cacfd2;
                    border-radius: 10px;
                    width: 100%;
                    padding: 2%;
                  "
                  rows="3"
                >
                </textarea>
                <span v-if="no_comment" style="color: red"
                  >Vous ne pouvez pas envoyer un message vide</span
                >

                <div style="justify-content: flex-end; display: flex">
                  <div
                    class="btn primary"
                    style="margin-top: 3%"
                    @click="sendComment"
                  >
                    Envoyer
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else-if="!error">Chargement...</div>
        </div>
      </div>
    </div>
    <Modal v-model="interventionModal">
      <div class="modal">
        <div class="modal-close" @click="interventionModal = false">
          <i class="ion-md-close"></i>
        </div>
        <div class="modal-title">Demande d'intervention</div>
        <form @submit="submitIntervention">
          <Error :error="errorModal"></Error>

          <div class="form-field">
            <div class="form-field-label">
              Corps de métier
              <span class="required">*</span>
            </div>
            <select
              v-model="interventionForm.job"
              class="form-field-input"
              @change="updateDescription"
            >
              <option value="" selected>Sélectionnez</option>
              <option v-for="(job, key) in jobs" :key="key" :value="job">
                {{ job }}
              </option>
            </select>
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Prestataire
              <span class="required">*</span>
            </div>
            <select
              v-model="interventionForm.intervenant"
              class="form-field-input"
              @change="updateDescription"
            >
              <option value="" selected>Sélectionnez</option>
              <option
                v-for="(i, key) in filteredIntervenants"
                :key="key"
                :value="i.id"
              >
                {{ i.name }}
              </option>
            </select>
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Priorité
              <span class="required">*</span>
            </div>
            <select
              v-model="interventionForm.priority"
              class="form-field-input"
              @change="updateDescription"
            >
              <option value="" selected>Sélectionnez</option>
              <option
                v-for="(v, key) in priorities"
                :key="key"
                :value="v.value"
              >
                {{ v.name }}
              </option>
            </select>
          </div>

          <div class="form-field" v-if="report && report.picture">
            <div class="form-field-label">
              Joindre la photo
              <span class="required">*</span>
            </div>
            <input type="checkbox" v-model="interventionForm.joinPicture" />
          </div>

          <div class="form-field">
            <div class="form-field-label">
              Description
              <span class="required">*</span>
            </div>
            <textarea
              v-model="interventionForm.description"
              class="form-field-input"
              rows="6"
            >
            </textarea>
          </div>

          <div class="form-submission">
            <div class="btn default" @click="interventionModal = false">
              Annuler
            </div>
            <button type="submit" class="btn primary">Valider</button>
          </div>
        </form>
      </div>
    </Modal>
    <Modal v-if="report" v-model="fileModal">
      <img
        :src="report.picture"
        class="modal-file"
        @click="fileModal = false"
      />
    </Modal>
    <Modal v-model="statusModal">
      <div class="modal">
        <div class="modal-close" @click="statusModal = false">
          <i class="ion-md-close"></i>
        </div>
        <div class="modal-title">Modifier le statut</div>
        <form @submit="submitStatus">
          <Error :error="errorModal"></Error>

          <div class="form-field">
            <div class="form-field-label">
              Statut
              <span class="required">*</span>
            </div>
            <select
              v-model="statusFormModel"
              :value="statusForm.status"
              @change="onStatusModalChange(statusForm.status, $event)"
              class="form-field-input"
            >
              <option value="" selected>Sélectionnez</option>
              <option value="0">En attente de traitement</option>
              <option value="1">Ajourné</option>
              <option value="2">En cours</option>
              <option value="3">Prévu</option>
              <option value="4">Clôturé</option>
              <!-- <option value="7">Traité en interne</option> -->
              <!-- <option value="8">Visible pour l'auteur</option> -->
            </select>
          </div>

          <div class="form-field">
            <div class="form-field-label">Remarque</div>
            <textarea
              v-model="statusForm.comment"
              class="form-field-input"
              rows="6"
            >
            </textarea>
          </div>

          <div class="form-submission">
            <div class="btn default" @click="statusModal = false">Annuler</div>
            <button type="submit" class="btn primary">Valider</button>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import icons from "@/utils/icons";
import { API_ENDPOINT } from "@/utils/api";
import ReportingIcon from "@/assets/picto/signalement.svg";
import DeleteIcon from "@/assets/picto/supprimer.svg";
import ErrorComponent from "@/components/Error";
import Modal from "@/components/Modal";
import Owner from "@/components/Owner";
import LogementFormatter from "../components/LogementFormatter";
import { signalementService, siteService } from "@/services";
import RenderReact from "@/components/RenderReact";
import TextEditorRenderer from "@/components/TextEditor/TextEditorRenderer";

export default {
  name: "ViewReporting",
  data: () => ({
    TextEditorRenderer,
    error: undefined,
    errorModal: undefined,
    fileModal: false,
    report: undefined,
    statusModal: false,
    interventionModal: false,
    locations: "",
    comments: [],
    commentaireText: "",
    // statusReport: [
    //   "En attente de traitement",
    //   "Ajourné",
    //   "En cours",
    //   "Prévu",
    //   "Clôturé",
    //   // "Traité en interne",
    // ],
    statusForm: {
      status: "",
      comment: "",
    },
    statusFormModel: "",
    commentEnable: null,
    displayComment: null,
    interventionForm: {
      job: "",
      intervenant: "",
      priority: "",
      description: "",
      joinPicture: false,
    },
    priorities: [
      {
        name: "Normale",
        value: "normal",
      },
      {
        name: "Haute",
        value: "high",
      },
    ],
    no_comment: false,
  }),
  components: {
    RenderReact,
    LogementFormatter,
    Owner,
    Modal,
    Error: ErrorComponent,
    ReportingIcon,
    DeleteIcon,
  },
  watch: {
    statusModal() {
      this.errorModal = undefined;
      this.statusForm = {
        status: "",
        comment: "",
      };
    },
    interventionModal() {
      this.errorModal = undefined;
      this.interventionForm = {
        job: "",
        intervenant: "",
        priority: "",
        description: "",
        joinPicture: false,
      };
    },
  },
  computed: {
    ...mapState("user", [
      "sites",
      "blocs",
      "buildings",
      "homestays",
      "user",
      "config",
    ]),
    filteredIntervenants() {
      return this.interventionForm.job
        ? this.report.intervenants.filter(
            ({ job }) => job === this.interventionForm.job
          )
        : [];
    },
    jobs() {
      return this.report
        ? this.report.intervenants
            .map(({ job }) => job)
            .filter((v, i, r) => !r.slice(0, i).includes(v))
        : [];
    },
    canUpdate() {
      return this.config?.metadata?.permissions?.signalement?.update !== false;
    },
    canDelete() {
      return this.config?.metadata?.permissions?.signalement?.delete !== false;
    },
  },
  methods: {
    async askIntervention() {
      if (this.report.interventions.length === 0) {
        this.interventionModal = true;
      } else {
        this.report = await signalementService.reviveInterventionRequest({
          id: this.report.id,
        });
      }
    },
    onStatusModalChange(status, e) {
      if (e.target.value == 1 || e.target.value == 2 || e.target.value == 3) {
        if (
          confirm("Êtes-vous sûr de vouloir rendre public le signalement ?") ==
          true
        ) {
          this.statusFormModel = e.target.value;
          this.statusForm.status = e.target.value;
        } else {
          this.statusFormModel = status;
        }
      } else {
        this.statusFormModel = e.target.value;
        this.statusForm.status = e.target.value;
      }
    },
    async onDisplayComment() {
      try {
        this.report = await signalementService.update({
          id: this.$route.params.id,
          displayCommentEnable: this.displayComment,
        });
      } catch (error) {
        this.error = error;
      }
    },
    async onActivateComment() {
      try {
        this.report = await signalementService.update({
          id: this.$route.params.id,
          commentEnable: this.commentEnable,
        });
      } catch (error) {
        this.error = error;
      }
    },
    updateLocations(data) {
      this.locations = data;
    },
    updateDescription() {
      const siteIds = this.report.localisations
        .filter((v) => v.type === "site")
        .map((v) => v.id);
      const buildingIds = this.report.localisations
        .filter((v) => v.type === "building")
        .map((v) => v.id);

      const blocIds = this.report.localisations
        .filter((v) => v.type === "bloc")
        .map((v) => v.id);

      const homestayIds = this.report.localisations
        .filter((v) => v.type === "homestay")
        .map((v) => v.id);

      // console.log("my map", ...mapState("user", ["config"]));
      // const locations = [
      //   ...(siteIds.length
      //     ? this.sites
      //         .filter((v) => siteIds.includes(v._id))
      //         .map((v) => v.address)
      //     : []),
      //   ...(buildingIds.length
      //     ? this.buildings
      //         .filter((v) => buildingIds.includes(v._id))
      //         .map((v) => v.address)
      //     : []),
      //   ...(blocIds.length
      //     ? this.blocs
      //         .filter((v) => blocIds.includes(v._id))
      //         .map((v) => v.address)
      //     : []),
      //   ...(homestayIds.length
      //     ? this.homestays
      //         .filter((v) => homestayIds.includes(v._id))
      //         .map((v) => v.ul)
      //     : []),
      // ];
      const locations = [];

      const intervenant = this.interventionForm.intervenant
        ? this.report.intervenants.find(
            ({ id }) => id === this.interventionForm.intervenant
          )
        : undefined;

      this.interventionForm.description = `Bonjour,
L'entreprise sollicite une intervention dans le cadre du contrat d’entretien
Motif : ${this.report.name}
Corps de métier : ${this.interventionForm.job}
Contact sur site : ${
        this.user ? `${this.user.firstName} ${this.user.lastName}` : "Inconnu"
      }
Fonction : ${this.user.office ? this.user.office : "Inconnu"}
Lieu : ${this.locations}
Priorité : ${
        this.interventionForm.priority
          ? this.priorities.find(
              ({ value }) => value === this.interventionForm.priority
            ).name
          : "Inconnu"
      }
Description : ${this.report.description || ""}
Echéance : ${
        intervenant && intervenant.delay != null
          ? moment().add(intervenant.delay, "h").format("DD/MM/YYYY")
          : "Inconnu"
      }
Bonne réception`;
    },
    findIcon(name) {
      return icons.find(({ value }) => value === name);
    },
    async askDelete() {
      if (confirm("Voulez-vous vraiment supprimer le signalement ?")) {
        try {
          await signalementService.destroy({ id: this.$route.params.id });
          this.$router.back();
        } catch (error) {
          this.error = error;
        }
      }
    },
    async submitDisplayForUserOnly() {
      try {
        this.report = await signalementService.updateStatus({
          id: this.$route.params.id,
          status: 8,
          comment: "--",
        });
      } catch (error) {
        this.error = error;
      }
    },
    async submitProcessed() {
      try {
        this.report = await signalementService.updateStatus({
          id: this.$route.params.id,
          status: 7,
          comment: "--",
        });
      } catch (error) {
        this.error = error;
      }
    },
    async submitPublish() {
      try {
        if (
          confirm("Êtes-vous sûr de vouloir rendre public le signalement ?") ==
          true
        ) {
          this.report = await signalementService.updateStatus({
            id: this.$route.params.id,
            status: 2,
            comment: "--",
          });
        }
      } catch (error) {
        this.error = error;
      }
    },
    async sendComment() {
      try {
        if (!(this.commentaireText.trim().length === 0)) {
          const usernameToDisplay = "L'équipe chantier";
          this.comments = await signalementService.addComment({
            signalementId: this.report.id,
            userId: this.user._id,
            comment: this.commentaireText,
            username: usernameToDisplay,
            userUL: "",
          });
          this.commentaireText = "";
          this.no_comment = false;
        } else {
          this.no_comment = true;
        }
      } catch (error) {
        this.error = error;
      }
    },
    async removeComment(commentId) {
      if (confirm("Voulez-vous vraiment supprimer ce commentaire ?")) {
        this.comments = await signalementService.removeComment({
          signalementId: this.report.id,
          commentId: commentId,
        });
      }
    },
    async submitIntervention(event) {
      event.preventDefault();
      if (
        !this.interventionForm.job ||
        !this.interventionForm.intervenant ||
        !this.interventionForm.description ||
        !this.interventionForm.priority
      ) {
        this.errorModal = new Error("Champs requis manquant.");
        return;
      }
      try {
        this.report = await signalementService.requestIntervention({
          id: this.report.id,
          intervenantId: this.interventionForm.intervenant,
          message: this.interventionForm.description,
          priority: this.interventionForm.priority,
          joinPicture: this.interventionForm.joinPicture,
        });
        this.interventionModal = false;
      } catch (error) {
        this.errorModal = error;
      }
    },
    async submitStatus(event) {
      event.preventDefault();
      if (this.status === "") {
        this.errorModal = new Error("Vous devez selectionner un status.");
        return;
      }
      try {
        this.report = await signalementService.updateStatus({
          id: this.$route.params.id,
          status: parseInt(this.statusForm.status, 10),
          comment: this.statusForm.comment,
        });
        this.statusModal = false;
      } catch (error) {
        this.errorModal = error;
      }
    },
    async downloadStats() {
      window.open(
        `${API_ENDPOINT}signalement/${this.report.id}/stats.csv`,
        "_blank"
      );
    },
  },
  async mounted() {
    try {
      // alert("Êtes-vous sûr de vouloir publier la carte ?");
      this.report = await signalementService.get({ id: this.$route.params.id });
      this.comments = this.report.comments;
      this.commentEnable = this.report.commentEnable;
      this.displayComment = this.report.displayCommentEnable;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.commentaire-title {
  font-size: 24px;
  font-weight: 600;
}

.avatar-cirlce {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  line-height: 25px;
  text-align: center;
  background: #cccccc;
  display: inline-block;
}

.reporting {
  margin-bottom: 48px;
}

.title {
  font-size: 24px;
  color: $color-reporting;
  font-weight: 600;
  margin: 32px 0;

  svg {
    height: 42px;
    margin-right: 12px;
    fill: $color-reporting;
  }
}

.hide-picture {
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
}

.details th,
.track-status {
  color: #a2a2a2;
  font-weight: 500;
  font-size: 16px;
}

.metric-line {
  display: flex;
  flex-direction: row;
  align-items: center;

  .metric {
    margin-right: 16px;
  }
}

.track-status {
  margin: 16px 0;
}

.details {
  th {
    padding: 16px 16px 16px 0;
  }

  td {
    font-size: 16px;
    font-weight: 600;

    svg {
      height: 64px;
    }

    img {
      height: 128px;
    }

    &.description {
      font-weight: 400;
    }
  }
}

.track-status-details {
  width: 100%;

  th {
    background: #4c4c4c;
    color: #a2a2a2;
    font-weight: 500;
    padding: 8px;
  }

  td {
    padding: 8px;
  }
}

.delete-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 16px 0;

  .btn {
    &,
    div {
      display: flex;
      flex-direction: row;
      align-self: center;
    }

    svg {
      height: 32px;
      margin-right: 8px;
      fill: $color-blue-light;
    }
  }
}

.modal-file {
  margin: 72px;
  width: 100%;
  max-width: calc(100vw - 144px);
  height: auto;
  max-height: calc(100vh - 144px);
}

.report-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.report-approval-actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .report-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .metric-line {
    flex-direction: column;
    align-items: flex-start;
  }

  .details {
    td,
    th {
      padding-top: 0;
      vertical-align: top;
    }
  }
}

.warning {
  background: #fbd38d;
  color: #ed8936;
  padding: 16px;
  border-radius: 4px 8px;
  margin-bottom: 24px;
}
</style>
