<template>
  <div v-if="error" class="error">
    {{message}}
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: ['error'],
  computed: {
    message() {
      const message = this.error.message || this.error
      if (message === 'Network Error') {
        return 'Connexion au serveur Neybs impossible.\nMerci de réessayer plus tard.'
      }
      return message
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.error {
  padding: 16px;
  background: $color-red;
  border-radius: 0.25rem;
  color: $color-white;
  margin-bottom: 16px;
}
</style>
