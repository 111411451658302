<template>
  <div class="root" ref="root">
    <div class="switch-user" @click="$router.push('/user/home')">
      Toucher pour passer en vue utilisateur
    </div>
    <div class="side-nav" :class="{ 'side-nav-collapsed': toggleNav }">
      <div class="side-nav-header">
        <img class="side-nav-logo" src="../../assets/images/logo.png" title="Neybs" alt="Neybs"
             @click="sidebarLink({ name: 'admin-home' })"/>
        <div class="button" @click="toggleNav = !toggleNav">
          <i class="ion-ios-menu"></i>
        </div>
      </div>
      <div class="side-nav-content">
        <SideNavSection title="Gestion">
          <SideNavLink :to="{ name: 'list-reporting' }" :disabled="config && !config.features.includes('signalement')"
            v-model="toggleNav">
            <ReportIcon></ReportIcon>
            Signalement
          </SideNavLink>
          <SideNavLink :to="{ name: 'list-concertation' }" :disabled="config && !config.features.includes('concertation')"
            v-model="toggleNav">
            <ConsultationIcon></ConsultationIcon>
            Concertation
          </SideNavLink>
          <SideNavLink :to="{ name: 'list-satisfaction' }" :disabled="config && !config.features.includes('concertation')"
            v-model="toggleNav">
            <SatisfactionIcon></SatisfactionIcon>
            Satisfaction
          </SideNavLink>
          <SideNavLink :to="{ name: 'list-work' }" :disabled="config && !config.features.includes('travaux')"
            v-model="toggleNav">
            <WorkIcon></WorkIcon>
            Travaux
          </SideNavLink>
          <SideNavLink :to="{ name: 'list-intervention' }" :disabled="config && !config.features.includes('intervention')"
            v-model="toggleNav">
            <InterventionIcon></InterventionIcon>
            Intervention - Prise de RDV
          </SideNavLink>
          <SideNavLink :to="{ name: 'list-tribune' }" :disabled="config && !config.features.includes('tribune')"
            v-model="toggleNav">
            <TribuneIcon></TribuneIcon>
            Tribune
          </SideNavLink>
        </SideNavSection>
        <SideNavSection title="Administration">
          <SideNavLink :to="{ name: 'statistics-superowner' }" :disabled="!config || !['admin', 'superowner'].includes(config.type)"
            v-model="toggleNav">
            <StatistiquesIcon></StatistiquesIcon>
            Statistiques
          </SideNavLink>
          <SideNavLink :to="{ name: 'permissions-superowner' }" :disabled="!config || !['admin', 'superowner'].includes(config.type)"
            v-model="toggleNav">
            <AuthIcon></AuthIcon>
            Roles et autorisations
          </SideNavLink>
          <SideNavLink :to="{ name: 'admin-list-localisation' }" :disabled="!config || !['admin'].includes(config.type)"
            v-model="toggleNav">
            <ImmeubleIcon></ImmeubleIcon>
            Localisation
          </SideNavLink>
          <SideNavLink :to="{ name: 'admin-config' }" :disabled="!config || !['admin'].includes(config.type)"
                       v-model="toggleNav">
            <ParametresIcon></ParametresIcon>
            Configuration
          </SideNavLink>
          <SideNavLink :to="{ name: 'admin-list-tutorial' }" :disabled="!config || !['admin'].includes(config.type)"
                       v-model="toggleNav">
            <TutorialIcon></TutorialIcon>
            Tutoriels
          </SideNavLink>
        </SideNavSection>
        <SideNavSection title="Mon compte">
          <SideNavLink :to="{ name: 'profile' }" v-model="toggleNav">
            <UtilisateurIcon></UtilisateurIcon>
            Mon profil
          </SideNavLink>
<!--          <SideNavLink :to="{ name: 'profile' }" :disabled="true" v-model="toggleNav">-->
<!--            <MessagingIcon></MessagingIcon>-->
<!--            Messagerie-->
<!--          </SideNavLink>-->
          <a
            v-if="!config.hideDocumentation" class="side-nav-link" target="_blank" rel="noreferrer noopener"
            href="https://drive.google.com/file/d/1-KPVOFt41G268_4ZjtoC1UcNZA3nlDk_/view"
          >
            <HelpIcon></HelpIcon>
            Documentation
          </a>
          <SideNavLink @click="logoutWrapper" v-model="toggleNav">
            <DeconnexionIcon></DeconnexionIcon>
            Déconnexion
          </SideNavLink>
        </SideNavSection>
      </div>
    </div>
    <div class="content">
      <router-link :to="{ name: 'admin-config', query: { tab: 'billing' } }" v-if="freeTrialUntil" class="free-trial">
        Il reste {{freeTrialUntil}} jour{{freeTrialUntil > 1 ? "s" : ""}} avant la fin de votre période d'essai.
        <u>Cliquez ici pour mettre à jour votre mode de paiement</u>
      </router-link>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import moment from 'moment'
import ReportIcon from '@/assets/picto/signalement.svg'
import ConsultationIcon from '@/assets/picto/concertation.svg'
import SatisfactionIcon from '@/assets/picto/satisfaction.svg'
import WorkIcon from '@/assets/picto/travaux.svg'
import ImmeubleIcon from '@/assets/picto/immeuble.svg'
import InterventionIcon from '@/assets/picto/interventions.svg'
import TribuneIcon from '@/assets/picto/tribune.svg'
import MessagingIcon from '@/assets/picto/messagerie.svg'
import UtilisateurIcon from '@/assets/picto/person.svg'
import DeconnexionIcon from '@/assets/picto/deconnexion.svg'
import StatistiquesIcon from '@/assets/picto/analytics.svg'
import ParametresIcon from '@/assets/picto/parametres.svg'
import TutorialIcon from '@/assets/picto/tutosvideos.svg'
import HelpIcon from '@/assets/picto/infos.svg'
import AuthIcon from '@/assets/picto/auth.svg'
import SideNavSection from '@/components/SideNavSection'
import SideNavLink from '@/components/SideNavLink'

export default {
  name: 'AdminLayout',
  components: {
    SideNavLink,
    SideNavSection,
    ReportIcon,
    ImmeubleIcon,
    ConsultationIcon,
    SatisfactionIcon,
    WorkIcon,
    InterventionIcon,
    ParametresIcon,
    TutorialIcon,
    TribuneIcon,
    UtilisateurIcon,
    MessagingIcon,
    AuthIcon,
    StatistiquesIcon,
    DeconnexionIcon,
    HelpIcon,
  },
  data: () => ({
    toggleNav: false,
    hammer: undefined,
  }),
  computed: {
    ...mapState('user', ['user', 'config']),
    freeTrialUntil() {
      return this.config.freeTrialUntil ? moment(this.config.freeTrialUntil).diff(moment(), 'days') : null
    },
  },
  methods: {
    ...mapMutations('user', ['setToken']),
    ...mapActions('user', ['fetchUser', 'fetchConfig']),
    ...mapActions('localisation', ['fetchLocalisations']),
    logoutWrapper() {
      this.setToken(undefined)
      this.$router.push({ name: 'login' })
    },
    sidebarLink(data) {
      this.$router.push(data)
      this.toggleNav = false
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.fetchUser(),
        this.fetchConfig(),
        this.fetchLocalisations(),
      ])
    } catch (e) {
      console.error(e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables';

.root {
  display: flex;
  flex-direction: row;
}

.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100vh;
  max-height: 100vh;
  background: $color-blue;
  overflow-y: auto;
  z-index: 9000;
}

.side-nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 12px;

  & > img {
    height: 64px;
  }

  .avatar {
    cursor: pointer;
    font-weight: 500;
    color: $color-white;

    img {
      height: 48px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  .button {
    display: none;
    color: $color-white;
    font-size: 36px;
  }
}

.side-nav-content {
  .side-nav-section:last-child {
    border-bottom: none;
  }
}

.side-nav-logo {
  display: flex;
  align-self: center;
}

.side-nav-link-list {
  list-style: none;
  margin-bottom: 24px;
  padding: 0;

  &.bottom {
    border-top: 1px solid $color-grey-dark;
    padding-top: 16px;
  }

  a,
  & > div {
    display: flex;
    flex-direction: row;
    align-self: center;
    text-decoration: none;
    margin-bottom: 8px;
    padding: 8px 0 8px 28px;
    font-size: 18px;
    line-height: 24px;
    color: $color-white;
    cursor: pointer;

    svg {
      height: 32px;
      padding-right: 8px;
      fill: #fff;
    }

    div {
      display: flex;
      align-items: center;
    }

    &:hover {
      background: $color-blue-light;
    }

    &.disabled {
      opacity: 0.4;
      user-select: none;
      pointer-events: none;
      cursor: default;

      &:hover {
        background: none;
      }
    }
  }
}

.badge {
  background: $color-red;
  padding: 0 7px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;
  margin-left: 8px;
}

.content {
  display: block;
  width: 100%;
  margin-left: 300px;
}

.switch-user {
  display: none;
}

@media screen and (max-width: $breakpoint-mobile) {
  .root {
    flex-direction: column;
    padding-top: 118px;
  }

  .content {
    margin-left: 0;
  }

  .side-nav {
    width: 100%;
    height: 70px;
    top: calc(48px + env(safe-area-inset-top));

    &.side-nav-collapsed {
      height: calc(100vh - env(safe-area-inset-top) - 48px);
      max-height: calc(100vh - env(safe-area-inset-top) - 48px);
    }
  }

  .side-nav-header {
    padding: 8px 24px;

    img {
      height: 48px;
    }

    & > .avatar {
      display: none;
    }

    & > .button {
      display: block;
    }
  }

  .side-nav-content {
    display: none;
    background: $color-blue;
    top: calc(118px + env(safe-area-inset-top));
    width: 100%;
    z-index: 9999;
    overflow-y: auto;
  }

  .side-nav-collapsed .side-nav-content {
    display: block;
  }

  .switch-user {
    display: block;
    position: fixed;
    z-index: 1000000;
    top: env(safe-area-inset-top);
    left: 0;
    width: 100%;
    padding: 12px 24px;
    color: #fff;
    background: $color-green-light;
  }
}

.free-trial {
  display: block;
  background: $color-blue-light;
  padding: 16px 32px;
  font-weight: 600;
  color: #fff;

  &:hover {
    text-decoration: none;
  }
}

.side-nav-link {
  color: $color-white;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color .4s ease-out;
  font-weight: 400;
  font-size: 16px;
  line-height: 1rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: darken($color-blue, 1);
  }

  svg {
    height: 24px;
    fill: $color-white;
    margin-right: 8px;
  }

  &.disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
    opacity: .75;
  }
}
</style>
