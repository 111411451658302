<template>
  <div class="concertation">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="ConcertationForm"
            title="Ajouter une concertation"
            :config="config"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConcertationForm from '@/components/ConcertationForm'
import {concertationService} from '@/services'
import RenderReact from '@/components/RenderReact'
import {mapState} from 'vuex'

export default {
  name: 'NewConcertation',
  components: {RenderReact},
  data: () => ({ ConcertationForm }),
  computed: mapState('user', ['config']),
  methods: {
    async onSubmit(values) {
      await concertationService.create(values)
      this.$router.push({ name: 'list-concertation' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.concertation {
  margin-bottom: 48px;
}

.go-back {
  margin-bottom: 24px;
}
</style>
