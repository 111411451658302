import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Input} from 'antd'
import styles from '@/assets/styles/ConcertationFormAnswer.module.scss'
import PictogramInput from '@/components/PictogramInput'
import PictureInput from '@/components/PictureInput'

function ConcertationFormQuestionOption({ field, remove }) {
  return (
    <div className={styles.root}>
      <Form.Item
        name={[field.name, 'name']}
        fieldKey={[field.fieldKey, 'name']}
        label="Réponse"
        rules={[{ required: true, message: 'Réponse requise.' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={[field.name, 'pictogram']}
        fieldKey={[field.fieldKey, 'pictogram']}
        label="Pictogramme"
      >
        <PictogramInput />
      </Form.Item>

      <Form.Item
        name={[field.name, 'picture']}
        fieldKey={[field.fieldKey, 'picture']}
        label="Photo"
      >
        <PictureInput />
      </Form.Item>

      <Form.Item>
        <Button type="danger" onClick={() => remove(field.name)}>
          Supprimer
        </Button>
      </Form.Item>
    </div>
  )
}

ConcertationFormQuestionOption.propTypes = {
  field: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
}

export default ConcertationFormQuestionOption
