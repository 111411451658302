<template>
  <span>{{name}}</span>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Owner',
  props: ['id'],
  data: () => ({
    name: '',
  }),
  computed: mapState('user', ['owners']),
  methods: mapActions('user', ['fetchOwners']),
  async mounted() {
    const r = await this.fetchOwners()
    const find = r.find(({ _id }) => _id === this.id)
    if (find) {
      this.name = `${find.firstName} ${find.lastName}`
    }
  }
}
</script>
