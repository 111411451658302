import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Form, Select, Input, Button, Checkbox, Typography} from 'antd'
import styles from '@/assets/styles/ConcertationFormQuestion.module.scss'
import ConcertationFormQuestionOption from '@/components/ConcertationFormQuestionOption'

const { Title } = Typography

function ConcertationFormQuestion({ field, canRemove, remove, config }) {
  const selectRef = useRef(null)
  const [type, setType] = useState(false)

  useEffect(() => {
    onChange(selectRef.current?.props?.value)
  }, [selectRef])

  function onChange(type) {
    setType(type)
    return type
  }

  function optionsValidator(_, value) {
    if (!value || value.length < 2) {
      return Promise.reject('Vous devez au moins ajouter 2 réponses.')
    }
    return Promise.resolve()
  }

  return (
    <div className={styles.root}>
      <Form.Item
        name={[field.name, 'type']}
        fieldKey={[field.fieldKey, 'type']}
        label="Type"
        rules={[{ required: true, message: 'Type requis.' }]}
      >
        <Select placeholder="Sélectionner" onChange={onChange} ref={selectRef}>
          <Select.Option value="SIMPLE_CHOICE">Choix Simple</Select.Option>
          <Select.Option value="MULTIPLE_CHOICE">Choix Multiple</Select.Option>
          <Select.Option value="PHONE_NUMBER" disabled={config && !config.features.includes('concertation-full')}>
            Numéro de téléphone
          </Select.Option>
          <Select.Option value="YES_NO" disabled={config && !config.features.includes('concertation-full')}>
            Oui / Non
          </Select.Option>
          <Select.Option value="TEXT" disabled={config && !config.features.includes('concertation-full')}>
            Champs texte
          </Select.Option>
          <Select.Option value="FILE" disabled={config && !config.features.includes('concertation-full')}>
            Piece jointe
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name={[field.name, 'name']}
        fieldKey={[field.fieldKey, 'name']}
        label="Question"
        rules={[{ required: true, message: 'Question requise.' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={[field.name, 'instructions']}
        fieldKey={[field.fieldKey, 'instructions']}
        label="Consigne"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={[field.name, 'mandatory']}
        fieldKey={[field.fieldKey, 'mandatory']}
        valuePropName="checked"
      >
        <Checkbox>
          Obligatoire
        </Checkbox>
      </Form.Item>

      {type === 'FILE' && (
        <Form.Item
          name={[field.name, 'multiple']}
          fieldKey={[field.fieldKey, 'multiple']}
          valuePropName="checked"
        >
          <Checkbox>
            Multiple
          </Checkbox>
        </Form.Item>
      )}

      {['SIMPLE_CHOICE', 'MULTIPLE_CHOICE'].includes(type) && (
        <Form.List
          name={[field.name, 'options']}
          style={{ marginBottom: 24 }}
          rules={[{ validator: optionsValidator }]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <Title level={5} className={styles.answerListTitle}>
                Réponses
              </Title>

              {fields.map(field => (
                <ConcertationFormQuestionOption
                  key={field.key}
                  field={field}
                  remove={remove}
                />
              ))}

              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Ajouter une réponse
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      )}

      {canRemove && (
        <Form.Item>
          <Button type="danger" onClick={() => remove(field.name)}>
            Supprimer
          </Button>
        </Form.Item>
      )}
    </div>
  )
}

ConcertationFormQuestion.propTypes = {
  field: PropTypes.object.isRequired,
  canRemove: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  config: PropTypes.object,
}

export default ConcertationFormQuestion
