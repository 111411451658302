import React, {useMemo, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Input, Select} from 'antd'

function ReportTypeForm({ parentReportTypes, reportType,  onSubmit }) {
  const [form] = Form.useForm()
  const initialValues = useMemo(() => reportType ?? { parentId: null }, [reportType])

  useEffect(() => {
    form.resetFields()
  }, [form, reportType])

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Requis.' }]}>
        <Input />
      </Form.Item>

      <Form.Item name="parentId" label="Parent">
        <Select>
          <Select.Option value={null}>
            Aucun
          </Select.Option>
          {parentReportTypes.map(type => (
            <Select.Option key={type.id} value={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          Valider
        </Button>
      </Form.Item>
    </Form>
  )
}

ReportTypeForm.propTypes = {
  parentReportTypes: PropTypes.array,
  reportType: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default ReportTypeForm
