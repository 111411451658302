import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Button, Input, Upload} from 'antd'
import {Toast} from '@capacitor/toast'
import {concertationService} from '@/services'
import styles from '@/assets/styles/ConcertationAnswer.module.scss'
import store from '@/store'
import Pictogram from '@/components/Pictogram'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import {client} from '@/utils/api'
import {UploadOutlined} from '@ant-design/icons'

function QuestionInput({question, value, onChange}) {
  switch (question.type) {
  case 'PHONE_NUMBER':
  case 'TEXT':
    return (
      <div>
        <Input
          placeholder={question.question}
          size="large"
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      </div>
    )
  case 'SIMPLE_CHOICE':
  case 'MULTIPLE_CHOICE':
    return question.options.map(v => {
      const selected = question.type === 'SIMPLE_CHOICE'
        ? value === v.id
        : value?.includes(v.id)

      function toggle() {
        if (question.type === 'SIMPLE_CHOICE') {
          onChange(v.id)
        } else {
          if (selected) {
            onChange(value?.filter(c => c !== v.id) ?? [])
          } else {
            onChange([...(value ?? []), v.id])
          }
        }
      }

      return (
        <div
          key={v.id}
          className={classnames(styles.answer, {[styles.answerSelected]: selected})}
          onClick={toggle}
        >
          <div className={styles.answerHeader}>
            <div>
              <Pictogram name={v.pictogram}/>
              {v.name}
            </div>
            {selected ? (
              <Pictogram name="valider"/>
            ) : <div style={{ width: 48, height: 1 }} />}
          </div>
          {v.picture && (
            <img
              src={v.picture}
              className={styles.answerPicture}
            />
          )}
        </div>
      )
    })
  case 'YES_NO':
    return (
      <>
        <div
          className={classnames(styles.answer, {[styles.answerSelected]: value === true})}
          onClick={() => onChange(true)}
        >
          <div className={styles.answerHeader}>
            <div className={styles.answerYes}>
              <Pictogram name="satisfaction" style={{fill: 'green'}}/>
              Oui
            </div>
            {value === true && (
              <Pictogram name="valider"/>
            )}
          </div>
        </div>
        <div
          className={classnames(styles.answer, {[styles.answerSelected]: value === false})}
          onClick={() => onChange(false)}
        >
          <div className={styles.answerHeader}>
            <div className={styles.answerNo}>
              <Pictogram name="satisfaction"/>
              Non
            </div>
            {value === false && (
              <Pictogram name="valider"/>
            )}
          </div>
        </div>
      </>
    )
  case 'FILE':
    return (
      <Upload
        listType="picture"
        className={styles.answerFile}
        fileList={value || []}
        customRequest={async ({file, onError, onProgress, onSuccess}) => {
          try {
            const form = new FormData()
            form.append('file', file)
            const res = await client.post('/files/upload', form, {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: onProgress,
            })
            onSuccess(res.data)
          } catch (error) {
            onError(error)
          }
        }}
        onChange={(info) => {
          if (['done', 'removed'].includes(info.file.status)) {
            onChange(info.fileList)
          }
        }}
      >
        {(!value?.length || question.multiple) && (
          <Button icon={<UploadOutlined />}>
            Ajouter une pièce jointe
          </Button>
        )}
      </Upload>
    )
  }
  return null
}

QuestionInput.propTypes = {
  question: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
}

function ConcertationAnswer({id, routerBack}) {
  const [concertation, setConcertation] = useState(null)
  const [questionIndex, setQuestionIndex] = useState(0)
  const [values, setValues] = useState({})
  const [error, setError] = useState(null)
  const [finish, setFinish] = useState(false)
  const question = useMemo(
    () => concertation?.questions?.[questionIndex - 1] ?? null,
    [concertation, questionIndex],
  )

  useEffect(() => {
    concertationService.get({id})
      .then((concertation) => {
        if (!concertation.welcomeMessage) {
          setQuestionIndex(1)
        }
        setConcertation(concertation)
      })
      .catch(() => {
        Toast.show({
          text: 'Impossible de charger la concertation, merci de réessayer plus tard.',
          duration: 'long',
        })
        routerBack()
      })
  }, [id])

  useEffect(() => setError(null), [questionIndex])

  function onChange(value) {
    setValues({
      ...values,
      [question.id]: value,
    })
  }

  function validate() {
    if (questionIndex === 0) {
      return true
    }

    const value = values[question.id]
    switch (question.type) {
    case 'PHONE_NUMBER':
      if (question.mandatory && !value?.trim()) {
        setError('Réponse requise')
        return false
      } else if (value?.trim() && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)) {
        setError('Numéro de téléphone invalide')
        return false
      }
      break
    case 'TEXT':
      if (question.mandatory && !value?.trim()) {
        setError('Réponse requise')
        return false
      }
      break
    case 'SIMPLE_CHOICE':
      if (question.mandatory && !value) {
        setError('Réponse requise')
        return false
      }
      break
    case 'MULTIPLE_CHOICE':
      if (question.mandatory && !value?.length) {
        setError('Réponse requise')
        return false
      }
      break
    case 'YES_NO':
      if (question.mandatory && ![true, false].includes(value)) {
        setError('Réponse requise')
        return false
      }
      break
    case 'FILE':
      if (question.mandatory && !value?.length) {
        setError('Pièce jointe requise')
        return false
      }
      break
    }
    setError(null)
    return true
  }

  function onSubmit() {
    const payload = {
      id,
      answers: Object.fromEntries(
        Object.entries(values).map(([questionId, value]) => {
          const question = concertation.questions.find(v => v.id === questionId)
          if (question.type !== 'FILE') {
            return [questionId, value]
          }
          return [questionId, value.map(v => v.response.url)]
        }),
      ),
    }
    concertationService.answer(payload)
      .then(async () => {
        await store.commit('concertation/markAsAnswered', { id })
        setFinish(true)
      })
      .catch(() => Toast.alert({
        text: 'Impossible de soumettre le formulaire. Merci de réessayer plus tard.',
        duration: 'long',
      }))
  }

  if (!concertation) {
    return null
  }

  function renderBody() {
    if (questionIndex === 0 || finish) {
      return (
        <>
          {!finish && (
            <div className={styles.title}>
              {concertation.name}
            </div>
          )}
          <div className={styles.description}>
            <TextEditorRenderer>
              {finish ? concertation.thankyouMessage : concertation.welcomeMessage}
            </TextEditorRenderer>
          </div>
        </>
      )
    }

    return (
      <>
        <div className={styles.title}>
          {question.name}
        </div>
        {question.instructions && (
          <div className={styles.description}>
            {question.instructions}
          </div>
        )}
        <QuestionInput
          question={question}
          value={values[question.id]}
          onChange={onChange}
        />
        {error && (
          <div className={styles.error}>
            {error}
          </div>
        )}
      </>
    )
  }

  return (
    <div className={styles.root}>
      {renderBody()}
      <div className={styles.formNavigation}>
        {finish ? (
          <>
            <div />
            <div className={styles.formNavigationOption} onClick={() => routerBack()}>
              Voir toutes les concertations
              <i className="ion-ios-arrow-forward"/>
            </div>
          </>
        ) : (
          <>
            <div className={styles.progress}>
              <div
                className={styles.progressBar}
                style={{ width: `${(Math.max(questionIndex, 0) / concertation.questions.length) * 100}%` }}
              />
            </div>
            {questionIndex > 0 ? (
              <div className={styles.formNavigationOption} onClick={() => setQuestionIndex(questionIndex - 1)}>
                <i className="ion-ios-arrow-back"/>
                Précédent
              </div>
            ) : <div/>}
            <div className={styles.progressText}>
              {questionIndex} / {concertation.questions.length}
            </div>
            {questionIndex < concertation.questions.length ? (
              <div
                className={styles.formNavigationOption}
                onClick={() => validate() && setQuestionIndex(questionIndex + 1)}
              >
                {questionIndex === 0 ? 'Commencer' : 'Suivant'}
                <i className="ion-ios-arrow-forward"/>
              </div>
            ) : (
              <div className={styles.formNavigationOption} onClick={() => validate() && onSubmit()}>
                Valider
                <i className="ion-ios-arrow-forward"/>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

ConcertationAnswer.propTypes = {
  id: PropTypes.string.isRequired,
  routerBack: PropTypes.func,
}

export default ConcertationAnswer
