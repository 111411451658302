import axios from 'axios'
import store from '@/store'

export const API_ENDPOINT = process.env.VUE_APP_API_URL || 'http://localhost:3000/'

export const client = axios.create({
  baseURL: API_ENDPOINT,
})

client.interceptors.request.use(config => {
  const { token } = store.state.user
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
