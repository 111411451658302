<template>
  <div v-if="value" class="modal-overlay" @click="onClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['value'],
  methods: {
    onClick(event) {
      if (event.srcElement.className === 'modal-overlay') {
        this.$emit('input', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.modal-overlay {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
