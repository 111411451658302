<template>
  <div class="element" :class="{ reverse: report.owner && report.owner.role === 'USER' }" @click="view">
    <div v-if="!report.hasRead" class="badge">
      <div class="badge-white"></div>
    </div>
    <div class="element-left" :class="{ 'with-user': report.owner && report.owner.role === 'USER' }">
      <div class="icon">
        <ReportingIcon></ReportingIcon>
      </div>
      <div class="description">
        <div class="title">
          {{report.name}} {{report.owner && report.owner.role === 'USER' ? "(Signalement locataire)" : ""}}
        </div>
        <div class="times">
          <i class="ion-ios-refresh"></i> Créé le {{formatCreatedAt(report.createdAt)}}
        </div>
        <div class="where">
          <i class="ion-ios-business"></i>
          <LogementFormatter :locations="report.localisations"></LogementFormatter>
        </div>
        <div class="description">
          <RenderReact
            :component="TextEditorRenderer"
            :strip="true"
            :children="report.description"
          />
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="metric-item">
        <div class="item">
          <component v-if="report.pictogram && findIcon(report.pictogram)" v-bind:is="findIcon(report.pictogram).image"></component>
        </div>
      </div>
      <div class="metric-item">
        <!-- <div class="metric">Interaction : {{report.interactions}}</div> -->
          <div class="metric">Atteint : {{report.views}}</div>
        <div v-if="report.status !== undefined" class="status">
          <StatusIcon :status="report.status"></StatusIcon>
          <div class="text">
            {{report.status | status_label}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ReportingIcon from '@/assets/picto/signalement.svg'
import StatusIcon from '@/components/StatusIcon'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import icons from '@/utils/icons'
import LogementFormatter from './LogementFormatter'
import RenderReact from '@/components/RenderReact'

export default {
  name: 'ReportingCard',
  props: ['report'],
  data: () => ({ TextEditorRenderer }),
  components: {
    RenderReact,
    LogementFormatter,
    ReportingIcon,
    StatusIcon,
  },
  methods: {
    view() {
      this.$router.push({ name: 'view-reporting', params: { id: this.report.id } })
    },
    findIcon(name) {
      return icons.find(({ value }) => value === name)
    },
    formatCreatedAt(date) {
      return `${moment(date).format('DD/MM/YYYY')} à ${moment(date).format('HH')}h${moment(date).format('MM')}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.element {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  border: 4px solid $color-reporting;
  margin-bottom: 16px;
  cursor: pointer;

  &,
  .element-left {
    display: flex;
    flex-direction: row;

    &.with-user {
      .icon {
        margin-left: 8px;
        background: #fff;

        svg {
          fill: $color-reporting;
        }
      }
    }
  }

  .badge {
    position: absolute;
    height: 32px;
    width: 32px;
    background: $color-red;
    right: -10px;
    top: -10px;
    border-radius: 50%;
  }

  .badge-white {
    position: absolute;
    height: 16px;
    width: 16px;
    background: $color-white;
    right: 8px;
    top: 8px;
    border-radius: 50%;
  }

  .icon {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: $color-reporting;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    svg {
      height: 42px;
      fill: $color-white;
    }
  }

  .description {
    padding: 0 8px 8px;

    .title {
      color: $color-reporting;
      font-size: 23px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .times,
    .where {
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    min-width: fit-content;
    margin-right: 16px;

    .metric-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .metric {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 14px;
      }
    }

    svg {
      height: 62px;
      margin: 0 24px;
      align-self: center;
    }

    img {
      height: 80px;
    }

    .status {
      align-self: center;
      margin: 0 auto 8px;
      font-weight: 500;
      text-align: center;
    }
  }

  &.reverse {
    .icon {
      background: transparent;

      svg {
        fill: $color-reporting;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .element {
    padding: 8px;

    .icon {
      display: none;
    }

    .actions {
      display: none;
    }
  }
}
</style>
