<template>
  <div class="element" @click="view">
    <div class="element-left">
      <div class="icon">
        <SatisfactionIcon></SatisfactionIcon>
      </div>
      <div class="description">
        <div class="title">{{ satisfaction.name }}</div>
        <div class="times">
          <i class="ion-ios-calendar"></i> Du
          {{ satisfaction.startAt | moment("DD/MM/YYYY") }} au
          {{ satisfaction.endAt | moment("DD/MM/YYYY") }} &nbsp;|&nbsp;
          <i class="ion-ios-refresh"></i>
          {{ satisfaction.createdAt | moment("from", "now") }}
        </div>
        <div class="where">
          <i class="ion-ios-business"></i>
          <LogementFormatter
            :locations="satisfaction.localisations"
          ></LogementFormatter>
        </div>
        <div class="votes">
          <div class="toward">
            <i class="ion-md-thumbs-up"></i>
            <span> {{ toward.percentage }} % </span>
          </div>
          <div class="against">
            <i class="ion-md-thumbs-down"></i>
            <span> {{ against.percentage }} % </span>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="metric-list">
        <div class="metric">Atteint : {{ satisfaction.views }}</div>
        <!-- <div class="metric">Interaction : {{satisfaction.interactions}}</div> -->
      </div>
      <component
        v-if="satisfaction.pictogram"
        v-bind:is="icon.image"
      ></component>
    </div>
  </div>
</template>

<script>
import SatisfactionIcon from "@/assets/picto/satisfaction.svg";
import StatusIcon from "@/components/StatusIcon";
import icons from "@/utils/icons";
import LogementFormatter from "@/components/LogementFormatter";

export default {
  name: "SatisfactionCard",
  props: ["satisfaction"],
  components: {
    LogementFormatter,
    SatisfactionIcon,
    StatusIcon,
  },
  computed: {
    icon() {
      return icons.find(({ value }) => value === this.satisfaction.pictogram);
    },
    toward() {
      return this.satisfaction?.options.find((v) => v.type === "TOWARD");
    },
    against() {
      return this.satisfaction?.options.find((v) => v.type === "AGAINST");
    },
  },
  methods: {
    view() {
      this.$router.push({
        name: "view-satisfaction",
        params: { id: this.satisfaction.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.element {
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  border: 4px solid $color-satisfaction;
  margin-bottom: 16px;
  width: 100%;
  cursor: pointer;

  &,
  .element-left {
    display: flex;
    flex-direction: row;
  }

  .icon {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: $color-satisfaction;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    svg {
      height: 42px;
      fill: $color-white;
    }
  }

  .description {
    padding: 0 8px 8px;

    .title {
      color: $color-satisfaction;
      font-size: 23px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .times,
    .where {
      font-weight: 600;
      margin-bottom: 8px;
    }

    .votes {
      &,
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      i {
        font-size: 32px;
        margin-right: 8px;
      }

      span {
        margin-right: 16px;
        font-size: 16px;
        font-weight: 500;
      }

      .toward {
        i {
          color: $color-green;
        }
      }

      .against {
        i {
          color: $color-red;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    min-width: fit-content;

    .metric-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .metric {
        margin: 0 16px 16px 0;
        font-weight: 500;
        font-size: 14px;
      }
    }

    svg {
      height: 62px;
      margin: 0 48px;
      align-self: center;
    }

    img {
      height: 164px;
    }

    .status {
      align-self: center;
      margin: 0 32px;
      font-weight: 500;
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .element {
    padding: 8px;

    .icon {
      display: none;
    }

    .actions {
      display: none;
    }
  }
}
</style>
