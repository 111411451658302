<template>
  <div>
    <div class="login-banner" @click="forgetPassword">
      Pour récupérer votre code : cliquez ici
    </div>
    <div class="first-login">
      <img src="../assets/images/logo.png" alt="Neybs" title="Neybs">
      <form v-if="token" class="form" @submit="submit">
        <div class="title">Première connexion</div>
        <Error :error="error"></Error>
        <div class="form-group">
          <label>Email</label>
          <input v-model="email" type="email" placeholder="Email">
        </div>
        <div class="form-group">
          <label>Mot de passe</label>
          <input v-model="password" type="password" placeholder="Mot de passe">
        </div>
        <div class="form-group">
          <label>Confirmer mot de passe</label>
          <input v-model="confirm" type="password" placeholder="Confirmer mot de passe">
        </div>
        <div class="form-actions">
          <router-link :to="{ name: 'login' }">
            Annuler
          </router-link>
          <button type="submit">
            Valider
          </button>
        </div>
      </form>
      <form v-else class="form" @submit="submit">
        <div class="title">Première connexion</div>
        <Error :error="error"></Error>
        <div class="form-group">
          <label>Code</label>
          <input v-model="code" type="text" placeholder="Code d'authentification">
        </div>
        <div class="form-advice">
          Merci de renseigner le code d'authentification remis par votre bailleur.
        </div>
        <div class="form-actions">
          <router-link :to="{ name: 'login' }">
            Annuler
          </router-link>
          <button type="submit">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ErrorComponent from '@/components/Error'

export default {
  name: 'FirstLogin',
  data: () => ({
    token: '',
    code: '',
    email: '',
    password: '',
    confirm: '',
    error: undefined,
  }),
  components: {
    Error: ErrorComponent
  },
  methods: {
    ...mapActions('user', ['createUser', 'firstLoginToken']),
    forgetPassword() {
      window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAASzTtntUODU2QlhFRzZSSVJIWUJBUkpWTjdLVDZZNS4u', '_blank')
    },
    async submit(event) {
      event.preventDefault()
      this.error = undefined
      try {
        if (this.token) {
          if (this.password !== this.confirm) {
            throw new Error('Le mot de passe ne correspond pas.')
          }
          const res = await this.createUser({
            token: this.token,
            email: this.email,
            password: this.password,
          })
          this.$router.push({
            name: res.type === 'user'
              ? 'user-home'
              : 'admin-home'
          })
        } else {
          this.token = await this.firstLoginToken({ code: this.code })
        }
      } catch (error) {
        if (error.isAxiosError && error.response && error.response.data && error.response.data.status === 401) {
          this.error = new Error(this.token
            ? "Nom d'utilisateur ou mot de passe incorrect."
            : "Code d'authentification incorrect."
          )
        } else if (error.isAxiosError && error.response && error.response.data && error.response.data.status === 400) {
          this.error = new Error(error.response.data.message)
        } else {
          this.error = error
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.login-banner {
  display: none;
  background: $color-blue-light;
  color: #fff;
  padding: 16px;
  font-size: 16px;
}

.first-login {
  max-width: 400px;
  margin: 64px auto 32px;

  img {
    display: block;
    width: 96px;
    margin: 0 auto 32px;
  }

  form {
    padding: 16px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.36);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);

    .title {
      text-transform: uppercase;
      color: $color-white;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .form-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      label {
        min-width: 100px;
        max-width: 100px;
        margin-right: 16px;
        display: flex;
        color: $color-white;
        font-weight: 500;
      }

      input {
        border: 2px solid #d4d4d4;
        box-shadow: none;
        border-radius: 4px;
        width: 100%;
        padding: 6px 12px;
      }
    }

    .form-advice {
      font-weight: 500;
      margin-bottom: 16px;
      color: #fff;
    }

    a, button {
      display: block;
      background: #d4d4d4;
      border: none;
      padding: 6px 12px;
      border-radius: 4px;

      &:hover {
        background: $color-blue-light;
      }
    }
  }
}

.form-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  a {
    color: #000;
  }

  button {
    color: #fff;
    background: $color-blue-light !important;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .login-banner {
    display: block;
  }

  .form-actions {
    justify-content: space-between;
  }
}
</style>

<style>
.login-bg {
  background: url(../assets/images/background.png) no-repeat center fixed;
  -webkit-background-size: cover;
  background-size: cover;
}
</style>
