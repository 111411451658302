<template>
  <span>
    {{ formattedLocations }}
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LogementFormatter",
  props: ["locations", "setLocations"],
  computed: {
    ...mapState("localisation", ["localisations"]),
    formattedLocations() {
      if (!this.localisations) {
        return "Chargement...";
      }
      const data = this.locations
        ?.map((loc) => {
          const found = this.localisations.find(
            (v) => v.type === loc.type && v.id === loc.id
          );
          return found?.name?.toUpperCase() ?? "Inconnu";
        })
        .join("; ");
      if (this.setLocations) this.setLocations(data);
      return data;
    },
  },
};
</script>

<style scoped></style>
