<template>
  <div class="reporting">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="ReportForm"
            title="Ajouter un signalement"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'
import ReportForm from '@/components/ReportForm'
import RenderReact from '@/components/RenderReact'
import {signalementService} from '@/services'

export default {
  name: 'NewReporting',
  components: {RenderReact},
  data: () => ({ ReportForm }),
  methods: {
    async onSubmit(values) {
      await signalementService.create(values)
      this.$router.push({ name: 'list-reporting' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.reporting {
  margin-bottom: 48px;
}
</style>
