import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from '@/assets/styles/InterventionCard.module.scss'
import LocationFormatter from '@/components/LocationFormatter'
import Pictogram from '@/components/Pictogram'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'


function InterventionCard({ intervention, routerPush }) {
	const [isDashed, setIsDashed] = useState(false)
	function view() {
		routerPush({
			name: 'view-intervention',
			params: {
				id: intervention.id,
			},
		})
	}

	useEffect(() => {
		const today = new Date;
		if (new Date(intervention.availableAt).getTime() > today.getTime()) {
			setIsDashed(true)
		}
	}, [intervention])

	return (
		<div className={styles.root} style={{ borderStyle: isDashed ? "dashed" : "solid" }} onClick={view}>
			<div className={styles.left}>
				<div className={styles.icon}>
					<Pictogram name="interventions" />
				</div>
				<div className={styles.description}>
					<div className={styles.titl}>{intervention.name}</div>
					<div className={styles.times}>
						<i className="ion-ios-calendar" />&nbsp;
						{moment(intervention.createdAt).format('DD/MM/YYYY')}&nbsp;
						{/* au {moment(intervention.endAt).format('DD/MM/YYYY')}  */}
						&nbsp;|&nbsp;
						<i className="ion-ios-refresh" />&nbsp;
						{moment(intervention.createdAt).fromNow()}
					</div>
					<div className={styles.where}>
						<i className="ion-ios-business" />&nbsp;
						<LocationFormatter locations={intervention.localisations} />
					</div>
					<div className={styles.description}>
						<TextEditorRenderer strip>
							{intervention.description}
						</TextEditorRenderer>
					</div>
				</div>
			</div>
			<div className={styles.actions}>
				<div className={styles.metricList}>
					<div className={styles.metric} style={{ display: "flex", width: '70px' }}>Atteint : {intervention.views}</div>
					{/* <div className={styles.metric}>Interaction : {intervention.interactions}</div> */}
				</div>
				<Pictogram name={intervention.pictogram} />
			</div>
		</div>
	)
}

InterventionCard.propTypes = {
	intervention: PropTypes.object.isRequired,
	routerPush: PropTypes.func.isRequired,
}

export default InterventionCard
