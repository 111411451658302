import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from '@/assets/styles/ConcertationCard.module.scss'
import LocationFormatter from '@/components/LocationFormatter'
import Pictogram from '@/components/Pictogram'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'

function ConcertationCard({ concertation, routerPush }) {
  const [isDashed, setIsDashed] = useState(false)

  function view() {
    routerPush({
      name: 'view-concertation',
      params: {
        id: concertation.id,
      },
    })
  }

  useEffect(() => {
    const today = new Date;
    if (new Date(concertation.availableAt).getTime() > today.getTime()) {
      setIsDashed(true)
    }
  }, [concertation])

  return (
    <div className={styles.root} style={{ borderStyle: isDashed ? "dashed" : "solid" }} onClick={view}>
      <div className={styles.left}>
        <div className={styles.icon}>
          <Pictogram name="concertation" />
        </div>
        <div className={styles.description}>
          <div className={styles.title}>{concertation.name}</div>
          <div className={styles.leftAndRight}>
            <div className={styles.times}>
              <i className="ion-ios-calendar" />&nbsp;
              Du {moment(concertation.startAt).format('DD/MM/YYYY')}
              au {moment(concertation.endAt).format('DD/MM/YYYY')} &nbsp;|&nbsp;
              <i className="ion-ios-refresh" />&nbsp;
              {moment(concertation.createdAt).fromNow()}
            </div>
            <div className={styles.metricList}>
              <div className={styles.metric}>Atteint : {concertation.views}</div>
              {/* <div className={styles.metric}>Interaction : {concertation.interactions}</div> */}
            </div>
          </div>
          <div className={styles.where}>
            <i className="ion-ios-business" />&nbsp;
            <LocationFormatter locations={concertation.localisations} />
          </div>
          <div className={styles.description}>
            <TextEditorRenderer strip>
              {concertation.description}
            </TextEditorRenderer>
          </div>
        </div>
      </div>
    </div>
  )
}

ConcertationCard.propTypes = {
  concertation: PropTypes.object.isRequired,
  routerPush: PropTypes.func.isRequired,
}

export default ConcertationCard
