import React, {useState, useEffect} from 'react'
import {Button, Form, Input, Popover} from 'antd'
import {LinkOutlined} from '@ant-design/icons'
import {EditorState, RichUtils} from 'draft-js'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './TextEditor.module.scss'

function TextEditorLinkForm({ editorState, setEditorState }) {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [deletable, setDeletable] = useState(false)
  const selection = editorState.getSelection()

  useEffect(() => {
    if (!visible) {
      setDeletable(false)

      if (form) {
        form.resetFields()
      }
    }
  }, [visible, form])

  function promptLink(event) {
    event.preventDefault()
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

      if (linkKey) {
        const linkInstance = editorState.getCurrentContent().getEntity(linkKey)
        form.setFieldsValue(linkInstance.getData())
        setDeletable(true)
      }
    }
  }

  function confirmLink(values) {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', values)
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    })

    setEditorState(RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey,
    ))
    setVisible(false)
  }

  function removeLink() {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null))
      setVisible(false)
    }
  }

  const popoverContent = (
    <Form form={form} onFinish={confirmLink} layout="horizontal">
      <Form.Item name="url" rules={[{ required: true, message: 'Requis.' }]}>
        <Input placeholder="URL" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Valider
        </Button>
        {deletable && (
          <Button type="danger" htmlType="button" style={{ margin: '0 8px' }} onClick={removeLink}>
            Supprimer
          </Button>
        )}
      </Form.Item>
    </Form>
  )

  return (
    <Popover
      title="Ajouter un lien"
      content={popoverContent}
      style={{ width: 1000 }}
      trigger="click"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <button
        type="button" onMouseDown={promptLink}
        className={clsx(styles.toolbarItem, {
          [styles.toolbarItemActive]: visible,
          [styles.toolbarItemDisabled]: selection.isCollapsed(),
        })}
      >
        <LinkOutlined />
      </button>
    </Popover>
  )
}

TextEditorLinkForm.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
}

export default TextEditorLinkForm
