import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import md from '@/utils/remarkable'
import styles from './TextEditorRenderer.module.css'

const htmlElements = {
  paragraph: ({ children }) => <p>{children}</p>,
  text: ({ content }) => content,
  softbreak: () => <br />,
  hardbreak: () => <br />,
  strong: ({ children }) => <strong>{children}</strong>,
  del: ({ children }) => <del>{children}</del>,
  em: ({ children }) => <em>{children}</em>,
  link: ({ children, title, href }) => {
    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(href)) {
      // if it's an email
      href = `mailto:${href}`
    } else if (!/^.+:\/\//.test(href)) {
      // if it's a link without protocol fallback to http
      href = `http://${href}`
    }
    return (
      <a href={href} title={title} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  },
  bullet_list: ({ children }) => <ul>{children}</ul>,
  ordered_list: ({ children }) => <ul>{children}</ul>,
  list_item: ({ children }) => <li>{children}</li>,
}

const strippedElements = {
  paragraph: ({ children }) => children,
  text: ({ content }) => content,
  softbreak: () => ' ',
  hardbreak: () => ' ',
  strong: ({ children }) => children,
  del: ({ children }) => children,
  em: ({ children }) => children,
  link: ({ children }) => children,
  bullet_list: ({ children }) => children,
  ordered_list: ({ children }) => children,
  list_item: ({ children }) => children,
}

function processItems({ strip, data, key }) {
  const elements = strip ? strippedElements : htmlElements
  let children = []

  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    key++

    if (item.type.endsWith('_open')) {
      const [, name] = /(.+)_open/g.exec(item.type)
      const close = data.slice(i + 1).find(v => v.type === `${name}_close`)
      if (!close) {
        continue
      }

      const Component = elements[name]
      if (Component) {
        children = [
          ...children,
          <Component key={key} {...item}>
            {processItems({
              data: data.slice(i + 1, data.indexOf(close)),
              strip,
              key,
            })}
          </Component>,
        ]
      }
      i = data.indexOf(close)
    } else if (item.type === 'inline') {
      children = [
        ...children,
        ...processItems({
          data: item.children,
          strip,
          key,
        }),
      ]
    } else {
      const Component = elements[item.type]
      if (Component) {
        children = [
          ...children,
          <Component key={key} {...item} />
        ]
      }
    }
  }
  return children
}

function TextEditorRenderer({ children, strip, className }) {
  const items = useMemo(() => {
    const data = md.parse(children, {})
    return processItems({ strip, data, key: 0 })
  }, [children])

  return (
    <div className={clsx(styles.root, className)}>
      {items}
    </div>
  )
}

TextEditorRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  strip: PropTypes.bool,
}

export default TextEditorRenderer
