import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/contacts.graphql'
import GET_QUERY from '@/queries/contact.graphql'
import CREATE_MUTATION from '@/queries/createContact.graphql'
import UPDATE_MUTATION from '@/queries/updateContact.graphql'
import DELETE_MUTATION from '@/queries/deleteContact.graphql'
import SORT_MUTATION from '@/queries/sortContacts.graphql'

export async function list() {
  const res = await client.query({
    query: LIST_QUERY,
  })
  return res.data.contacts
}

export async function get({id}) {
  const res = await client.query({
    query: GET_QUERY,
    variables: {id},
  })
  return res.data.contact
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createContact
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {input},
  })
  return res.data.updateContact
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteContact
}

export async function sort({contactIds}) {
  const res = await client.mutate({
    mutation: SORT_MUTATION,
    variables: {contactIds},
  })
  return res.data.sortContacts
}
