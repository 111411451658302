import Vue from "vue";
import Router from "vue-router";
import AdminLayout from "./views/admin/Layout";
import ListReporting from "./views/ListReporting";
import ViewReporting from "./views/ViewReporting";
import ListWork from "./views/ListWork";
import ListIntervention from "./views/ListIntervention";
import NewIntervention from "./views/NewIntervention";
import NewWork from "./views/NewWork";
import NewReporting from "./views/NewReporting";
import NewTribune from "./views/NewTribune";
import Login from "./views/Login";
import FirstLogin from "./views/FirstLogin";
import ListTribune from "@/views/ListTribune";
import ListSatisfaction from "@/views/ListSatisfaction";
import store from "./store";
import AdminHome from "@/views/AdminHome";
import NewSatisfaction from "@/views/NewSatisfaction";
import ListConcertation from "@/views/ListConcertation";
import NewConcertation from "@/views/NewConcertation";
import ViewConcertation from "@/views/admin/Concertation/Concertation";
import Config from "@/views/admin/Config/Config";
import ViewSatisfaction from "@/views/ViewSatisfaction";
import ViewWork from "@/views/ViewWork";
// import ViewIntervention from '@/views/ViewIntervention'
import ViewIntervention from "@/views/admin/Intervention/Intervention";
import ViewTribune from "@/views/ViewTribune";
import EditReporting from "@/views/EditReporting";
import EditConcertation from "@/views/NewEditConcertation";
import EditSatisfaction from "@/views/EditSatisfaction";
import EditWork from "@/views/EditWork";
import EditIntervention from "@/views/EditIntervention";
import EditTribune from "@/views/EditTribune";
import Profile from "./views/Profile";
import SuperOwnerHome from "./views/admin/SuperOwnerHome";
import SuperOwnerStatistics from "./views/admin/SuperOwnerStatistics";
import SuperOwnerPermissions from "./views/admin/SuperOwnerPermissions";
import AdminCreateUser from "./views/admin/CreateUser";
import UserLayout from "./views/user/Layout";
import ConcertationAnswer from "./views/user/ConcertationAnswer.vue";
import UserListTribunes from "./views/user/ListTribunes";
import UserContacts from "./views/user/Contacts";
import UserListSignalements from "./views/user/ListSignalements";
import UserListTravaux from "./views/user/ListTravaux";
import UserListInterventions from "./views/user/ListInterventions";
import UserListVotes from "./views/user/ListVotes";
import UserTutorial from "./views/user/Tutorial";
import UserProfile from "./views/user/Profile";
import UserNewSignalement from "./views/user/NewSignalement";
import AdminListLocalisation from "./views/admin/ListLocalisation";
import AdminCreateLocalisation from "./views/admin/CreateLocalisation";
import AdminListTutorial from "./views/admin/ListTutorial";
import AdminAddTutorial from "./views/admin/AddTutorial";
import AdminUpdateTutorial from "./views/admin/UpdateTutorial";
import SuperAdminLayout from "./views/superadmin/Layout";
import SuperAdminHome from "./views/superadmin/Home";
import SuperAdminListCompanies from "./views/superadmin/ListCompanies";
import SuperAdminCreateCompany from "./views/superadmin/CreateCompany";
import SuperAdminEditCompany from "./views/superadmin/EditCompany";
import SuperAdminListUsers from "./views/superadmin/ListUsers";
import SuperAdminListNotifications from "./views/superadmin/ListNotifications";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/superadmin/",
      component: SuperAdminLayout,
      meta: {
        superAdminOnly: true,
      },
      children: [
        {
          name: "superadmin-home",
          path: "home",
          component: SuperAdminHome,
        },
        {
          name: "superadmin-company-list",
          path: "companies",
          component: SuperAdminListCompanies,
        },
        {
          name: "superadmin-company-new",
          path: "companies/new",
          component: SuperAdminCreateCompany,
        },
        {
          name: "superadmin-company-edit",
          path: "companies/:id/edit",
          component: SuperAdminEditCompany,
        },
        {
          name: "superadmin-user-list",
          path: "users",
          component: SuperAdminListUsers,
        },
        {
          name: "superadmin-notification-list",
          path: "notifications",
          component: SuperAdminListNotifications,
        },
      ],
    },
    {
      path: "/admin/",
      component: AdminLayout,
      meta: {
        adminOnly: true,
      },
      children: [
        {
          name: "admin-home",
          path: "home",
          component: AdminHome,
        },
        {
          name: "statistics-superowner",
          path: "superowner/statistics",
          component: SuperOwnerStatistics,
        },
        {
          name: "permissions-superowner",
          path: "superowner/permissions",
          component: SuperOwnerPermissions,
        },
        {
          name: "admin-create-user",
          path: "create-user",
          component: AdminCreateUser,
        },
        {
          name: "admin-list-localisation",
          path: "admin/localisations",
          component: AdminListLocalisation,
        },
        {
          name: "admin-create-localisation",
          path: "admin/localisations/new",
          component: AdminCreateLocalisation,
        },
        {
          name: "admin-list-tutorial",
          path: "admin/tutorials",
          component: AdminListTutorial,
        },
        {
          name: "admin-add-tutorial",
          path: "admin/tutorials/new",
          component: AdminAddTutorial,
        },
        {
          name: "admin-update-tutorial",
          path: "admin/tutorials/:id",
          component: AdminUpdateTutorial,
        },
        {
          name: "home-superowner",
          path: "superowner",
          component: SuperOwnerHome,
        },
        {
          name: "list-reporting",
          path: "reporting",
          component: ListReporting,
        },
        {
          name: "profile",
          path: "profile",
          component: Profile,
        },
        {
          name: "new-reporting",
          path: "reporting/new",
          component: NewReporting,
        },
        {
          name: "view-reporting",
          path: "reporting/:id",
          component: ViewReporting,
        },
        {
          name: "edit-reporting",
          path: "reporting/:id/edit",
          component: EditReporting,
        },
        {
          name: "list-work",
          path: "work",
          component: ListWork,
        },
        {
          name: "new-work",
          path: "work/new",
          component: NewWork,
        },
        {
          name: "view-work",
          path: "work/:id",
          component: ViewWork,
        },
        {
          name: "edit-work",
          path: "work/:id/edit",
          component: EditWork,
        },
        {
          name: "list-tribune",
          path: "tribune",
          component: ListTribune,
        },
        {
          name: "new-tribune",
          path: "tribune/new",
          component: NewTribune,
        },
        {
          name: "view-tribune",
          path: "tribune/:id",
          component: ViewTribune,
        },
        {
          name: "edit-tribune",
          path: "tribune/:id/edit",
          component: EditTribune,
        },
        {
          name: "list-intervention",
          path: "intervention",
          component: ListIntervention,
        },
        {
          name: "new-intervention",
          path: "intervention/new",
          component: NewIntervention,
        },
        {
          name: "view-intervention",
          path: "intervention/:id",
          component: ViewIntervention,
        },
        {
          name: "edit-intervention",
          path: "intervention/:id/edit",
          component: EditIntervention,
        },
        {
          name: "list-satisfaction",
          path: "satisfaction",
          component: ListSatisfaction,
        },
        {
          name: "new-satisfaction",
          path: "satisfaction/new",
          component: NewSatisfaction,
        },
        {
          name: "view-satisfaction",
          path: "satisfaction/:id",
          component: ViewSatisfaction,
        },
        {
          name: "edit-satisfaction",
          path: "satisfaction/:id/edit",
          component: EditSatisfaction,
        },
        {
          name: "list-concertation",
          path: "concertation",
          component: ListConcertation,
        },
        {
          name: "new-concertation",
          path: "concertation/new",
          component: NewConcertation,
        },
        {
          name: "view-concertation",
          path: "concertation/:id",
          component: ViewConcertation,
        },
        {
          name: "edit-concertation",
          path: "concertation/:id/edit",
          component: EditConcertation,
        },
        {
          name: "admin-config",
          path: "config",
          component: Config,
        },
      ],
    },
    {
      path: "/user/",
      component: UserLayout,
      children: [
        {
          name: "user-home",
          path: "home",
          component: UserListTribunes,
        },
        {
          name: "user-signalements-list",
          path: "signalements",
          component: UserListSignalements,
        },
        {
          name: "user-signalements-new",
          path: "signalements/new",
          component: UserNewSignalement,
        },
        {
          name: "user-travaux-list",
          path: "travaux",
          component: UserListTravaux,
        },
        {
          name: "user-interventions-list",
          path: "interventions",
          component: UserListInterventions,
        },
        {
          name: "user-votes-list",
          path: "votes",
          component: UserListVotes,
        },
        {
          name: "user-contacts",
          path: "contacts",
          component: UserContacts,
        },
        {
          name: "user-tutorial",
          path: "tutorial",
          component: UserTutorial,
        },
        {
          name: "user-profile",
          path: "profile",
          component: UserProfile,
        },
        {
          name: "user-concertation-answer",
          path: "concertation/:id/answer",
          component: ConcertationAnswer,
        },
      ],
    },
    {
      name: "login",
      path: "/login",
      component: Login,
      meta: {
        bodyClasses: ["login-bg"],
      },
    },
    {
      name: "first-login",
      path: "/first-login",
      component: FirstLogin,
      meta: {
        bodyClasses: ["login-bg"],
      },
    },
    {
      name: "home",
      path: "/",
      redirect: () => ({
        name: getUserHomeName(),
      }),
    },
    {
      path: "*",
      redirect: () => ({
        name: getUserHomeName(),
      }),
    },
  ],
});

function getUserHomeName() {
  const userType = store.state.user.userType;
  const userView = store.state.user.userView;
  if (userType === "user" || userView) {
    return "user-home";
  }
  if (userType === "superadmin") {
    return "superadmin-home";
  }
  return "admin-home";
}

router.beforeEach((to, from, next) => {
  document.body.className = document.body.className
    .split(" ")
    .filter(
      (className) =>
        !(
          from.meta.bodyClasses && from.meta.bodyClasses.length
            ? from.meta.bodyClasses
            : []
        ).includes(className)
    )
    .join(" ");
  if (!["login", "first-login"].includes(to.name) && !store.state.user.token) {
    return next({ name: "login" });
  } else if (to.name === "login" && store.state.user.token) {
    return next({ name: getUserHomeName() });
  }
  if (to.meta.bodyClasses && to.meta.bodyClasses.length) {
    document.body.className = [
      ...document.body.className.split(" "),
      ...to.meta.bodyClasses,
    ].join(" ");
  }
  return next();
});

function patchRouterMethod(router, methodName) {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function (location) {
    return router["old" + methodName](location).catch((error) => {
      if (error.name === "NavigationDuplicated") {
        return this.currentRoute;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

export default router;
