<template>
  <div ref="container"/>
</template>

<script>
import React from 'react'
import ReactDOM from 'react-dom'

export default {
  name: 'RenderReact',
  props: ['component'],
  watch: {
    '$props.component'() {
      this.mountComponent()
    },
    '$attrs'() {
      this.mountComponent()
    },
    '$listeners'() {
      this.mountComponent()
    },
  },
  methods: {
    mountComponent() {
      const props = {
        ...this.$attrs,
        ...this.$listeners,
        // children: undefined,
      }
      const children = this.$slots?.default ?? []
      ReactDOM.render(
        React.createElement(
          this.$props.component,
          props,
          ...children.map(child => this.formatChild(child)),
        ),
        this.$refs.container,
      )
    },
    formatChild(child) {
      if (!child.tag && child.text) {
        return child.text
      }

      const props = {
        ...child.$attrs,
        ...child.$listeners,
        children: undefined,
      }
      return React.createElement(
        child.tag,
        props,
        ...child.children.map(child => this.formatChild(child)),
      )
    },
  },
  mounted() {
    this.mountComponent()
  }
}
</script>
