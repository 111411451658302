import React, {useMemo, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Input} from 'antd'
import LocationInput from '@/components/LocationInput'
import PictureInput from '@/components/PictureInput'

function ContactLinkForm({ link, onSubmit }) {
  const [form] = Form.useForm()
  const initialValues = useMemo(() => link, [link])

  useEffect(() => {
    form.resetFields()
  }, [form, link])

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name="name" label="Titre" rules={[{ required: true, message: 'Requis.' }]}>
        <Input />
      </Form.Item>

      <Form.Item name="href" label="Lien" rules={[{ required: true, message: 'Requis.' }]}>
        <Input />
      </Form.Item>

      <Form.Item name="localisations" label="Localisation" rules={[{ required: true, message: 'Requis.' }]}>
        <LocationInput />
      </Form.Item>

      <Form.Item name="image" label="Image" rules={[{ required: true, message: 'Requis.' }]}>
        <PictureInput />
      </Form.Item>

      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          Valider
        </Button>
      </Form.Item>
    </Form>
  )
}

ContactLinkForm.propTypes = {
  link: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default ContactLinkForm
