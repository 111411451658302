import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Modal, notification } from 'antd'
import { interventionService } from '@/services'
import useVueState from '@/hooks/useVueState'
import icons from '@/utils/icons'


import Pictogram from '@/components/Pictogram'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import LocationFormatter from '@/components/LocationFormatter'
import styles from './InterventionOverview.module.scss'

function InterventionOverview({ intervention, routerPush }) {
  const config = useVueState((s) => s.user.config)
  const [fileModal, setFileModal] = useState(false)

  const canUpdate = useMemo(
    () => config?.metadata?.permissions?.intervention?.update !== false,
    [config],
  )
  const canDelete = useMemo(
    () => config?.metadata?.permissions?.intervention?.delete !== false,
    [config],
  )

  async function askDelete() {
    Modal.confirm({
      title: 'Confirmer',
      icon: null,
      content: "Voulez-vous vraiment supprimer l'intervention ?",
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      confirmLoading: true,
      async onOk() {
        try {
          await interventionService.destroy({
            id: intervention.id,
          })
          routerPush({ name: 'list-intervention' })
        } catch (error) {
          notification.error({
            message: 'Erreur !',
            description: error.toString(),
          })
        }
      },
    })
  }

  function findIcon(name) {
    return icons.find(({ value }) => value === name)
  }

  function onClickDownload() {
    window.open(`${API_ENDPOINT}interventions/${this.intervention.id}/stats.csv`, '_blank')
  }

  return (
    <div className="row justify-content-center">
      <div className="col-md-6">
        <table className={styles.details}>
          <tbody>
            <tr>
              <th>Titre</th>
              <td>{intervention.name}</td>
            </tr>
            <tr>
              <th>Date</th>
              <td>
                <div className={styles.times}>
                  <i className="ion-ios-calendar" />&nbsp;
                  {moment(intervention.createdAt).format('DD/MM/YYYY')}&nbsp;|&nbsp;
                  {/* au {moment(intervention.endAt).format('DD/MM/YYYY')} &nbsp;|&nbsp; */}
                  <i className="ion-ios-refresh" />&nbsp;
                  {moment(intervention.createdAt).fromNow()}
                </div>
              </td>
            </tr>

            <tr>
              <th>Date de l'intervention</th>
              <td>
                <div className={styles.times}>
                  <i className="ion-ios-calendar" />&nbsp;
                  {moment(intervention.startAt).format('DD/MM/YYYY')}&nbsp;|&nbsp;
                  {intervention.time}
                  {/* au {moment(intervention.endAt).format('DD/MM/YYYY')} &nbsp;|&nbsp; */}

                </div>
              </td>
            </tr>
            {intervention.company ?
              <tr >
                <th>Prestataire</th>
                <td>{intervention.company}</td>
              </tr>
              :
              null
            }
            <tr>
              <th>Lieu</th>
              <td>
                <LocationFormatter locations={intervention.localisations} />
              </td>
            </tr>
            {intervention.availableAt ?
              <tr>
                <th>Programmé le </th>
                <td className={styles.description}>
                  {moment(intervention.availableAt).format('DD/MM/YYYY')}
                </td>
              </tr>
              :
              null
            }
            <tr>
              <th>Statistiques</th>
              <td>
                {/* <div className={styles.metricLine}>
                  <div className={styles.metric}>Disponible : {intervention.avaidable}</div>
                  <div className={styles.metric}>Absent : {intervention.absent}</div>
                </div> */}
                <div className={styles.metricLine}>
                  <div className={styles.metric}>UL concernés : {intervention.ulTargeted}</div>
                  <div className={styles.metric}>UL actifs : {intervention.ulActive}</div>
                </div>
                <div className={styles.metricLine}>
                  {/* <div className={styles.metric}>UL atteints : {intervention.views}</div> */}
                  {/* <div className={styles.metric}>Interactions : {intervention.interactions}</div> */}
                </div>
                <div className="btn default" style={{ padding: 0 }} onClick={() => onClickDownload()}>
                  Télécharger les statistiques
                </div>
              </td>
            </tr>
            {intervention.description ?
              <tr>
                <th>Description</th>
                <td className={styles.description}>
                  <TextEditorRenderer >
                    {intervention.description}
                  </TextEditorRenderer>
                </td>
              </tr>
              :
              null
            }
            <tr>
              <th>Pictogramme associé</th>
              {findIcon(intervention.pictogram) ?
                <td >
                  <Pictogram name={intervention.pictogram} />
                  {/* <component v-bind:is="findIcon(intervention.pictogram).image"></component> */}
                </td>
                :
                null
              }
            </tr>
            <tr>
              <th>Disponible</th>
              <td>
                <div className={styles.metric}> {intervention.avaidable}/{intervention.ulTargeted}</div>
              </td>
            </tr>
            <tr>
              <th>Absent</th>
              <td>
                <div className={styles.metric}> {intervention.absent}/{intervention.ulTargeted}</div>
              </td>
            </tr>
            {intervention.picture ?
              <tr>
                <th>Photo</th>
                <td>
                  <img src={intervention.picture} click={() => setFileModal(true)} />
                  {intervention ?
                    <Modal model={fileModal}>
                      <img src={intervention.picture} className="modal-file" click={() => setFileModal(false)} />
                    </Modal>
                    :
                    null
                  }
                </td>
              </tr>
              :
              null
            }
          </tbody>
        </table>

        <div className={styles.deleteReport}>
          <div className={`btn default  ${!canUpdate ? disabled : null}`}
            onClick={() => routerPush({ name: 'edit-intervention', params: { id: intervention.id } })}
          >
            <div>Modifier l'intervention</div>
          </div>
        </div>
        <div className={styles.deleteReport}>
          <div
            // className={clsx("btn default", { disabled: !canDelete })}
            className={`btn default  ${!canDelete ? disabled : null}`}
            onClick={() => askDelete()}
          >
            {/* <Pictogram name="supprimer" /> */}
            {/* <DeleteIcon></DeleteIcon> */}
            <div>Supprimer l'intervention</div>
          </div>
        </div>
      </div >
    </div >
  )
}

InterventionOverview.propTypes = {
  intervention: PropTypes.object.isRequired,
  routerPush: PropTypes.func.isRequired,
}

export default InterventionOverview
