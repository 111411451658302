import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/localisationTypes.graphql'
import CREATE_MUTATION from '@/queries/createLocalisationType.graphql'
import UPDATE_MUTATION from '@/queries/updateLocalisationType.graphql'
import DELETE_MUTATION from '@/queries/deleteLocalisationType.graphql'

export async function list() {
  const res = await client.query({
    query: LIST_QUERY,
  })
  return res.data.localisationTypes
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createLocalisationType
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {input},
  })
  return res.data.updateLocalisationType
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteLocalisationType
}
