import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import icons from '@/utils/icons'

function Pictogram({ name }) {
  const Component = useMemo(
    () => icons.find(v => v.value === name)?.component,
    [name],
  )
  return Component ? <Component /> : null
}

Pictogram.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Pictogram
