<template>
  <div id="app">
    <div class="status-bar"></div>
    <router-view></router-view>
  </div>
</template>

<script>
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { FCM } from '@capacitor-community/fcm'
import { PushNotifications } from '@capacitor/push-notifications'
import { Keyboard } from '@capacitor/keyboard'
import { App } from '@capacitor/app'
import { Toast } from '@capacitor/toast'
import { Capacitor } from '@capacitor/core'
import { mapActions, mapState } from 'vuex'
import { Dialog } from '@capacitor/dialog';
import { Browser } from '@capacitor/browser';
import localAppInfo from "../appInfo.json"

export default {
  name: 'App',
  computed: {
    ...mapState('user', ['token']),
    ...mapState('appInfo', ['appInfo']),
  },
  methods: {
    ...mapActions('user', ['updateDevice', 'fetchConfig']),
    ...mapActions('work', ['fetchWorks']),
    ...mapActions('reporting', ['fetchReports']),
    ...mapActions('intervention', ['fetchInterventions']),
    ...mapActions('satisfaction', ['fetchSatisfactions']),
    ...mapActions('tribune', ['fetchTribunes']),
    ...mapActions('concertation', ['fetchConcertations']),
    ...mapActions('appInfo', ['fetchAppInfo', 'setAppVersionChecked']),
    async showAlert() {
      await Dialog.alert({
        title: "Nouvelle mise à jour",
        message: "Une nouvelle mise à jour est disponible ! Cliquer pour installer",
        buttonTitle: "Installer",
      }).then((res) => {
        if (Capacitor.getPlatform() === 'ios') {
          console.log("run ios")
          Browser.open({ url: 'https://apps.apple.com/fr/app/neybs/id1231937542' });
        } else {
          console.log("run android")
          Browser.open({ url: 'https://play.google.com/store/apps/details?id=com.ionicframework.newnayb408038&hl=fr' });
        }
        document.location.href = 'index.html';
      })
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      await this.fetchAppInfo()

      if (localAppInfo.appVersion !== this.appInfo.appVersion) {
        this.showAlert();
      } else {
        setAppVersionChecked(true);
      }
      Promise.all([
        FirebaseAnalytics.enable(),
        Keyboard.setAccessoryBarVisible({ isVisible: true }),
      ]).catch(console.error)
      App.addListener('appStateChange', async (state) => {
        if (state.isActive) {
          await PushNotifications.removeAllDeliveredNotifications()
          if (this.token) {
            await this.fetchConfig()
          }
        }
      })

      PushNotifications.requestPermissions()
        .then(({ receive }) => {
          if (receive === 'granted') {
            return PushNotifications.register()
          }
        })

      PushNotifications.addListener('registration', async () => {
        const { token } = await FCM.getToken().catch(console.error)
        if (this.token) {
          await this.updateDevice({ token })
        }
      })
      PushNotifications.addListener('pushNotificationReceived', async (event) => {
        await Toast.show({
          duration: 'short',
          position: 'bottom',
          text: event.title,
        })
        switch (event.data?.type) {
        case 'travaux':
          await this.fetchWorks()
          this.$router.push({ name: 'user-travaux-list', params: { id } })
          break
        case 'signalement':
          await this.fetchReports()
          this.$router.push({ name: 'user-signalements-list', params: { id } })
          break
        case 'intervention':
          await this.fetchInterventions()
          this.$router.push({ name: 'user-interventions-list', params: { id } })
          break
        case 'satisfaction':
          await this.fetchSatisfactions()
          this.$router.push({ name: 'user-votes-list', params: { id } })
          break
        case 'tribune':
          await this.fetchTribunes()
          this.$router.push({ name: 'user-home', params: { id } })
          break
        case 'concertation':
          await this.fetchConcertations()
          this.$router.push({ name: 'user-concertation-answer', params: { id } })
          break
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "./assets/styles/index";
@import "~ionicons/dist/scss/ionicons.scss";

html,
body {
  min-height: 100%;
}

@media screen and (max-width: $breakpoint-mobile) {
  body {
    margin-bottom: calc(16px + env(safe-area-inset-bottom));
  }
}

div,
p,
span,
svg {
  user-select: none;
}
</style>

<style lang="scss" scoped>
.status-bar {
  position: fixed;
  top: 0;
  height: env(safe-area-inset-top);
  background: #000;
  width: 100%;
  z-index: 999999;
}
</style>
