import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/works.graphql'
import GET_QUERY from '@/queries/work.graphql'
import CREATE_MUTATION from '@/queries/createWork.graphql'
import UPDATE_MUTATION from '@/queries/updateWork.graphql'
import DELETE_MUTATION from '@/queries/deleteWork.graphql'
import READED_MUTATION from '@/queries/readedWork.graphql'

export async function list({filter, localisations = []} = {}) {
  const res = await client.query({
    query: LIST_QUERY,
    variables: {
      filter,
      localisations,
    },
  })
  return res.data.works
}

export async function get({ id }) {
  const res = await client.query({
    query: GET_QUERY,
    variables: { id },
  })
  return res.data.work
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createWork
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {input},
  })
  return res.data.updateWork
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteWork
}

export async function readed({id}) {
  const res = await client.mutate({
    mutation: READED_MUTATION,
    variables: {id},
  })
  return res.data.readedWork
}
