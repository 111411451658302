import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Empty, Input, notification, Spin, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { interventionService } from '@/services'
import styles from './InterventionResults.module.scss'
import InterventionAnswer from './InterventionAnswer'

function InterventionResults({ intervention }) {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    setLoading(true)
    interventionService.getAnswers({ id: intervention.id, page, search })
      .then((answers) => {
        setAnswers(answers ?? [])
      })
      .catch((error) => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
      .finally(() => setLoading(false))
  }, [page, search, intervention])

  function renderContent() {
    if (loading) {
      return null
    }
    if (!answers?.length) {
      return <Empty description="Aucune réponse trouvée" />
    }
    return answers.map(answer => (
      <InterventionAnswer
        key={answer.ul}
        intervention={intervention}
        answer={answer}
      />
    ))
  }

  return (
    <div>
      <div className={styles.header}>
        <Input.Search
          placeholder="Rechercher..."
          onSearch={search => setSearch(search)}
          size="large"
        />
      </div>
      <div className={styles.content}>
        {renderContent()}
      </div>
      {loading && (
        <div className={styles.loader}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
        </div>
      )}
    </div>
  )
}

InterventionResults.propTypes = {
  intervention: PropTypes.object.isRequired,
}

export default InterventionResults
