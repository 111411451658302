<template>
  <div class="tribune">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="TribuneForm"
            title="Ajouter une tribune"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RenderReact from '@/components/RenderReact'
import TribuneForm from '@/components/TribuneForm'
import {tribuneService} from '@/services'

export default {
  name: 'NewTribune',
  components: {
    RenderReact,
  },
  data: () => ({ TribuneForm }),
  methods: {
    async onSubmit(values) {
      await tribuneService.create(values)
      this.$router.push({ name: 'list-tribune' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.tribune {
  margin-bottom: 48px;
}
</style>
