import { workService } from '@/services'

const initialState = {
  works: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setWorks(state, works) {
      state.works = works
    },
  },
  actions: {
    async fetchWorks({ commit }) {
      const data = await workService.list()
      data.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime())
      commit('setWorks', data)
      return data
    },
    async markAsRead({ dispatch }, { id }) {
      await workService.readed({ id })
      return dispatch('fetchWorks')
    },
  },
}
