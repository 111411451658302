import { client } from '@/utils/api'

const initialState = {}

export default {
  namespaced: true,
  state: initialState,
  mutations: {},
  actions: {
    async createUser(_, { firstName, lastName, email, password, role, office, superownerId }) {
      const res = await client.post('admin/create-user', {
        firstName,
        lastName,
        email,
        password,
        role,
        office,
        superownerId,
      })
      return res.data.data
    },
    async createLocation(_, payload) {
      const res = await client.post('admin/create-location', payload)
      return res.data.data
    },
    async importLocations(_, { file }) {
      const form = new FormData()
      form.append('file', file)
      const res = await client.post('admin/import-location', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return res.data.data
    },
    async uploadImage(_, { image }) {
      const res = await client.post('admin/upload-image', {
        image,
      })
      return res.data.url
    },
    async uploadVideo(_, { video }) {
      const form = new FormData()
      form.append('file', video)
      const res = await client.post('admin/upload-video', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return res.data.url
    },
  },
}
