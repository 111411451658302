import client from "@/utils/apollo";
import LIST_QUERY from "@/queries/interventions.graphql";
import GET_QUERY from "@/queries/intervention.graphql";
import CREATE_MUTATION from "@/queries/createIntervention.graphql";
import UPDATE_MUTATION from "@/queries/updateIntervention.graphql";
import DELETE_MUTATION from "@/queries/deleteIntervention.graphql";
import READED_MUTATION from "@/queries/readedIntervention.graphql";
import ANSWERS_QUERY from "@/queries/interventionAnswers.graphql";
import ANSWER_MUTATION from "@/queries/answersIntervention.graphql";

export async function list({ filter, localisations = [] } = {}) {
  const res = await client.query({
    query: LIST_QUERY,
    variables: {
      filter,
      localisations,
    },
  });
  return res.data.interventions;
}

export async function get({ id }) {
  const res = await client.query({
    query: GET_QUERY,
    variables: { id },
  });
  return res.data.intervention;
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: { input },
  });
  return res.data.createIntervention;
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: { input },
  });
  return res.data.updateIntervention;
}

export async function destroy({ id }) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: { id },
  });
  return res.data.deleteIntervention;
}

export async function readed({ id }) {
  const res = await client.mutate({
    mutation: READED_MUTATION,
    variables: { id },
  });
  return res.data.readedIntervention;
}

export async function getAnswers({ id, page, search }) {
  const res = await client.query({
    query: ANSWERS_QUERY,
    variables: {
      id,
      page,
      search,
    },
  });
  return res.data.interventionAnswers;
}

export async function answer(input) {
  const res = await client.mutate({
    mutation: ANSWER_MUTATION,
    variables: { input },
  });
  return res.data.answerIntervention;
}
