import React, {useEffect, useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import {Button, Drawer, Form, Input, Typography, notification, Table, Space} from 'antd'
import {CreditCardOutlined} from '@ant-design/icons'
import {configService} from '@/services'
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import styles from './Config.module.scss'
import LocationFormatter from "@/components/LocationFormatter";

const { Title } = Typography

const CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#1890ff',
      color: 'rgba(0, 0, 0, 0.65)',
      fontWeight: 500,
      fontFamily: 'Segoe UI, Roboto, Open Sans, , sans-serif',
      fontSize: '15px',
      fontSmoothing: 'antialiased',
      border: '1px solid #d9d9d9',
      ':-webkit-autofill': {color: '#fce883'},
      '::placeholder': {color: '#bfbfbf'}
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee'
    }
  }
}

function StripeForm({onSubmit}) {
  const stripe = useStripe()
  const elements = useElements()
  const [form] = Form.useForm()

  const onFinish = useCallback(
    (values) => onSubmit(values, form, stripe, elements),
    [form, stripe, elements],
  )

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item name="holder" label="Titulaire de la carte" rules={[{required: true, message: 'Requis.'}]}>
        <Input/>
      </Form.Item>

      <Form.Item name="card" label="Carte" rules={[{required: true, message: 'Requis.'}]}>
        <CardElement options={CARD_ELEMENT_OPTIONS}/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          Valider
        </Button>
      </Form.Item>
    </Form>
  )
}

StripeForm.propTypes = {
  onSubmit: PropTypes.func,
}

const columns = [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Montant',
    dataIndex: 'amountDue',
    key: 'amountDue',
    render: amountDue => `${amountDue/100}€`,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => {
      switch (status) {
      case 'paid':
        return <span className={styles.invoicePaid}>Payé</span>
      case 'open':
      case 'uncollectible':
        return <span className={styles.invoiceDue}>En attente de paiement</span>
      case 'void':
        return <span className={styles.invoiceDue}>Annulé</span>
      default:
        return 'Inconnu'
      }
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        {['paid', 'void'].includes(record.status) && (
          <Button type="link" href={record.invoicePdfUrl} target="_blank" rel="noopener noreferrer">
            Télécharger la facture
          </Button>
        )}
        {['open', 'uncollectible'].includes(record.status) && (
          <Button type="link" href={record.hostedInvoiceUrl} target="_blank" rel="noopener noreferrer">
            Payer
          </Button>
        )}
      </Space>
    ),
  },
]

function Billing() {
  const [loading, setLoading] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [invoices, setInvoices] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)
  const [intentKey, setIntentKey] = useState(null)
  const [loadingIntent, setLoadingIntent] = useState(false)

  useEffect(() => {
    setLoading(true)
    Promise.all([
      configService.getPaymentMethod(),
      configService.listInvoices(),
    ])
      .then(([paymentMethod, invoices]) => {
        setPaymentMethod(paymentMethod)
        setInvoices(invoices)
      })
      .catch((error) => notification.error({
        message: 'Erreur !',
        description: error.message,
      }))
      .finally(() => setLoading(false))
  }, [])

  async function onSubmit(values, form, stripe, elements) {
    if (!stripe || !elements) {
      return
    }
    const res = await stripe.confirmCardSetup(intentKey, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: values.holder,
        },
      },
      return_url: window.location.href,
    })
    if (res.error) {
      return notification.error({
        message: 'Erreur !',
        description: res.error.message,
      })
    }
    return configService
      .updatePaymentMethod({paymentMethodId: res.setupIntent.payment_method})
      .then((paymentMethod) => {
        setIntentKey(null)
        setPaymentMethod(paymentMethod)
      })
      .catch((error) => notification.error({
        message: 'Erreur !',
        description: error.message,
      }))
  }

  async function requestIntentKey() {
    setLoadingIntent(true)
    try {
      const data = await configService.newSetupIntent()
      setStripePromise(loadStripe(data.stripePublicKey))
      setIntentKey(data.secretKey)
    } finally {
      setLoadingIntent(false)
    }
  }

  return loading ? 'Chargement...' : (
    <>
      <Title level={4}>Mode de paiement</Title>
      <div className={styles.billingContainer}>
        {paymentMethod && (
          <div className={styles.creditCard}>
            <CreditCardOutlined />
            {paymentMethod.last4} (expire le {paymentMethod.expMonth}/{paymentMethod.expYear})
          </div>
        )}

        <Button type="primary" htmlType="submit" onClick={requestIntentKey} loading={loadingIntent}>
          {paymentMethod ? 'Changer de mode de paiement' : 'Ajouter un mode de paiement'}
        </Button>
      </div>

      <Title level={4}>Factures</Title>
      <div className={styles.billingContainer}>
        <Table dataSource={invoices} columns={columns} pagination={false} rowKey="id"/>
      </div>

      <Drawer
        title={paymentMethod ? 'Changer de mode de paiement' : 'Ajouter un mode de paiement'}
        placement="right"
        width="min(100vw, 600px)"
        closable={false}
        onClose={() => setIntentKey(null)}
        visible={intentKey}
      >
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <StripeForm onSubmit={onSubmit}/>
          </Elements>
        )}
      </Drawer>
    </>
  )
}

Billing.propTypes = {
  config: PropTypes.object,
  updateConfig: PropTypes.func,
}

export default Billing
