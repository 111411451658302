import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import styles from './InterventionAnswer.module.scss'

function InterventionAnswer({ intervention, answer }) {
  const [toggle, setToggle] = useState(true);

  return (
    <div className={styles.root}>
      <div className={styles.header} onClick={() => setToggle(!toggle)}>
        <div>
          <span>
            {answer.ul ? `UL ${answer.ul}` : 'UL inconnu'}&nbsp;-&nbsp;
          </span>
          <span>
            {answer.bloc ? `${answer.bloc}` : 'inconnu'}&nbsp;-&nbsp;
          </span>
          <span>
            {answer.number ? `Pte ${answer.number}` : 'inconnu'}&nbsp;-&nbsp;
          </span>
          <span>
            {answer.floor ? `Etg ${answer.floor}` : 'inconnu'}&nbsp;
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
          <div>
            ({new Intl.DateTimeFormat('fr-FR').format(new Date(answer.createdAt))})
          </div>

          <div style={{ display: "flex", verticalAlign: "middle" }}>
            {toggle ? <CaretDownOutlined /> : <CaretUpOutlined />}
          </div>
        </div>
      </div>
      {!toggle && (
        <div className={styles.body}>
          <Col gutter={[16, 24]}>
            <div>Réponse : {answer.answer}</div>
            <div>Texte : {answer.text}</div>
          </Col>
        </div>
      )}
    </div>
  )
}

InterventionAnswer.propTypes = {
  intervention: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
}

export default InterventionAnswer
