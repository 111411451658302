import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/tribunes.graphql'
import GET_QUERY from '@/queries/tribune.graphql'
import CREATE_MUTATION from '@/queries/createTribune.graphql'
import UPDATE_MUTATION from '@/queries/updateTribune.graphql'
import DELETE_MUTATION from '@/queries/deleteTribune.graphql'
import READED_MUTATION from '@/queries/readedTribune.graphql'

export async function list({filter, localisations = []} = {}) {
  const res = await client.query({
    query: LIST_QUERY,
    variables: {
      filter,
      localisations,
    },
  })
  return res.data.tribunes
}

export async function get({ id }) {
  const res = await client.query({
    query: GET_QUERY,
    variables: { id },
  })
  return res.data.tribune
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: {input},
  })
  return res.data.createTribune
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: {input},
  })
  return res.data.updateTribune
}

export async function destroy({id}) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: {id},
  })
  return res.data.deleteTribune
}

export async function readed({id}) {
  const res = await client.mutate({
    mutation: READED_MUTATION,
    variables: {id},
  })
  return res.data.readedTribune
}
