<template>
  <div class="update-tutorial">
    <div class="header">
      <TutorialIcon></TutorialIcon>
      Modifier un tutoriel
    </div>
    <form class="form" @submit="submit">
      <Error :error="error"></Error>

      <template v-if="tutorial">
        <div class="form-field">
          <div class="form-field-label">
            Nom
            <span class="required">*</span>
          </div>
          <input v-model="name" class="form-field-input" type="text">
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Logo
            <span class="required">*</span>
          </div>
          <PictureUrlInput v-model="image"/>
        </div>
        <div class="form-field">
          <div class="form-field-label">
            Video
            <span class="required">*</span>
          </div>
          <VideoUrlInput v-model="video"/>
        </div>

        <div class="form-submission">
          <div class="btn danger" @click="removeTutorial">
            Supprimer
          </div>
          <div>
            <div class="btn default" @click="$router.go(-1)">
              Annuler
            </div>
            <button type="submit" class="btn primary">
              Valider
            </button>
          </div>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import TutorialIcon from '@/assets/picto/tutosvideos.svg'
import ErrorComponent from '@/components/Error'
import PictureUrlInput from '@/components/PictureUrlInput'
import VideoUrlInput from '@/components/VideoUrlInput'
import {tutorialService} from '@/services'

export default {
  name: 'UpdateTutorial',
  components: {
    TutorialIcon,
    PictureUrlInput,
    VideoUrlInput,
    Error: ErrorComponent,
  },
  data: () => ({
    error: undefined,
    tutorial: undefined,
    name: '',
    video: '',
    image: '',
  }),
  methods: {
    async removeTutorial() {
      try {
        await tutorialService.destroy({
          id: this.tutorial.id,
        })
        this.$router.push({ name: 'admin-list-tutorial' })
      } catch (error) {
        this.error = error
      }
    },
    async submit(event) {
      event.preventDefault()
      try {
        if (!this.name || !this.video || !this.image) {
          throw new Error('Champs requis manquant.')
        }
        await tutorialService.update({
          id: this.tutorial.id,
          name: this.name,
          video: this.video,
          image: this.image,
        })
        this.$router.push({ name: 'admin-list-tutorial' })
      } catch (error) {
        this.error = error
      }
    },
  },
  async mounted() {
    try {
      const tutorial = await tutorialService.get({
        id: this.$route.params.id,
      })
      this.name = tutorial.name
      this.video = tutorial.video
      this.image = tutorial.image
      this.tutorial = tutorial
    } catch (error) {
      this.error = error
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.form {
  margin: 0 auto 32px;
  max-width: 720px;
  padding: 0 16px;
}

.form-submission {
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
