<template>
  <div class="user-list-travaux">
    <template v-if="mappedWorks.length > 0">
      <div class="card" v-for="work in works" :key="work.id">
        <div class="card-header">
          <div class="card-type-icon">
            <TravauxIcon></TravauxIcon>
          </div>
          <div class="card-icon">
            <component v-if="work.pictogram" v-bind:is="findIcon(work.pictogram).image"></component>
          </div>
        </div>
        <div class="card-body">
          <div class="card-title">
            {{work.name}}
          </div>
          <div class="card-duration">
            <CalendarIcon></CalendarIcon>
            <RenderReact :component="DateRangeFormatter" :startAt="work.startAt" :endAt="work.endAt" />
          </div>
          <!-- <div class="card-duration">
            <DurationIcon></DurationIcon>
            {{work.createdAt | moment("from", "now")}}
          </div> -->
          <div class="card-data" v-if="work.company">
            Prestataire : {{work.company}}
          </div>
          <div class="card-description">
            <RenderReact
              :component="TextEditorRenderer"
              :children="work.description"
            />
          </div>
          <img v-if="work.picture" class="card-image" :src="work.picture">
        </div>
        <div class="card-footer">
          <div v-if="work.hasRead" class="card-button read">
            <i class="ion-ios-checkmark-circle"></i>
            Information déjà lue
          </div>
          <ClickLoaderWrapper v-else class="card-button" @click="markAsReadWrapper(work)">
<!--            <i class="ion-ios-checkmark-circle-outline"></i>-->
            J'ai lu l'information
          </ClickLoaderWrapper>
        </div>
      </div>
    </template>
    <div v-else class="nothing-to-show">
      <TravauxIcon></TravauxIcon>
      Rien à signaler
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import { Capacitor } from '@capacitor/core'
import { Dialog } from '@capacitor/dialog'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import {mapActions, mapState} from 'vuex'
import ClickLoaderWrapper from '@/components/ClickLoaderWrapper'
import DurationIcon from '@/assets/picto/historique.svg'
import CalendarIcon from '@/assets/picto/agenda.svg'
import TravauxIcon from '@/assets/picto/travaux.svg'
import icons from '@/utils/icons'
import RenderReact from '@/components/RenderReact'
import TextEditorRenderer from '@/components/TextEditor/TextEditorRenderer'
import DateRangeFormatter from '@/components/DateRangeFormatter'

export default {
  name: 'UserListTravaux',
  components: {
    RenderReact,
    ClickLoaderWrapper,
    TravauxIcon,
    CalendarIcon,
    DurationIcon,
  },
  data: () => ({
    TextEditorRenderer,
    DateRangeFormatter,
    hammer: undefined,
  }),
  computed: {
    ...mapState('work', ['works']),
    mappedWorks() {
      return this.works
        ?.sort(({ availableAt: a }, { availableAt: b }) => new Date(b).getTime() - new Date(a).getTime()) ?? []
    },
  },
  methods: {
    ...mapActions('work', ['fetchWorks', 'markAsRead']),
    findIcon(name) {
      return icons.find(({ value }) => value === name) ?? {}
    },
    markAsReadWrapper(work, cb) {
      cb(this.markAsRead({ id: work.id }))
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== 'web') {
      FirebaseAnalytics.setScreenName({
        screenName: 'list-travaux',
        nameOverride: 'ListTravauxScreen',
      }).catch(console.error)
    }
    this.hammer = new Hammer(document.body)
    this.hammer.on('swipe', (event) => {
      if (event?.direction === 4) {
        this.$router.push({ name: 'user-votes-list' })
      } else if (event?.direction === 2) {
        this.$router.push({ name: 'user-interventions-list' })
      }
    })
    try {
      await this.fetchWorks()
    } catch (error) {
      await Dialog.alert({
        title: 'Erreur',
        message: error.message,
      })
    }
  },
  async beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/card";

.card {
  @include user-card($color-work);
}

.nothing-to-show {
  @include nothing-to-show($color-work);
}
</style>
