import client from '@/utils/apollo'
import LIST_QUERY from '@/queries/users.graphql'
import UPDATE_ME_MUTATION from '@/queries/updateMe.graphql'
import UPDATE_USER_MUTATION from '@/queries/updateUser.graphql'

export async function list({only}) {
  const res = await client.query({
    query: LIST_QUERY,
    variables: {only},
  })
  return res.data.users
}

export async function updateMe(input) {
  const res = await client.mutate({
    mutation: UPDATE_ME_MUTATION,
    variables: {input},
  })
  return res.data.updateMe
}

export async function updateUser(input) {
  const res = await client.mutate({
    mutation: UPDATE_USER_MUTATION,
    variables: {input},
  })
  return res.data.updateUser
}
