<template>
  <div class="home">
    <div class="header">
      <ParametresIcon></ParametresIcon> Parametrage admin
    </div>
    <div class="container">
      <div class="link-list">
        <router-link :to="{ name: 'statistics-superowner' }" class="link">
          Statistiques
        </router-link>
        <router-link :to="{ name: 'permissions-superowner' }" class="link">
          Roles et autorisations
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ParametresIcon from '@/assets/picto/parametres.svg'

export default {
  name: 'SuperOwnerHome',
  components: {
    ParametresIcon
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.home {
  padding-bottom: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.link-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  grid-auto-rows: minmax(auto, 33.33%);
}

.link {
  display: block;
  background: $color-grey;
  font-size: 16px;
  font-weight: 600;
  padding: 32px 0 64px;
  text-align: center;
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
