<template>
  <div class="contacts">
    <div v-if="error" class="error-container">
      <Error :error="error" />
    </div>

    <div class="contact-container contact-container-colored">
      <div class="contact-container-title">Contacts</div>

      <div
        v-for="(contact, index) in contacts"
        :key="index"
        class="contact-item"
      >
        <div class="contact-role">{{ contact.role }} :</div>
        <div v-if="contact.image">
          <img :src="contact.image" width="52" height="52" />
        </div>
        <div v-if="contact.name" class="contact-name">
          <i class="ion-md-person"></i>
          {{ contact.name }}
        </div>
        <div v-if="contact.hours && contact.hours.length" class="contact-hours">
          <ClockIcon></ClockIcon>
          <div class="contact-hours-list">
            <div v-for="(hour, index) in contact.hours" :key="index">
              {{ hour }}
            </div>
          </div>
        </div>
        <div v-if="contact.phone" class="contact-phone" @click="call(contact)">
          <i class="ion-ios-call"></i>
          <span>{{ contact.phone }}</span>
        </div>
      </div>

      <div v-if="contacts.length === 0">Aucun contact disponible.</div>
    </div>

    <div class="contact-container">
      <div class="contact-container-title">Liens utiles</div>

      <div class="link-list">
        <div
          v-for="(link, index) in links"
          :key="index"
          class="link"
          @click="openLink(link.href)"
        >
          <img :src="link.image" />
          {{ link.name }}
        </div>
      </div>

      <div v-if="links.length === 0">Aucun lien disponible.</div>
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";
import { Capacitor } from "@capacitor/core";
import { Toast } from "@capacitor/toast";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { CallNumber } from "@ionic-native/call-number";
import Error from "@/components/Error";
import ClockIcon from "@/assets/picto/clock.svg";
import { contactLinkService, contactService } from "@/services";

export default {
  name: "UserContacts",
  components: {
    Error,
    ClockIcon,
  },
  data: () => ({
    contacts: [],
    links: [],
    error: undefined,
    hammer: undefined,
  }),
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    async call(contact) {
      if (contact.phone) {
        try {
          await CallNumber.callNumber(contact.phone, true);
        } catch (error) {
          await Toast.show({
            text: "Impossible d'appeler ce numéro.",
          });
        }
      }
    },
  },
  async mounted() {
    if (Capacitor.getPlatform() !== "web") {
      FirebaseAnalytics.setScreenName({
        screenName: "contacts",
        nameOverride: "ContactsScreen",
      }).catch(console.error);
    }

    try {
      const [contacts, links] = await Promise.all([
        contactService.list(),
        contactLinkService.list(),
      ]);
      this.contacts = contacts;
      this.links = links;
    } catch (error) {
      this.error = error;
    }

    this.hammer = new Hammer(document.body);
    this.hammer.on("swipe", (event) => {
      if (event?.direction === 4) {
        this.$router.push({ name: "user-interventions-list" });
      }
    });
  },
  async beforeDestroy() {
    if (this.hammer) {
      this.hammer.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables";

.error-container {
  .error {
    border-radius: 0;
  }
}

.contact-container {
  padding: 16px;

  .contact-container-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.contact-container-colored {
  background: $color-contact;
  color: $color-white;
}

.contact-item {
  margin-bottom: 12px;
  word-break: break-word;

  .contact-role {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .contact-name,
  .contact-hours,
  .contact-phone {
    margin-left: 8px;

    i,
    svg {
      margin-right: 12px;
    }
  }

  .contact-phone span {
    text-decoration: underline;
  }

  svg {
    height: 16px;

    .st0 {
      fill: none;
      stroke: #ffffff;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st1 {
      fill: none;
      stroke: #ffffff;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }

  .contact-hours {
    display: flex;
    align-items: center;
  }
}

.link-list {
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    word-break: break-word;

    img {
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }
  }
}
</style>
