<template>
  <div class="add-tutorial">
    <div class="header">
      <TutorialIcon></TutorialIcon>
      Ajouter un tutoriel
    </div>
    <form class="form" @submit="submit">
      <Error :error="error"></Error>
      <div class="form-field">
        <div class="form-field-label">
          Nom
          <span class="required">*</span>
        </div>
        <input v-model="name" class="form-field-input" type="text">
      </div>
      <div class="form-field">
        <div class="form-field-label">
          Logo
          <span class="required">*</span>
        </div>
        <PictureUrlInput v-model="image"/>
      </div>
      <div class="form-field">
        <div class="form-field-label">
          Video
          <span class="required">*</span>
        </div>
        <VideoUrlInput v-model="video"/>
      </div>

      <div class="form-submission">
        <div class="btn default" @click="$router.go(-1)">
          Annuler
        </div>
        <button type="submit" class="btn primary">
          Valider
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import TutorialIcon from '@/assets/picto/tutosvideos.svg'
import ErrorComponent from '@/components/Error'
import PictureUrlInput from '@/components/PictureUrlInput'
import VideoUrlInput from '@/components/VideoUrlInput'
import {tutorialService} from '@/services'

export default {
  name: 'AddTutorial',
  components: {
    TutorialIcon,
    PictureUrlInput,
    VideoUrlInput,
    Error: ErrorComponent,
  },
  data: () => ({
    error: undefined,
    name: '',
    image: '',
    video: '',
  }),
  methods: {
    async submit(event) {
      event.preventDefault()
      try {
        if (!this.name || !this.image || !this.video) {
          throw new Error('Champs requis manquant.')
        }
        await tutorialService.create({
          name: this.name,
          image: this.image,
          video: this.video,
        })
        this.$router.push({ name: 'admin-list-tutorial' })
      } catch (error) {
        this.error = error
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.form {
  margin: 0 auto 32px;
  max-width: 720px;
  padding: 0 16px;
}

.box {
  margin-bottom: 36px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.empty-state {
  padding: 32px 10px;
  border-radius: 4px;
  margin-bottom: 16px;
  background: $color-grey;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
