<template>
  <div class="bloc-input">
    <TreeSelect
      :value="value"
      :multiple="true"
      :options="options"
      :disabled="disabled"
      @input="onChange"
    />
  </div>
</template>

<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TreeSelect from '@riophae/vue-treeselect'
import {mapState} from 'vuex'

export default {
  name: 'LocalisationInput',
  props: ['defaultValue', 'disabled', 'unit'],
  components: { TreeSelect },
  data: () => ({
    value: undefined,
    options: [],
  }),
  computed: mapState('localisation', ['localisations']),
  watch: {
    localisations: {
      immediate: true,
      handler() {
        this.options = this.buildLocalisations()
      },
    },
    unit: {
      immediate: true,
      handler() {
        this.options = this.buildLocalisations()
      },
    },
  },
  methods: {
    buildLocalisations(reamingTypes = ['site', 'building', 'bloc', 'homestay'], parentId = null) {
      return reamingTypes.length !== 0 && reamingTypes.includes(this.unit)
        ? this.localisations
          .filter(l => l.type === reamingTypes[0] && l.parentId === parentId)
          .sort(({ name: a }, { name: b }) => a.localeCompare(b))
          .map(l => ({
            id: `${reamingTypes[0]}:${l.id}`,
            label: l.name.toUpperCase(),
            isDisabled: l.disabled,
            children: this.buildLocalisations(reamingTypes.slice(1), l.id),
          }))
        : undefined
    },
    onChange(event) {
      this.value = event
      this.$emit('input', event.map(v => {
        const [type, id] = v.split(':')
        return { type, id }
      }))
    },
  },
  async mounted() {
    if (this.defaultValue) {
      this.onChange(
        this.defaultValue.map(v => `${v.type}:${v.id}`)
      )
    }
  }
}
</script>
