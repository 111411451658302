import { render, staticRenderFns } from "./CreateLocalisation.vue?vue&type=template&id=2211c411&scoped=true&"
import script from "./CreateLocalisation.vue?vue&type=script&lang=js&"
export * from "./CreateLocalisation.vue?vue&type=script&lang=js&"
import style0 from "./CreateLocalisation.vue?vue&type=style&index=0&id=2211c411&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2211c411",
  null
  
)

export default component.exports