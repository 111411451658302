<template>
  <div class="concertation">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center" v-if="concertation">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="ConcertationForm"
            title="Modifier une concertation"
            :concertation="concertation"
            :config="config"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConcertationForm from '@/components/ConcertationForm'
import RenderReact from '@/components/RenderReact'
import {concertationService} from '@/services'
import {mapState} from 'vuex'

export default {
  name: 'EditConcertation',
  components: {
    RenderReact,
  },
  data: () => ({
    concertation: null,
    ConcertationForm,
  }),
  computed: mapState('user', ['config']),
  methods: {
    async onSubmit(values) {
      const id = this.$route.params.id
      await concertationService.update({
        ...values,
        id,
      })
      this.$router.push({
        name: 'view-concertation',
        params: {
          id,
        },
      })
    },
  },
  async mounted() {
    try {
      this.concertation = await concertationService.get({
        id: this.$route.params.id,
      })
    } catch (error) {
      alert(error)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.concertation {
  margin-bottom: 48px;
}
</style>
