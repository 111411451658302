<template>
  <div
    class="element"
    :style="{ borderStyle: isDashed ? 'dashed' : 'solid' }"
    @click="view"
  >
    <div class="element-left">
      <div class="icon">
        <TribuneIcon></TribuneIcon>
      </div>
      <div class="description">
        <div class="title">{{ tribune.name }}</div>
        <div class="times">
          <i class="ion-ios-calendar"></i> Le
          {{ tribune.startAt | moment("DD/MM/YYYY") }}
          <!-- &nbsp;|&nbsp; -->
          <!-- <i class="ion-ios-refresh"></i> {{tribune.createdAt | moment("from", "now")}} -->
        </div>
        <div class="where">
          <i class="ion-ios-business"></i>
          <LogementFormatter
            :locations="tribune.localisations"
          ></LogementFormatter>
        </div>
        <div class="description">
          <RenderReact
            :strip="true"
            :component="TextEditorRenderer"
            :children="tribune.description"
          />
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="metric-list">
        <div class="metric">Atteint : {{ tribune.views }}</div>
        <!-- <div class="metric">Interaction : {{ tribune.interactions }}</div> -->
      </div>
      <component
        v-if="tribune.pictogram"
        v-bind:is="findIcon(tribune.pictogram).image"
      ></component>
    </div>
  </div>
</template>

<script>
import TribuneIcon from "@/assets/picto/tribune.svg";
import StatusIcon from "@/components/StatusIcon";
import icons from "@/utils/icons";
import LogementFormatter from "@/components/LogementFormatter";
import RenderReact from "@/components/RenderReact";
import TextEditorRenderer from "@/components/TextEditor/TextEditorRenderer";

export default {
  name: "TribuneCard",
  props: ["tribune"],
  data: () => ({ TextEditorRenderer, isDashed: false }),
  components: {
    RenderReact,
    LogementFormatter,
    TribuneIcon,
    StatusIcon,
  },
  methods: {
    view() {
      this.$router.push({
        name: "view-tribune",
        params: { id: this.tribune.id },
      });
    },
    findIcon(name) {
      return icons.find(({ value }) => value === name);
    },
  },
  async mounted() {
    const today = new Date();
    if (new Date(this.tribune.availableAt).getTime() > today.getTime()) {
      this.isDashed = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.element {
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  border: 4px solid $color-tribune;
  margin-bottom: 16px;
  width: 100%;
  cursor: pointer;

  &,
  .element-left {
    display: flex;
    flex-direction: row;
  }

  .icon {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: $color-tribune;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    svg {
      height: 42px;
      fill: $color-white;
    }
  }

  .description {
    padding: 0 8px 8px;

    .title {
      color: $color-tribune;
      font-size: 23px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .times,
    .where {
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    min-width: fit-content;

    .metric-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .metric {
        margin: 0 16px 16px 0;
        font-weight: 500;
        font-size: 14px;
      }
    }

    svg {
      height: 62px;
      margin: 0 48px;
      align-self: center;
    }

    img {
      height: 164px;
    }

    .status {
      align-self: center;
      margin: 0 32px;
      font-weight: 500;
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .element {
    padding: 8px;

    .icon {
      display: none;
    }

    .actions {
      display: none;
    }
  }
}
</style>
