import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Empty, Input, notification, Spin, Space} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {concertationService} from '@/services'
import styles from './ConcertationResults.module.scss'
import ConcertationAnswer from './ConcertationAnswer'

function ConcertationResults({ concertation }) {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    setLoading(true)
    concertationService.getAnswers({ id: concertation.id, page, search })
      .then((answers) => setAnswers(answers ?? []))
      .catch((error) => notification.error({
        message: 'Erreur !',
        description: error.toString(),
      }))
      .finally(() => setLoading(false))
  }, [page, search])

  function renderContent() {
    if (loading) {
      return null
    }
    if (!answers?.length) {
      return <Empty description="Aucune réponse trouvée"/>
    }
    return answers.map(answer => (
      <ConcertationAnswer
        key={answer.id}
        concertation={concertation}
        answer={answer}
      />
    ))
  }

  return (
    <div>
      <div className={styles.header}>
        <Input.Search
          placeholder="Rechercher..."
          onSearch={search => setSearch(search)}
          size="large"
        />
      </div>
      <div className={styles.content}>
        {renderContent()}
      </div>
      {loading && (
        <div className={styles.loader}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
        </div>
      )}
    </div>
  )
}

ConcertationResults.propTypes = {
  concertation: PropTypes.object.isRequired,
}

export default ConcertationResults
