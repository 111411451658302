import { client } from '@/utils/api'

const initialState = {
  superowners: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setSuperOwners(state, superowners) {
      state.superowners = superowners
    },
  },
  actions: {
    async fetchSuperOwners({ commit }) {
      const res = await client.get('superowners')
      commit('setSuperOwners', res.data.data)
      return res.data.data
    },
  },
}
