<template>
  <div class="location-wrapper">
    <div class="location-name" @click="toggle = !toggle" :class="{ 'not-toggled': !toggle }">
      <div v-if="location.children" class="icon">
        <i class="ion-ios-arrow-down"></i>
      </div>
      <div>
        {{location.name}}
      </div>
    </div>
    <template v-if="location.children && toggle">
      <LocationCollapse v-for="l in location.children" :key="l.id" :location="l">
      </LocationCollapse>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LocationCollapse',
  props: ['location'],
  data: () => ({ toggle: false })
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.location-wrapper {
  margin-left: 16px;
}

.location-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;

  .icon {
    margin-right: 4px;
  }

  &.not-toggled > .icon {
    transform: rotate(-90deg);
  }
}
</style>
