<template>
  <div class="picture-input">
    <input ref="input" class="hidden-input" type="file" accept="image/*" @change="onChange"/>
    <div class="button" @click="onClick">
      <div v-if="loading" class="loader"></div>
      <img v-else-if="image" :src="image" />
      <UploadPictureIcon v-else></UploadPictureIcon>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Capacitor } from '@capacitor/core'
import { Dialog } from '@capacitor/dialog'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import UploadPictureIcon from '@/assets/picto/telechargerphoto.svg'

export default {
  name: 'PictureUrlInput',
  props: ['value'],
  components: { UploadPictureIcon },
  data: () => ({
    image: undefined,
    loading: false,
  }),
  methods: {
    ...mapActions('admin', ['uploadImage']),
    onChange(event) {
      if (event.target && event.target.files && event.target.files[0]) {
        const [file] = event.target.files
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          this.afterGetImage(e.target.result)
        })
        reader.readAsDataURL(file)
      }
    },
    async onClick() {
      if (Capacitor.getPlatform() === 'web') {
        this.$refs.input.click()
      } else {
        const res = await Camera.getPhoto({
          allowEditing: false,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Camera,
        })
        await this.afterGetImage(res.dataUrl)
      }
    },
    async afterGetImage(dataUrl) {
      this.loading = true
      try {
        const url = await this.uploadImage({ image: dataUrl })
        this.image = url
        this.$emit('input', url)
      } catch (error) {
        await Dialog.alert({
          title: 'Erreur',
          message: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.image = this.value
    this.$emit('input', this.image)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.hidden-input {
  display: none;
}

.loader {
  opacity: 1;
  margin: 8px;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid $color-blue-dark;
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;

  &,
  ::after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: inline-block;
  border-radius: 12px;
  cursor: pointer;
  padding: 8px;
  background: $color-grey;

  svg, img {
    height: 48px;
    width: 48px;
  }

  &:hover {
    border-color: $color-blue-light;

    svg {
      fill: $color-blue-light;
    }
  }
}
</style>
