import React from 'react'
import PropTypes from 'prop-types'

function TextEditorLink({ contentState, entityKey, children }) {
  const { url } = contentState.getEntity(entityKey).getData()
  return (
    <a href={url} target="_blank" rel="noreferrer nofollow">
      {children}
    </a>
  )
}

TextEditorLink.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

TextEditorLink.findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      )
    },
    callback
  )
}

export default TextEditorLink
