import React, {useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {Button, Drawer} from 'antd'
import classnames from 'classnames'
import styles from '@/assets/styles/PictogramInput.module.scss'
import icons from '@/utils/icons'
import Pictogram from '@/components/Pictogram'

function PictogramInput({ value, onChange }) {
  const [isVisible, setVisible] = useState(false)
  const currentIcon = useMemo(
    () => icons.find(v => v.value === value),
    [value],
  )

  function selectIcon(value){
    onChange(value)
    setVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        {currentIcon ? 'Changer de pictogramme' : 'Sélectionner un pictogramme'}
      </Button>
      {currentIcon && (
        <div className={styles.currentIcon}>
          <Pictogram name={currentIcon.value} />
          {currentIcon.name}
        </div>
      )}
      <Drawer
        title="Choisissez le pictogramme qui correspond à la situation"
        placement="right"
        width="40vw"
        closable={false}
        onClose={() => setVisible(false)}
        visible={isVisible}
      >
        <p className={styles.description}>
          Ce choix est important. Il permet aux locataires de mieux comprendre la nature
          de la publication en un clin d'oeil !
        </p>
        <div className={styles.iconList}>
          {icons.map(icon => (
            <div
              key={icon.value}
              className={classnames(styles.icon, {
                [styles.iconSelected]: value === icon.value,
              })}
              onClick={() => selectIcon(icon.value)}
            >
              <Pictogram name={icon.value} />
              {icon.name}
            </div>
          ))}
        </div>
      </Drawer>
    </>
  )
}

PictogramInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default PictogramInput
