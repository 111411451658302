import client from "@/utils/apollo";
import LIST_QUERY from "@/queries/signalements.graphql";
import GET_QUERY from "@/queries/signalement.graphql";
import CREATE_MUTATION from "@/queries/createSignalement.graphql";
import ADD_COMMENT from "@/queries/addCommentSignalement.graphql";
import REMOVE_COMMENT from "@/queries/removeCommentSignalement.graphql";
import UPDATE_MUTATION from "@/queries/updateSignalement.graphql";
import UPDATE_STATUS_MUTATION from "@/queries/updateSignalementStatus.graphql";
import REQUEST_INTERVENTION_MUTATION from "@/queries/requestSignalementIntervention.graphql";
import REVIVE_INTERVENTION_MUTATION from "@/queries/reviveSignalementInterventionRequest.graphql";
import DELETE_MUTATION from "@/queries/deleteSignalement.graphql";
import READED_MUTATION from "@/queries/readedSignalement.graphql";

export async function list({ filter, localisations = [] } = {}) {
  const res = await client.query({
    query: LIST_QUERY,
    variables: {
      filter,
      localisations,
    },
  });
  return res.data.signalements;
}

export async function get({ id }) {
  const res = await client.query({
    query: GET_QUERY,
    variables: { id },
  });
  return res.data.signalement;
}

export async function create(input) {
  const res = await client.mutate({
    mutation: CREATE_MUTATION,
    variables: { input },
  });
  return res.data.createSignalement;
}

export async function update(input) {
  const res = await client.mutate({
    mutation: UPDATE_MUTATION,
    variables: { input },
  });
  return res.data.updateSignalement;
}

export async function addComment(input) {
  const res = await client.mutate({
    mutation: ADD_COMMENT,
    variables: { input },
  });
  return res.data.addCommentSignalement;
}

export async function removeComment(input) {
  const res = await client.query({
    query: REMOVE_COMMENT,
    variables: input,
  });
  return res.data.removeComment;
}

export async function updateStatus(input) {
  const res = await client.mutate({
    mutation: UPDATE_STATUS_MUTATION,
    variables: { input },
  });
  return res.data.updateSignalementStatus;
}

export async function requestIntervention(input) {
  const res = await client.mutate({
    mutation: REQUEST_INTERVENTION_MUTATION,
    variables: { input },
  });
  return res.data.requestSignalementIntervention;
}

export async function reviveInterventionRequest(input) {
  const res = await client.mutate({
    mutation: REVIVE_INTERVENTION_MUTATION,
    variables: { input },
  });
  return res.data.reviveSignalementInterventionRequest;
}

export async function destroy({ id }) {
  const res = await client.mutate({
    mutation: DELETE_MUTATION,
    variables: { id },
  });
  return res.data.deleteSignalement;
}

export async function readed({ id }) {
  const res = await client.mutate({
    mutation: READED_MUTATION,
    variables: { id },
  });
  return res.data.readedSignalement;
}
