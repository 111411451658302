<template>
  <div class="work">
    <div class="container">
      <div class="go-back" @click="$router.go(-1)">
        <i class="ion-md-arrow-round-back"></i>
        Retour
      </div>

      <div class="row justify-content-center" v-if="work">
        <div class="col-md-8 col-xl-6">
          <RenderReact
            :component="WorkForm"
            :work="work"
            title="Modifier un signalement travaux"
            @onSubmit="onSubmit"
            @routerBack="$router.back()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkForm from '@/components/WorkForm'
import RenderReact from '@/components/RenderReact'
import {workService} from '@/services'

export default {
  name: 'EditWork',
  components: {
    RenderReact,
  },
  data: () => ({
    work: null,
    WorkForm,
  }),
  methods: {
    async onSubmit(values) {
      const id = this.$route.params.id
      await workService.update({
        ...values,
        id,
      })
      this.$router.push({ name: 'view-work', params: { id } })
    },
  },
  async mounted() {
    this.work = await workService.get({ id: this.$route.params.id })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.work {
  margin-bottom: 48px;
}
</style>
