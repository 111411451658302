import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Alert } from 'antd'
import { interventionService } from '@/services'
import styles from './Intervention.module.scss'
import InterventionOverview from './InterventionOverview'
import InterventionResults from './InterventionResults'

function Intervention({ id, routerPush }) {
  const [page, setPage] = useState('overview')
  const [loading, setLoading] = useState(true)
  const [intervention, setIntervention] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
    interventionService.get({ id })
      .then((intervention) => setIntervention(intervention))
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [id])

  function renderContent() {
    switch (page) {
      case 'overview':
        return <InterventionOverview
          intervention={intervention}
          routerPush={routerPush}
        />
      case 'results':
        return <InterventionResults
          intervention={intervention}
        />
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerItem} onClick={() => routerPush({ name: 'list-intervention' })}>
          Intervention
        </div>
        <div className={styles.headerItem}>
          {intervention ? intervention.name : 'Chargement...'}
        </div>
      </div>
      <div className={styles.navigation}>
        <div
          onClick={() => setPage('overview')}
          className={clsx(styles.navigationLink, { [styles.active]: page === 'overview' })}
        >
          Informations
        </div>
        <div
          onClick={() => setPage('results')}
          className={clsx(styles.navigationLink, { [styles.active]: page === 'results' })}
        >
          Résultats
        </div>
      </div>

      <div className={clsx(styles.container, 'container')}>
        {loading && 'Chargement...'}

        {(!loading && error) && (
          <Alert
            showIcon
            type="error"
            message="Erreur"
            description={error?.message ?? error}
          />
        )}

        {(!loading && intervention) && renderContent()}
      </div>
    </div>
  )
}

Intervention.propTypes = {
  id: PropTypes.string.isRequired,
  routerPush: PropTypes.func.isRequired,
}

export default Intervention
