<template>
  <div
    class="element"
    :style="{ borderStyle: isDashed ? 'dashed' : 'solid' }"
    @click="view"
  >
    <div class="element-left">
      <div class="icon">
        <WorkIcon></WorkIcon>
      </div>
      <div class="description">
        <div class="title">{{ work.name }}</div>
        <div class="times">
          <span class="d-flex">
            <i class="ion-ios-calendar mr-1"></i>
            <RenderReact
              :component="DateRangeFormatter"
              :startAt="work.startAt"
              :endAt="work.endAt"
            />
          </span>
          <i class="ion-ios-refresh"></i>
          {{ work.createdAt | moment("from", "now") }}
        </div>
        <div class="where">
          <i class="ion-ios-business"></i>
          <LogementFormatter
            :locations="work.localisations"
          ></LogementFormatter>
        </div>
        <div class="corporate" v-if="work.company">
          Prestataire : {{ work.company }}
        </div>
        <div v-if="work.description" class="description">
          <RenderReact
            :component="TextEditorRenderer"
            :strip="true"
            :children="work.description"
          />
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="metric-list">
        <div class="metric">Atteint : {{ work.views }}</div>
        <!-- <div class="metric">Interaction : {{work.interactions}}</div> -->
      </div>
      <component
        v-if="work.pictogram"
        v-bind:is="findIcon(work.pictogram).image"
      ></component>
    </div>
  </div>
</template>

<script>
import WorkIcon from "@/assets/picto/travaux.svg";
import StatusIcon from "@/components/StatusIcon";
import icons from "@/utils/icons";
import LogementFormatter from "@/components/LogementFormatter";
import TextEditorRenderer from "@/components/TextEditor/TextEditorRenderer";
import DateRangeFormatter from "@/components/DateRangeFormatter";
import RenderReact from "@/components/RenderReact";

export default {
  name: "WorkCard",
  props: ["work"],
  data: () => ({ TextEditorRenderer, DateRangeFormatter, isDashed: false }),
  components: { RenderReact, LogementFormatter, WorkIcon, StatusIcon },
  methods: {
    view() {
      this.$router.push({ name: "view-work", params: { id: this.work.id } });
    },
    findIcon(name) {
      return icons.find(({ value }) => value === name);
    },
  },
  async mounted() {
    const today = new Date();
    if (new Date(this.work.availableAt).getTime() > today.getTime()) {
      this.isDashed = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.element {
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  border: 4px solid $color-work;
  margin-bottom: 16px;
  width: 100%;
  cursor: pointer;

  &,
  .element-left {
    display: flex;
    flex-direction: row;
  }

  .icon {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: $color-work;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;

    svg {
      height: 42px;
      fill: $color-white;
    }
  }

  .description {
    padding: 0 8px 8px;

    .title {
      color: $color-work;
      font-size: 23px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .times,
    .where {
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    min-width: fit-content;

    .metric-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .metric {
        margin: 0 16px 16px 0;
        font-weight: 500;
        font-size: 14px;
      }
    }

    svg {
      height: 62px;
      margin: 0 48px;
      align-self: center;
    }

    img {
      height: 164px;
    }

    .status {
      align-self: center;
      margin: 0 32px;
      font-weight: 500;
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .element {
    padding: 8px;

    .icon {
      display: none;
    }

    .actions {
      display: none;
    }
  }
}
</style>
