<template>
  <div class="list-tutorial">
    <div class="header">
      <TutorialIcon></TutorialIcon>
      Tutoriels
    </div>
    <div class="container">
      <Error :error="error"></Error>

      <div class="box">
        <div class="title">
          Tutoriels
          <router-link class="btn primary" :to="{ name: 'admin-add-tutorial' }">
            Ajouter un tutoriel
          </router-link>
        </div>

        <div v-for="(tutorial, index) in tutorials" :key="index" class="tutorial-box">
          <div>
            <img :src="tutorial.image" :title="tutorial.name" :alt="tutorial.name"/>
            {{tutorial.name}}
          </div>
          <div>
            <router-link :to="{ name: 'admin-update-tutorial', params: { id: tutorial.id } }" class="btn small default">
              Modifier
            </router-link>
            <a :href="tutorial.video" target="_blank" class="btn small primary">
              Voir la vidéo
              <i class="ion-md-open"></i>
            </a>
          </div>
        </div>

        <div v-if="tutorials.length === 0" class="empty-state">
          Aucun tutoriel n'a été ajouté
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialIcon from '@/assets/picto/tutosvideos.svg'
import ErrorComponent from '@/components/Error'
import {tutorialService} from '@/services'

export default {
  name: 'ListTutorial',
  components: {
    TutorialIcon,
    Error: ErrorComponent,
  },
  data: () => ({
    tutorials: [],
    error: undefined,
  }),
  async mounted() {
    try {
      this.tutorials = await tutorialService.list()
    } catch (error) {
      this.error = error
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-blue-light;
  padding: 16px;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.box {
  margin-bottom: 36px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.empty-state {
  padding: 32px 10px;
  border-radius: 4px;
  margin-bottom: 16px;
  background: $color-grey;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.tutorial-box {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 16px;
  background: $color-grey;

  &,
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  img {
    height: 32px;
    margin-right: 8px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 15px;
    justify-content: flex-start;

    svg {
      height: 28px;
    }
  }
}
</style>
