import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Form, Typography, Button, Input, DatePicker, TimePicker } from 'antd'
import PictogramInput from '@/components/PictogramInput'
import LocationInput from '@/components/LocationInput'
import TextEditor from '@/components/TextEditor/TextEditor'
import PictureInput from "@/components/PictureInput";
import useVueState from '@/hooks/useVueState'

const { Title } = Typography

function disabledDate(current) {
  return current && current < moment().endOf('day')
}

function InterventionForm({ title, intervention, onSubmit, routerBack }) {
  const [form] = Form.useForm()
  const userData = useVueState((s) => s.user.user)
  const [localisationTypes, setLocalisationType] = useState("");
  const [timeValue, setTimeValue] = useState(null);
  const format = 'HH:mm';


  useEffect(() => {
    if (userData) {
      if (userData.role === "user") {
        setLocalisationType("bloc");
      } else {
        setLocalisationType("homestay");
      }
    }
  }, [userData])

  const initialValues = useMemo(() => {
    return intervention ? {
      ...intervention,
      availableAt: intervention?.availableAt ? moment(intervention.availableAt) : null,
      date: intervention?.startAt ? moment(intervention.startAt) : null,
      timeMoment: intervention.time ? moment(intervention.time, "HH:mm") : null,
      // intervention.endAt ? moment(intervention.endAt) : null

    } : {}
  }, [intervention])

  function preSubmit(values) {
    const payload = {
      ...values,
      date: undefined,
      timeMoment: undefined,
      time: timeValue,
      startAt: values.date?._d,
      // endAt: values.date?.[1],
      endAt: undefined
    }
    return onSubmit(payload).catch(error => {
      return notification.error({
        message: 'Erreur !',
        description: error.toString(),
      })
    })
  }
  const onChangeTimeValue = (time, timestring) => {
    setTimeValue(timestring);
  };

  return (
    <div>
      <Title level={3}>
        {title}
      </Title>
      <Form form={form} initialValues={initialValues} onFinish={preSubmit} layout="vertical">
        <Form.Item
          name="name"
          label="Titre"
          extra="Exemples : Maintenance ascenseur, Coupure d'eau, Coupure d'électricité, etc... "
          rules={[{ required: true, message: 'Titre requis.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <TextEditor />
        </Form.Item>

        <Form.Item
          name="pictogram"
          label="Pictogramme"
          rules={[{ required: true, message: 'Pictogramme requis.' }]}
        >
          <PictogramInput />
        </Form.Item>

        <Form.Item
          name="picture"
          label="Photo"
        >
          <PictureInput />
        </Form.Item>

        <Form.Item
          name="company"
          label="Entreprise"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="localisations"
          label="Localisations"
          rules={[{ required: true, message: 'Localisations requises.' }]}
        >
          <LocationInput unit={localisationTypes} />
        </Form.Item>

        <Form.Item
          name="date"
          label="Date de l'intervention"
          rules={[{ required: true, message: 'Date requise.' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Sélectionner"
          />
        </Form.Item>

        <Form.Item
          name="timeMoment"
          label="Heure de l'intervention"
          rules={[{ required: true, message: 'Heure requise.' }]}
        >
          <TimePicker value={timeValue} onChange={onChangeTimeValue} minuteStep={15} format={format} showNow={false} />
        </Form.Item>

        <Form.Item name="availableAt" label="Programmer">
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Sélectionner"
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={routerBack}>
            Annuler
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

InterventionForm.propTypes = {
  title: PropTypes.string.isRequired,
  intervention: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  routerBack: PropTypes.func.isRequired,
}

export default InterventionForm
