import { satisfactionService } from '@/services'

const initialState = {
  satisfactions: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setSatisfactions(state, satisfactions) {
      state.satisfactions = satisfactions
    },
  },
  actions: {
    async fetchSatisfactions({ commit }) {
      const satisfactions = await satisfactionService.list()
      const sortedSatisfactions = satisfactions
        .sort(({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime())
      commit('setSatisfactions', sortedSatisfactions)
      return sortedSatisfactions
    },
    async vote({ dispatch }, { id, type }) {
      await satisfactionService.vote({
        id,
        type,
      })
      return dispatch('fetchSatisfactions')
    },
  },
}
