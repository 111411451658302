<template>
  <div class="concertation">
    <div class="header">
      <div><ConcertationIcon></ConcertationIcon> Concertation</div>
    </div>

    <div class="container">
      <div class="filter-container">
        <div class="filter">
          <div class="filter-name">Localisations :</div>
          <LocalisationInput
            v-if="defaultLocalisations"
            v-model="localisations"
            :default-value="defaultLocalisations"
            unit="bloc"
          />
        </div>
        <div class="filter">
          <div class="filter-name">Statuts :</div>
          <div class="select">
            <select :value="$route.query.filter" @change="updateFilter">
              <option value="ALL">Tous</option>
              <option value="IN_PROGRESS">En cours</option>
              <option value="ARCHIVED">Archivés</option>
            </select>
          </div>
        </div>
        <div class="filter">
          <div class="filter-name">Trier par :</div>
          <div class="select">
            <select :value="$route.query.sort" @change="updateSort">
              <option value="createdAt">Date de création</option>
              <option value="availableAt">Date de disponibilité</option>
            </select>
          </div>
        </div>
      </div>

      <div class="banner">
        <div class="stats">
          <div class="name">Nombre total de concertations :</div>
          <div class="value">
            {{ concertations ? concertations.length : 0 }}
          </div>
        </div>
        <div class="actions">
          <router-link
            :to="{ name: 'new-concertation' }"
            :class="{ disabled: !canCreate }"
          >
            <i class="ion-ios-add"></i>
            Ajouter une concertation
          </router-link>
        </div>
      </div>
      <Error :error="error"></Error>
      <template v-if="concertations">
        <ConcertationCard
          v-for="concertation in sortedConcertations"
          :key="concertation.id"
          :concertation="concertation"
          :routerPush="$router.push"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConcertationIcon from "@/assets/picto/concertation.svg";
import Error from "@/components/Error";
import ConcertationCard from "@/components/ConcertationCard";
import { concertationService } from "@/services";
import LocalisationInput from "@/components/LocalisationInput";

export default {
  name: "ListConcertation",
  data: () => ({
    error: undefined,
    concertations: [],
    localisations: [],
    defaultLocalisations: undefined,
  }),
  components: {
    LocalisationInput,
    ConcertationCard,
    Error,
    ConcertationIcon,
  },
  watch: {
    "$route.query.filter": {
      immediate: true,
      handler(filter) {
        if (filter) {
          return this.fetchConcertations();
        } else {
          this.$router.push({
            query: { ...this.$route.query, filter: "ALL" },
          });
        }
      },
    },
    "$route.query.sort": {
      immediate: true,
      handler(sort) {
        if (!sort) {
          this.$router.push({
            query: { ...this.$route.query, sort: "createdAt" },
          });
        }
      },
    },
    localisations(localisations) {
      this.$router.push({
        query: {
          ...this.$route.query,
          localisations: JSON.stringify(localisations),
        },
      });
      return this.fetchConcertations();
    },
  },
  computed: {
    ...mapState("user", ["config"]),
    canCreate() {
      return this.config?.metadata?.permissions?.concertation?.create !== false;
    },
    sortedConcertations() {
      const sortField = this.$route.query.sort ?? "createdAt";
      return [...(this.concertations ?? [])].sort((a, b) => {
        if (a[sortField] == undefined || b[sortField] == undefined) {
          return b[sortField] ? 1 : -1;
        }
        return (
          new Date(b[sortField]).getTime() - new Date(a[sortField]).getTime()
        );
      });
    },
  },
  methods: {
    updateFilter(event) {
      this.$router.push({
        query: { ...this.$route.query, filter: event.target.value },
      });
    },
    updateSort(event) {
      this.$router.push({
        query: { ...this.$route.query, sort: event.target.value },
      });
    },
    async fetchConcertations() {
      try {
        this.concertations = await concertationService.list({
          filter: this.$route.query.filter,
          localisations: this.localisations,
        });
      } catch (error) {
        this.error = error;
      }
    },
  },
  mounted() {
    this.defaultLocalisations = this.$route.query.localisations
      ? JSON.parse(this.$route.query.localisations)
      : [];
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-concertation;
  padding: 16px 0;
  font-size: 22px;
  width: 100%;
  color: $color-white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 32px;

  svg {
    height: 36px;
    margin-right: 12px;
    fill: $color-white;
  }
}

.banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  .stats {
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
      margin-right: 8px;
    }

    .value {
      font-size: 22px;
      color: $color-concertation;
      line-height: 1.2;
    }
  }

  .actions a {
    padding: 8px;
    background: $color-concertation;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.filter-container {
  background: $color-grey-light;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 32px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .filter {
    width: 30%;
    margin-right: 1%;

    &:last-child {
      margin-right: 0;
    }
  }

  .filter-name {
    font-weight: 600;
    color: $color-grey-dark;
    margin-bottom: 6px;
  }

  .filter .select {
    display: inline-block;
    margin-right: 16px;
    width: 100%;

    select {
      width: 100%;
      height: 34px;
      padding: 6px 30px 6px 10px;
      font-size: 14px;
      line-height: 1.4;
      color: #555;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 8px 0;
  }

  .banner {
    flex-direction: column;
    align-items: baseline;

    .stats {
      margin-bottom: 16px;
    }
  }

  .filter-container {
    flex-direction: column;

    .filter {
      width: 100%;
      margin-right: 0;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
