import { interventionService } from '@/services'

const initialState = {
  interventions: undefined,
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setInterventions(state, interventions) {
      state.interventions = interventions
    },
  },
  actions: {
    async fetchInterventions({ commit }) {
      const data = await interventionService.list()
      data.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime())
      commit('setInterventions', data)
      return data
    },
    async markAsRead({ dispatch }, { id }) {
      await interventionService.readed({ id })
      return dispatch('fetchInterventions')
    },
  },
}
